.maincontent.guestuser-header {
    padding-bottom: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .guestprofiletabs .righttab li {
        margin-bottom: 4%; 
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
    .guestprofiletabs .lefttab ul li {
        flex: 1;
    }
    .guestprofiletabs .lefttab ul li a {
        width: auto;
        padding: 0px 10px;
    }
}

@media only screen and (min-width: 979px) and (max-width: 1023px) {
    .guestprofiletabs .lefttab ul li {
        flex: 1;
    }
    .guestprofiletabs .lefttab ul li a {
        width: auto;
        margin-right: 20px;
    }
    .guestprofiletabs .lefttab ul li:last-child a {
        margin-right: 20px;
    }
    .guestprofiletabs .righttab ul li a,
    .guestprofiletabs .righttab ul li span.editbutton {
        margin-right: 20px;
    }
}