.deals-content-box {
    overflow: hidden;
}
.addproperty .ck-editor__editable_inline {
    height: 200px;
    width: 100%;
}
.addproperty .ant-modal-body{
    padding: 24px!important;
}
.addproperty .ant-form-item{
    margin-bottom: 10px;
}
.addproperty .ant-form-item .ant-input, .addproperty .ant-form-item textarea, .addproperty .ant-input-number .ant-input-number-input, .addproperty .ant-select-selector .ant-select-selection-search-input{
    width: 100%;
    height: 40px!important;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    overflow: hidden;
    outline: none;
    resize: none;
    background: #FEFEFE;
    border-radius: 6px;
}
.addproperty .ant-select-selector {
    border: 1px solid #d9d9d9!important;
    border-radius: 6px!important;
    height: 40px!important;
}
.addproperty .ant-select-selection-placeholder {
    padding: 15px 0!important;
}
.addproperty .ant-input-number-handler-wrap{
    display: none;
}
.addproperty .price-wrapper {
    display: flex;
}
.deals-midcontent {
    max-width: 76%;
    margin: auto;
}
.dealersearchbox{
    border: none;
}

.dealersearchbox .searchbox{
    max-width: 90%!important;
}

.deals-content {
    display: flex;
    flex-wrap: wrap;
}

.deals-content-row {
    max-width: 30%;
    aspect-ratio: 16/11;
    cursor: pointer;
    margin: 0 15px 30px;
    position: relative;
    max-height: 250px;
    width: 100%;
}

.deals-content-overlay {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
}

.deals-content-row img {
    width: 100%;
    height: 100%;
}

.deals-content-top {
    background: linear-gradient(0deg,#0072BB,rgba(0,114,187,.8) 40%,rgba(39,74,187,.1));
    color: #fff;
    align-items: flex-end;
    background: #0072BB;
    background: linear-gradient(0deg,#0072BB,rgba(0,114,187,.8) 40%,rgba(39,74,187,.1));
    background-size: 100%;
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    font-size: 1.2em;
    padding: 0.5em 1em 0.7em;
    text-shadow: 1px 1px rgba(0,0,0,.25);
}

.deals-content-overlay-content {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
}

.deals-content-top .address {
    display: block; flex-grow: 1; font-size: 16px; padding: 8px 30px; font-weight: 500;
}

.deals-content-top .price {
    flex-grow: 0;
    font-size: 1.2em;
    margin-left: 0.5em;
    text-align: right;
    text-shadow: 1px 1px rgba(0,0,0,.5);
}
/* .deals-content-row:hover .deals-content-bottom {
    height: 2.8em;
} */

ul.deals-content-bottom {
    background-color: #0072BB;
    color: #fff;
    display: block;
    font-size: 1.5em;
    height: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0 1em;
    position: relative;
    transition: all .4s;
    width: 100%;
}

ul.deals-content-bottom li {
    color: #fff;
    display: inline-block;
    font-size: .7em;
    line-height: 150%;
    margin: 0 1.5em 0.75em 0;
    position: relative;
    transition: all .5s;
}
.add-property button {
    color: #fff;
    background: #0072BB;
    font-size: 20px;
    border: 0.8px solid #0072BB;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 10px 25px;
    height: auto;
}
.state-city-zip, .desc-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.state-city-zip > div{
    flex: 1;
}
.desc-wrapper > div {
    flex: 1 1 20%;
    max-width: 295px;
}
.newpropertform .form-label {
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
}
p.max-msg {
    text-align: right;
    color: #a5a3a3;
    font-size: 12px;
}
.image-slider {
    max-width: 720px;
    margin: auto;
}
.image-slider img {
    width: 100%;
}
.image-slider button.image-gallery-icon.image-gallery-fullscreen-button {
    cursor: zoom-in;
}
.image-slider .image-gallery-thumbnails{
    overflow: auto;
}
.image-slider .image-gallery-left-nav .image-gallery-svg, .image-slider .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
    background: #0072BB;
    border-radius: 50%;
}
@media only screen and (max-width: 768px){
    .deal-other span{
        width: 150ox;
    }
    .deal-action-wrapper button.enquiry{
        margin-bottom: 30px;
    }
}

.deals-title {
    font-size: 25px;
    margin-bottom: 30px;
    color: #000;
    text-align: center;
}

.deals-address {
    font-size: 16px;
}
.deals-address span.adbg{
    width: 30px;
    height: 30px;
    display: inline-block;
    background: url('../assets/address.svg') no-repeat center;
}

.deal-other {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 20px;
}
.deal-other div {
    margin-top: 15px;
    color: #000;
}
.deal-other span {
    color: rgba(0, 0, 0, 0.65);
    width: 200px;
    display: inline-block;
}
.deal-other span.icon {
    height: 30px;
    width: 30px;
    background: rgba(0, 0, 0, 0.65);
    display: none;
}
.deal-other .footage span.icon{
    background: url('../assets/footage.svg') no-repeat center
}
.deal-other .type span.icon{
    background: url('../assets/type.svg') no-repeat center
}
.deal-other .parking span.icon{
    background: url('../assets/parking.svg') no-repeat center
}
.deal-other .build span.icon{
    background: url('../assets/yearbuild.svg') no-repeat center
}
.deal-other .lotsize span.icon{
    background: url('../assets/lotsize.svg') no-repeat center
}
.deal-other .bedroom span.icon{
    background: url('../assets/bedroom.svg') no-repeat center
}
.deal-other .bathroom span.icon{
    background: url('../assets/bathroom.svg') no-repeat center
}
.deal-other .halfbathroom span.icon{
    background: url('../assets/bathroom.svg') no-repeat center
}
.image-preview:hover .delete-icon {
    display: block;
}
.delete-icon {
    background: rgb(255 255 255 / 70%) url('../assets/remove.svg') no-repeat center!important;
    cursor: pointer!important;
    background-size: 20px!important;
    position: absolute!important;
    width: 100%!important;
    height: 100%!important;
    border: none!important;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: none;
    font-size: 0!important;
}

.deal-action-wrapper{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 30px 0;
}
.deal-action-wrapper button {
    color: #0072BB;
    font-size: 20px;
    border: 0.8px solid #0072BB;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 10px 25px;
    height: auto;
}
.deal-action-wrapper button.enquiry{
    background: #0072BB;
    color: #fff;
}
.deal-action-wrapper button.enquiry:hover{
    background: transparent;
    color: #0072BB;
}
.deal-action-wrapper button.financing:hover{
    background: #0072BB;
    color: #fff;
}
.ap {
    color: rgba(0, 0, 0, 0.65);
    font-size: 17px;
}
.ap span{
    color: #000;
}
.ap .pa {
    display: flex;
    margin: 10px 0;
    font-size: 20px;
}
.ap .deal-price{
    /* text-align: right; */
    flex: 1;
}
.ap .deal-price span{
    display: block;
}
.deal-detail-desktop {
    margin: 20px 0;
}
.deal-detail-desktop .title {
    font-size: 20px;
    font-weight: bold;
}
.seller-information .userpic {
    width: 170px;
    height: 170px;
    margin-right: 14px;
    border-radius: 360px;
    position: relative;
}
.seller-information span.lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 200px !important;
    height: 200px !important;
}
.seller-information .userpic img {
    width: 100%;
    height: 100%;
    margin-right: 14px;
    border-radius: 360px;
    object-fit: cover;
}
.seller-information .pro-icon{
    right: 24px;
    bottom: 0;
}
.seller-information .title {
    font-size: 20px;
    font-weight: bold;
}
.seller-information .enquiry.btn{
    width: auto;
    height: auto;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;   
}
/***Enquiry ***/
.enquiry .ant-modal-content{
    margin: auto;
    max-width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    display: block;
    background: #fff;
    position: relative;
    z-index: 10;
}


/*details new ui updates*/

.maincontent.dealerpage:before { content: ''; width: 100vw; height: 100%; position: absolute; left: -50vw; right: -50vw; background: linear-gradient(92deg, white 40%, #cdcdcd); margin: auto; z-index: -1; } 
.maincontent.dealerpage { position: relative; z-index: 0; overflow: hidden; } 

.membersearchbox.dealersearchbox , 
.membersearchbox.dealersearchbox .membersearch .searchbox { background: none; } 

.membersearchbox.dealersearchbox .membersearch { justify-content: space-between; width: 100%; padding: 0; align-items: center; } 
.membersearchbox.dealersearchbox .membersearch h1 { font-weight: 900; color: #959595; margin: 0 7% 0 0; font-size: 46px; font-family: 'Roboto'; } 
.membersearchbox.dealersearchbox .membersearch input.formtext { background: none; border-radius: 13px 0 0 13px; border: 2px solid #5a6870; border-right: 0; } 
.membersearchbox.dealersearchbox .membersearch input.form-submit { border-radius: 0 13px 13px 0; border: 2px solid #1b4f71; background-color: #0074b1; border-left: 0; } 
.deals-midcontent.dealsdetailspage { max-width: 980px; } 
.dealsdetailspage .deals-title { text-align: left; font-size: 32px; text-transform: uppercase; margin: 25px 0 20px;line-height: normal; } 
.dealsdetailspage .deals-full-content { padding: 0 20px; } 

.deals-content-box .image-slider { width: 100%; max-width: 100%; margin: 0 0 30px;} 
.deals-content-box .image-gallery-content .image-gallery-slide .image-gallery-image { max-height: none; }
.deals-content-box .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal { width: auto; display: flex; justify-content: flex-end; background: linear-gradient(180deg, #006fac 85px, transparent 80px); margin: -85px 0px 0 25%; max-width: 75%; padding: 5px 8px 0;    justify-self: flex-end; overflow: hidden;} 
.deals-content-box .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal .image-gallery-thumbnails { max-width: 100%;  /* margin: -100px 0px 0 0; */ /* background: linear-gradient(181deg, #006fac 115px, transparent 80px); */ padding: 0;overflow: hidden; } 
.deals-content-box .image-gallery-thumbnail .image-gallery-thumbnail-image { max-height: 100px; } 
.deals-content-box .image-gallery-thumbnail+.image-gallery-thumbnail { width: 150px; }
.deals-content-box .image-gallery-content { display: grid; flex-wrap: wrap; width: 100%; } 
.deals-content-box .image-gallery-slide-wrapper { width: 100%; } 
.deals-content-box .image-gallery-thumbnail { width: 150px; }

.deals-detail .ap-bar { border-width: 2px 2px 0px 2px; border-style: solid; padding: 17px 2% 23px 10px; display: flex; justify-content: space-between; border-color: #5b5b5b; align-items: center; margin: 10px 0 20px; position: relative; } 
.deals-detail .ap-bar:before { content: ''; width: calc(100% + 4px); bottom: 0; background: linear-gradient(45deg, #50b9d3, #0031b3); height: 10px; position: absolute; left: -2px; } 
.deals-detail .ap-bar .deal-price { font-size: 44px; font-weight: 800; color: #4d4d4d; }
.deals-detail .deals-address { font-size: 20px; position: relative; padding: 0 0 5px 3px; color: #4d4d4d;    line-height: 1.5; }
.deals-detail .deals-address span {font-weight: 500;font-size: 26px;} 
.deals-detail .deals-address span.adbg { position: absolute; left: 0; top: 3px; width: 30px; height: 30px; } 
.deals-detail .ap { padding-left: 35px; font-size: 13px; margin: 0 0 -7px; }

.deals-re-avr { display: flex; width: 100%; justify-content: space-between; padding: 0 10px; } 
.deals-re-avr > div { flex: 0 49%; border: 2px solid #52b3cf; padding: 16px 15px; font-size: 20px; font-weight: 600; border-radius: 12px; } 
.deals-re-avr > div span { font-weight: 400; }
.deals-re-avr > div.deal-avr { border-color: #2857c0; }

.deal-other { padding: 0 10px; display: flex; flex-wrap: wrap; font-size: 15px; color: #444; } 
.deal-other > div { max-width: 25%; flex: 0 25%; padding: 5px 0 5px; } 
.deal-other > div span { display: block; font-weight: 600; font-size: 20px; color: #3b92bf; }

.deal-detail-desktop .title { color: #444; font-size: 22px; padding: 0 0 5px; } 
.deal-detail-desktop figure.table table { border: 1px solid #999; border-collapse: collapse; } 
.deal-detail-desktop figure.table table td { border: 1px solid #ccc; padding: 5px 20px; } 
.deal-action-wrapper { justify-content: flex-start; margin: 30px 0 5em; } 

.seller-information { display: flex; width: 100%; justify-content: start; align-items: center; padding: 0 330px 0 0; min-height: 200px; position: relative; } 
.seller-information .seller-text-info { order: 2; } 
.seller-information .userpic { order: 1; max-width: 210px; margin: 0 7% 0 0; width: 220px; display: flex; height: auto; } 
.seller-information .seller-ctas { order: 3; position: absolute; right: 0; top: 0; height: 100%; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; max-width: 300px; padding: 35px 0; } 
.seller-information .seller-text-info .title { font-size: 22px; letter-spacing: 0.4pt; line-height: normal; padding: 0 0 6px; } 
.seller-information .seller-text-info .seller-name { font-size: 20px; line-height: normal; } 
.seller-information .seller-ctas a.enquiry.btn { width: 100%; margin: 0; text-align: center; background: #00a6e3; border: 1px solid #2d9ebb; border-radius: 10px; padding: 14px 13px; text-transform: uppercase; font-weight: 500; letter-spacing: 0.2pt; font-size: 19px; color: #fff; } 
.seller-information .seller-ctas button.ant-btn { width: 100%; border-radius: 9px; border: 0; color: #fff; background: linear-gradient(45deg, #63b3c2, #1531a1); height: 43px; font-size: 20px; text-transform: uppercase; font-weight: 500; letter-spacing: 0.4pt; }

.property-details + .upload-wrapper { margin: 2em 0 0; } 
.property-details + .upload-wrapper div[role="presentation"] { border: 2px dashed #006db5 !important; border-radius: 17px; max-width: 680px; margin: auto; text-align: center; height: 170px; align-items: center; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; align-content: center; }

.deals-detail > div button.ant-btn, .add-property button.ant-btn { margin: 0; text-align: center; background: #00a6e3; border: 1px solid #2d9ebb; border-radius: 7px; padding: 13px 13px; font-weight: 500; letter-spacing: 0.2pt; font-size: 16px; color: #fff; display: inline-block; line-height: normal; height: auto; min-width: 70px; }
/*---- Deals landing page -----*/

.dealerpage .deals-midcontent { max-width: 980px; }
.deals-content-top .address div { font-weight: 400; font-size: 13px; } 
.deals-content.deals-landing { padding: 2em 0; } 
.deals-landing .deals-content-overlay { position: static; display: block; padding: 0px 9%; } 
.deals-landing .deals-content-overlay-content { position: relative; } 
.deals-landing .deals-content-top {background: none; color: #444; text-shadow: none; padding: 9px 0 7px; align-items: flex-start; line-height: normal; display: flex; flex-direction: column-reverse; justify-content: center; margin: -25px 0 0 0; } 
.deals-landing .deals-content-row {max-height: none; border-radius: 0; overflow: hidden; aspect-ratio: auto; margin: 1.6% 0.8%; max-width: 31.7%;} 
.deals-landing .deals-content-row img { height: auto; max-height: 220px; }
.deals-content-top .price { text-shadow: none; }
.deals-landing .deals-content-row .img-wrapper { min-height: 220px;display: flex; width: 100%; height: calc(100vw / 6.8); overflow: hidden; max-height: 220px; padding-top: 10px; background: linear-gradient(270deg, #0031c3, #5fb2d3);}

.deals-content-top .price {margin: 0; text-shadow: none; background: #4d4d4d; text-align: center; width: 100%; border-radius: 10px; box-shadow: 0 0 0 1px #ffffff85; color: #fff; font-size: 19px; font-weight: 500; padding: 3px 0; }

@media all and (max-width: 1023px) {
    .deals-midcontent { max-width: 99%; }
}
@media all and (max-width: 980px) {
    .deals-landing .deals-content-row .img-wrapper {min-height: auto; height: calc(100vw / 4.5);}
    .deals-content.deals-landing { padding: 2em 10px; }

    .seller-information span.lazy-load-image-background.blur.lazy-load-image-loaded { width: 25vw !important; height: 25vw !important; } 
    .seller-information .userpic { width: 25vw; height: 100%; max-width: 25vw; height: 25vw; }
}
@media only screen and (min-width: 768px) and (max-width: 1279px){
.membersearchbox .membersearch .searchbox .formtext, .membersearchbox .membersearch .searchbox .form-action .form-submit {
    height: 60px;
}
}
@media all and (max-width: 767px) {
    .deals-landing .deals-content-row{max-width: 48.4%;}
    .deals-landing .deals-content-row .img-wrapper { height: calc(100vw / 3.25); }
    .membersearchbox.dealersearchbox .membersearch h1 { text-align: center; width: 100%; margin: 0 0 0.5em; } 
    .membersearchbox.dealersearchbox .membersearch .searchbox .formtext, .membersearchbox.dealersearchbox .membersearch .searchbox .form-action .form-submit { height: 55px; }
    .deal-other > div { max-width: 33.33%; }

    .seller-information { padding: 0; max-width: 600px; flex-wrap: wrap; justify-content: center; margin: auto; } 
    .seller-information .seller-ctas { height: auto; padding: 30px  0 0; position: static; } 
    .seller-information .userpic { margin: auto; width: 180px; height: 180px; margin: 0 0 30px;max-width: 180px;padding: 0; } 
    .seller-information .seller-text-info { width: 100%; text-align: center; } 
    .seller-information span.lazy-load-image-background.blur.lazy-load-image-loaded { overflow: hidden; height: 170px !important;width: 170px !important; } 
    .seller-information .seller-ctas a.enquiry.btn { margin: 0 0 15px; }

    .deals-content-box .image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal { max-width: 100%; margin: 0px 0; background: #fff; }

    .membersearchbox.dealersearchbox .membersearch .searchbox{flex: 0 100%;margin: 0 10px;max-width: 100% !important;}
    .membersearchbox.dealersearchbox .membersearch .searchbox .formbox { margin: 0 10px; } 
    .dealersearchbox .add-property button { padding: 10px 20px; font-size: 19px; }
}

@media all and (max-width: 680px) {
    .deals-re-avr > div { flex: 0 100%; margin: 6px 0; } 
    .deals-re-avr { flex-wrap: wrap; }

    .membersearchbox.dealersearchbox .membersearch h1 { font-size: 36px; } 
    .dealsdetailspage .deals-title { font-size: 24px; line-height: normal; }

    .deals-detail .ap-bar .deal-price { font-size: 34px;white-space: nowrap; }
}

@media all and (max-width: 540px) {
    .deals-content.deals-landing {padding: 2em 15px;}
    .deals-landing .deals-content-row{max-width: 100%;margin: 0.5em auto;width: 340px;}
    .deals-landing .deals-content-row .img-wrapper { height: 220px;max-height: 220px; }
    .deal-other > div { max-width: 50%;  }

    .deals-detail .deals-address{font-size: 15px;}
    .deals-detail .deals-address span{font-size: 19px;}

    .dealersearchbox .add-property { position: absolute; right: 0px; top: -10px; } 
    .membersearchbox.dealersearchbox .membersearch .searchbox .formbox { margin: 0 0 0 10px; }
    .dealersearchbox .add-property button { padding: 5px 10px; font-size: 14px; }
    .membersearchbox.dealersearchbox .membersearch .searchbox:has(.add-property) { padding-top: 36px; position: relative; } 
    .deals-detail > div button.ant-btn, .add-property button.ant-btn { padding: 7px 14px; }

    .deals-re-avr{padding: 0;}
}

@media all and (max-width: 400px) {
    .deal-other > div { max-width: 100%;  }
}