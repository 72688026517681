.bannerarea {
    position: relative;
}
.bannerarea .bannertitle {
    max-width: 1008px;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    left: 0px;
    right: 0px;
}
.bannerarea .bannertitle h2 {
    margin: 0px;
    font-size: 56px;
    line-height: 83px;
    text-align: center;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-family: 'Oswald', sans-serif;
}

.bannerarea .imagebox img {
    width: 100%;
    height: auto;
}
.aboutusblock {
    padding: 96px 0px 24px 0px;
    overflow: hidden;
}
.aboutusblock .aboutus {
    max-width: 1440px;
    margin: auto;
    padding: 0px 0px 0px 56px;
}
.aboutusblock .aboutus h2.blocktitle {
    margin: 0px 0px 30px;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #AAAAAA;
    border-left: 2px solid #0072BB;
    padding-left: 10px;
}
.aboutusblock .aboutus .aboutleftbox {
    width: 45%;
    float: left;
    padding-right: 15px;
}
.aboutusblock .aboutus .imagewrapper {
    width: 55%;
    float: left;
    position: relative;
    padding-left: 22px;
    padding-right: 56px;
}

.aboutusblock .aboutus .blockcontent {
    font-size: 20px;
    line-height: 36px;
    color: #45484F;
}
.aboutusblock .aboutus .blockcontent p {
    margin-top: 0px;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 36px;
    color: #45484F;
}
.aboutusblock .aboutus .blockcontent p:last-child {
    margin-bottom: 0px;
}

.aboutusblock .aboutus .imagewrapper:after {
    content: "";
    position: absolute;
    top: 22%;
    left: 0px;
    width: 65%;
    height: 82%;
    background: #5A83ED;
    border-radius: 20px;
}
.aboutusblock .aboutus .imagewrapper::before {
    content: "";
    position: absolute;
    top: -51px;
    right: 0px;
    width: 53%;
    height: 90%;
    background: #5A83ED;
    border-radius: 20px;
}
.aboutusblock .aboutus .imagewrapper img {
    max-width: 100%;
    height: auto;
    border-radius: 24px;
}
.aboutusblock .aboutus .imagewrapper .verticle {
    display: flex;
    align-items: center;
    border-radius: 24px;
    position: relative;
    z-index: 1;
}
.aboutusblock .aboutus .imagewrapper .horizontal {
    position: relative;
    z-index: 1;
    margin-top: 26px;
}
.aboutusblock .aboutus .imagewrapper .verticle .imagebox2 {
    margin-left: 24px;
}
.aboutusblock .aboutus .imagewrapper .verticle .imagebox1,
.aboutusblock .aboutus .imagewrapper .verticle .imagebox2 {
    border-radius: 24px;
}

@media only screen and (min-width: 1440px) {
    .ourvisionwithmission h2.blocktitle {
        font-size: 64px;
        line-height: 96px;
        margin: 0px 0px 45px;
    }
    .aboutusblock .aboutus .blockcontent,
    .aboutusblock .aboutus .blockcontent p {
        font-size: 24px;
    }
}

.ourvisionwithmission {
    position: relative;
    overflow: hidden;
    margin-top: 78px;
}
.ourvisionwithmission .viewblockcontent {
    max-width: 1440px;
    margin: auto;
    padding: 75px 0px 44px;
    position: relative;
    z-index: 1;
    background: url(../../assets/images/curvelinebg.jpg) no-repeat 0px 0%;
    background-size: 100% 100%;
}
.ourvisionwithmission .viewblockcontent>div {
    padding: 0px 56px;
}
.ourvisionwithmission h2.blocktitle {
    margin: 0px 0px 30px;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #AAAAAA;
    border-left: 2px solid #0072BB;
    padding-left: 10px;
}
.ourvisionwithmission .blockcontent,
.ourvisionwithmission .blockcontent p {
    font-size: 20px;
    line-height: 36px;
    color: #45484F;
}
.ourvisionwithmission .blockcontent p:last-child {
    margin-bottom: 0px;
}
.ourvisionwithmission .ourvisionblock {
    margin-bottom: 46px;
}

@media only screen and (min-width: 1440px) {
    .ourvisionwithmission h2.blocktitle {
        font-size: 64px;
        line-height: 96px;
        margin: 0px 0px 45px;
    }
    .ourvisionwithmission .blockcontent,
    .ourvisionwithmission .blockcontent p {
        font-size: 24px;
    }
}
.aboutviewtestimonials {
    width: 100%;
    overflow: hidden;
    padding: 116px 0px 56px;
}
.aboutviewtestimonials .viewcontent {
    max-width: 1440px;
    margin: auto;
    padding: 0px 56px;
}
.aboutviewtestimonials .viewcontent .viewrow {
    display: flex;
    margin-bottom: 96px;
}
.aboutviewtestimonials .viewcontent .viewrow:last-child {
    margin-bottom: 0px;
}
.aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) {
    flex-direction: row-reverse;
}
.aboutviewtestimonials .viewcontent .viewrow .imagebox {
    width: 50%;
    text-align: center;
    flex: 1;
    position: relative;
    padding-bottom: 53px;
}
.aboutviewtestimonials .viewcontent .viewrow .contentbox {
    flex: 1;
    width: 50%;
}
.aboutviewtestimonials .viewcontent .viewrow .imagebox .image {
    width: 400px;
    margin: auto;
    position: relative;
    z-index: 1;
    height: 400px;
    background: #C4C4C4;
    border-radius: 50px 150px 25px 100px;
}
.aboutviewtestimonials .viewcontent .viewrow .imagebox .image img {
    position: relative;
    z-index: 1;
}
.aboutviewtestimonials .viewcontent .viewrow .imagebox::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70%;
    height: 455px;
    background: url(../../assets/images/linebg.svg) no-repeat 0% 100%;
    background-size: 100%;
}
.aboutviewtestimonials .viewcontent .viewrow .imagebox .image::before {
    content: "";
    position:absolute;
    top: -40px;
    right: -40px;
    width: 200px;
    height: 200px;
    background: #5698FC;
    border-radius: 50px 150px 25px 100px;
    transform: rotate(-180deg);
}

.aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .imagebox .image::before {
    top: auto;
    right: auto;
    bottom: -40px;
    left: -40px;
    border-radius: 50px 100px 25px 150px;
}
.aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .imagebox::after {
    display: none;
}
.aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .imagebox .image::after {
    background: url(../../assets/images/linebgeven.svg) no-repeat 50% 0%;
    background-size: 100%;
    width: 211px;
    height: 100%;
    right: -210px;
    content: "";
    position: absolute;
    top: 0px;
}

.aboutviewtestimonials .viewcontent .viewrow h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #11072F;
    margin: 0px 0px 24px 0px;
}
.aboutviewtestimonials .viewcontent .viewrow .description {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: #45484F;
}


@media only screen and (min-width: 1440px) {
    .aboutviewtestimonials .viewcontent .viewrow h2.blocktitle {
        font-size: 30px;
    }
    .aboutviewtestimonials .viewcontent .viewrow .description {
        font-size: 24px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .bannerarea .bannertitle {
        padding: 0px 20px;
    }
    .bannerarea .bannertitle h2 {
        font-size: 20px;
        line-height: 27px;
    }
    .aboutusblock {
        padding: 25px 0px;
    }
    .aboutusblock .aboutus {
        padding: 0px 20px;
        display: block;
    }
    .aboutusblock .aboutus .aboutleftbox {
        width: 100%;
        max-width: 100%;
        padding-right: 0px;
    }
    .aboutusblock .aboutus .imagewrapper {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-top: 15px;
    }
    .aboutusblock .aboutus .imagewrapper .horizontal {
        margin-top: 15px;
    }
    .aboutusblock .aboutus .imagewrapper .verticle {
        display: block;
    }
    .aboutusblock .aboutus .imagewrapper .verticle .imagebox2 {
        margin-left: 0px;
        margin-top: 15px;
    }
    .aboutusblock .aboutus h2.blocktitle {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .aboutusblock .aboutus .blockcontent,
    .aboutusblock .aboutus .blockcontent p,
    .ourvisionwithmission .blockcontent, 
    .ourvisionwithmission .blockcontent p,
    .aboutviewtestimonials .viewcontent .viewrow .description {
        font-size: 15px;
        line-height: 25px;
    }
    .aboutusblock .aboutus .imagewrapper:after {
       display: none;
    }
    .aboutusblock .aboutus .imagewrapper::before {
        display: none;
    }
    .aboutusblock .aboutus .imagewrapper img {
        width: 100%;
        height: auto;
    }
    .ourvisionwithmission {
        margin-top: 20px;
    }
    .aboutviewtestimonials {
        width: 100%;
        overflow: hidden;
        padding: 30px 0px;
        margin-top: 30px;
    }
    .ourvisionwithmission .viewblockcontent {
        padding: 20px;
    }
    .ourvisionwithmission h2.blocktitle {
        margin: 0px 0px 10px;
        font-size: 25px;
        line-height: 30px;
    }
    .ourvisionwithmission .ourvisionblock {
        margin-bottom: 25px;
    }
    .ourvisionwithmission .viewblockcontent>div {
        padding: 0px;
    }
    .aboutviewtestimonials .viewcontent {
        padding: 0px 20px;
    }
    .aboutviewtestimonials .viewcontent .viewrow {
        display: block;
        margin-bottom: 35px;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox {
        width: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .contentbox {
        width: 100%;
        margin-top: 25px;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image {
        width: 80%;
        height: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image img {
        width: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image::before {
        right: -5%;
        top: -5%;
        width: 38%;
        height: 38%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox::after {
        width: 70%;
        height: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .imagebox .image:after {
        width: 35%;
        right: -25%;
    }
    .aboutviewtestimonials .viewcontent .viewrow h2.blocktitle {
        font-size: 25px;
        line-height: 30px;
        margin: 0px 0px 15px 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .bannerarea .bannertitle {
        padding: 0px 20px;
    }
    .bannerarea .bannertitle h2 {
        font-size: 40px;
        line-height: 55px;
    }
    .aboutusblock {
        padding: 70px 0px 24px 0px;
    }
    .aboutusblock .aboutus {
        padding: 0px 20px;
    }
    .aboutusblock .aboutus .aboutleftbox {
        width: 40%;
    }
    .aboutusblock .aboutus .imagewrapper {
        width: 60%;
        padding-right: 22px;
    }
    
    .aboutusblock .aboutus .imagewrapper .horizontal {
        margin-top: 15px;
    }
    .aboutusblock .aboutus h2.blocktitle,
    .ourvisionwithmission h2.blocktitle {
        margin: 0px 0px 20px;
        font-size: 35px;
        line-height: 45px;
    }
    .aboutusblock .aboutus .blockcontent,
    .aboutusblock .aboutus .blockcontent p,
    .ourvisionwithmission .blockcontent, 
    .ourvisionwithmission .blockcontent p,
    .aboutviewtestimonials .viewcontent .viewrow .description {
        font-size: 16px;
        line-height: 28px;
    }
    .ourvisionwithmission {
        margin-top: 45px;
    }
    .ourvisionwithmission .viewblockcontent>div {
        padding: 0px 20px;
    }
    .aboutviewtestimonials .viewcontent {
        padding: 0px 20px;
    }
    
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image {
        width: 80%;
        height: auto;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image img {
        width: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image::before {
        right: -5%;
        top: -5%;
        width: 38%;
        height: 38%;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox::after {
        display: none;
    }
    .aboutviewtestimonials .viewcontent .viewrow .imagebox .image::after {
        content: "";
        position: absolute;
        top: 35%;
        left: -13%;
        width: 60%;
        height: 100%;
        background: url(../../assets/images/linebg.svg) no-repeat 0% 70%;
        background-size: 100%;
    }
    .aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .imagebox .image:after {
        width: 35%;
        right: -25%;
        left: auto;
    }

    .aboutviewtestimonials .viewcontent .viewrow {
        margin-bottom: 45px;
    }
    .aboutviewtestimonials .viewcontent .viewrow:nth-child(2n) .contentbox {
        padding-right: 20px;
    }
    

}