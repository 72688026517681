.allpodcastspage.maincontent {
    padding-bottom: 16px;
}
.allpodcastslist.viewpodcastslist {
    padding: 0px;
    border-bottom: 0px;
}
.allpodcastslist .viewpodcasts {
    max-width: 1126px;
}

.allpodcastslist .viewpodcasts .viewcontent .viewrow {
    width: 23%;
    margin: 0px 1% 2%;
    padding: 0px;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .allpodcastslist .viewpodcasts {
        padding: 0px 20px;
    }
    .allpodcastslist .viewpodcasts .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;
    }
    .viewpodcasts .viewcontent .viewrow .imagebox {
        height: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .allpodcastslist .viewpodcasts {
        padding: 0px 20px;
    }
    .allpodcastslist .viewpodcasts .viewcontent .viewrow {
        width: 49%;
        margin: 0px 2% 2% 0px;
    }
    .allpodcastslist .viewpodcasts .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px;
    }
    .viewpodcasts .viewcontent .viewrow .imagebox {
        height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .allpodcastslist .viewpodcasts {
        padding: 0px 20px;
    }
    .allpodcastslist .viewpodcasts .viewcontent .viewrow {
        width: 32%;
        margin: 0px 2% 2% 0px;
    }
    .allpodcastslist .viewpodcasts .viewcontent .viewrow:nth-child(3n) {
        margin-right: 0px;
    }
    .viewpodcasts .viewcontent .viewrow .imagebox {
        height: auto;
    }
}