.changepassword-wrapper {
    max-width: 414px;
    margin: auto;
    padding: 50px 20px;
}

.changepassword-wrapper .change-password-form-wrap {
    padding: 35px 30px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0,0.3);
}

.changepassword-wrapper .change-password-form-wrap h2 {
    font-size: 24px;
    color: #11072F;
}

.changepassword-wrapper .change-password-form-wrap .ant-input-affix-wrapper {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}
.changepassword-wrapper .change-password-form-wrap .ant-form-item-explain {
    margin-bottom: 15px;
}
.ant-form-item-label > label {
    color: #11072F;
}
.changepassword-wrapper .change-password-form-wrap .form-action {
    margin: 0px;
    text-align: center;
}
.changepassword-wrapper .change-password-form-wrap .form-action button {
    width: 100%;
    padding: 8px 20px;
    height: 42px;
    border: 0px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    border-radius: 4px;
    transition: all 0.5s ease;
}
.changepassword-wrapper .change-password-form-wrap .form-action button:hover {
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .changepassword-wrapper .change-password-form-wrap h2 {
        font-size: 21px;
    }
    .changepassword-wrapper .change-password-form-wrap .form-action button {
        padding: 8px 15px;
        font-size: 13px;
        line-height: 16px;
    }
}