.chatblock {
    position: fixed;
    max-width: 312px;
    width: 22%;
    top: 75px;
}
.chatwrapper .chatblock{
    padding-top: 185px;
}

.networkpage .chatwrapper .chatblock{
    padding-top: 0;
}
.chatblock h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #2B2B2B;
    padding: 24px 24px 28px;
    margin-bottom: 0px;
    background: #fbfbfb;
    border-radius: 10px 10px 0px 0px;
}
.sliderchatblock {
    display: none;
}
.chatblock h2.blocktitle .count {
    padding-left: 7px;
}
.chatblock .friendchatlist {
    overflow-y: auto;
    border-radius: 0px 0px 0px 10px;
    height: calc(100vh - 180px);
}
.chatblock .friendchatlist .viewcontent:last-child {
    border-radius: 0 0 10px 10px;
  }
.chatblock .friendchatlist .viewcontent:last-child::after {
    border-radius: 0 0 10px 10px;
    content: "";
    display: block;
    height: 15px;
  }
/* scrollbar */
.chatblock ::-webkit-scrollbar {
    width: 6px;
}
.chatblock  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.chatblock ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.chatblock ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
/* scrollbar */
.chatblock .friendchatlist .viewcontent {
    background: #fbfbfb;
}
.chatblock .friendchatlist .viewrow {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;
}
.chatblock .friendchatlist .viewrow:hover{
    background: #e0dede;
}

.chatblock .friendchatlist .viewcontent .slick-list .slick-slide{
    pointer-events: all;
}
/* .chatblock .friendchatlist .viewrow:last-child {
    padding-bottom: 20px;
    margin-bottom: 0px;
} */
.chatblock .friendchatlist .viewrow .userpic {
    width: 34px;
    height: 34px;
    margin-right: 15px;
    border-radius: 360px;
    text-align: right;
    position: relative;
}
.chatblock .friendchatlist .viewrow .userpic img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 360px;
    /* background: #04d800; */
    padding: 1px;
}
.chatblock .friendchatlist .viewrow.status-online .userpic img {
    border: 2px solid #04d800;
}
.chatblock .friendchatlist .viewrow.status-offline .userpic img.pro {
    border: 2px solid #FFD700;
}
.chatblock .friendchatlist .viewrow .userpic .pro-icon{
    width: 14px;
    height: 14px;
    background-size: 14px;
    z-index: 1;
    bottom: -3px;
    padding: 5px;
}
.chatblock .friendchatlist .viewrow.status-online .userpic .pro-icon{
    border: 2px solid #04d800;
}
.chatblock .friendchatlist .viewrow.status-offline .userpic img {
    border: 2px solid #cccccc;
}
.userpic .status {
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    background: #04d800;
    border-radius: 360px;
    position: absolute;
    bottom: -5px;
    right: 0px;
}
.chatblock .friendchatlist .viewrow .lazy-load-image-background{
    width: 100%!important;
    height: auto!important;

}
/* .chatblock .friendchatlist .viewrow.status-offline .userpic img {
    background: #cccccc;
}
*/
.chatblock .friendchatlist .viewrow.status-offline .userpic .status.offline {
    background: #cccccc;
}
.chatblock .friendchatlist .viewrow .username {
    flex: 1;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #35363A;
    text-transform: capitalize;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .sliderchatblock .status {
        display: none;
    }
    .maincontent.networkpage.network-both-sidebar .chatblock,
    .maincontent.networkpage.forpaid.network-both-sidebar .chatblock,
    .chatblock {
        max-width: 100%;
        width: 100%;
        position: relative;
        border-radius: 0px;
        top: 0px;
    }
    .sliderchatblock {
        display: block;
        margin-bottom: 20px;
    }
    .forums .sliderchatblock{
        padding: 10px 10px 0 10px;
    }
    .rightsidebar.chatwrapper {
        display: none;
    }
    .chatblock.sliderchatblock .friendchatlist {
        overflow: hidden;
        border-radius: 0px;
        height: auto;
    }
    .chatblock.sliderchatblock h2.blocktitle {
        padding: 0px 0px 15px;
        margin-bottom: 0px;
        background: none;
        border-radius: 0px;
    }
    .chatblock.sliderchatblock .friendchatlist .viewcontent {
        background: none;
    }
    .chatblock.sliderchatblock .friendchatlist .slick-list {
        margin: 0px 50px;
    }
    .chatblock.sliderchatblock .friendchatlist .viewrow {
        padding: 0px !important;
        text-align: center;
    }
    .chatblock.sliderchatblock .friendchatlist .viewrow .userpic {
        margin: auto auto 10px;
    }
    .chatblock.sliderchatblock .friendchatlist .ant-carousel .slick-prev {
        left: 5px;
    }
    .chatblock.sliderchatblock .friendchatlist .ant-carousel .slick-next {
        right: 5px;
    }
    .chatblock.sliderchatblock .viewcontent .ant-carousel .slick-slide {
        background: none;
        border-radius: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
    
    .chatblock h2.blocktitle {
        font-size: 14px;
        line-height: 21px;
        padding: 14px;
    }
    .chatblock .friendchatlist .viewrow {
        gap: 10px;
        margin-bottom: 14px;
        padding: 8px 14px;
    }
    .chatblock .friendchatlist .viewrow .username {
        font-size: 15px;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1280px) {
    .chatblock h2.blocktitle {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .chatblock {
        width: 25%;
        top: 94px;
    }
    .maincontent.networkpage.forpaid.network-both-sidebar .chatblock {
        top: 374px;
        right: 0px;
    }
}