.forums .forumssearchbox {
    position: fixed;
    width: 49%;
    margin: auto;
    z-index: 1;
}
.forumssearchbox:before {
    content: "";
    width: 100%;
    height: 50px;
    background: #fff;
    position: absolute;
    top: -50px;
}
.forumssearchbox {
    display: flex;
    gap: 24px;
    align-items: center;
    margin: auto;
    padding: 24px 0px;
    background: #fff;
}
.forumssearchbox form {
    margin: 0px;
}
.forumssearchbox .userpic {
    width: 60px;
    height: 60px;
    border-radius: 360px;
    text-align: right;
    position:relative;
}
.forumssearchbox .userpic img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
}
.forumssearchbox .forumssearch {
    flex: 1;
}
.forumssearchbox .forumssearch .formtext {
    width: 100%;
    height: 60px;
    padding: 0px 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
    outline: none;
    box-shadow: none;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 40px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .forums .forumssearchbox {
        position: relative;
        width: 100%;
        max-width: 100%;
        gap: 15px;
    }
    .forumssearchbox .forumssearch .formtext {
        height: 48px;
        font-size: 15px;
    }
    .forumssearchbox .userpic,
    .forumssearchbox .userpic img {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {

}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .forumssearchbox .userpic,
    .forumssearchbox .userpic img {
        width: 40px;
        height: 40px;
    }
    .forumssearchbox .forumssearch .formtext {
        height: 40px;
        font-size: 16px;
    }
    .forums .forumssearchbox {
        width: 46%;
    }
}