.allbookspage.maincontent {
    padding-bottom: 16px;
 }
.allbookslist {
    padding-top: 0px;
}
.allbookslist.viewpodcastslist {
    padding: 0px;
    border-bottom: 0px;
}
.allbookslist .viewbook {
    max-width: 1126px;
}
.allbookslist .viewbook .viewcontent {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.allbookslist .viewbook .viewcontent .viewrow {
    width: 31%;
    display: inline-block !important;
    margin: 0px 1% 2%;
    overflow: hidden;
}
.allbookslist .viewbook .viewcontent .viewrow .bookcontent {
    min-height: 210px;
}
.allbookslist .viewbook .viewcontent .viewrow .imagebox {
    width: 108px;
    float: left;
}
.allbookslist .viewbook .viewcontent .viewrow .content {
    width: 125px;
    float: left;
}
.allbookslist .viewbook .viewcontent .viewrow .imagebox img {
    width: 108px;
    height: 200px;
}
.allbookslist .viewbook .viewcontent .viewrow .description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.allbookslist .viewbook .sharelinkarea {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-top: 12px;
    justify-content: space-between;
}
.allbookslist .viewbook .sharelinkarea .tags {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.allbookslist .viewbook .viewcontent .viewrow .sharelink ul {
    position: relative;
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .allbookslist {
        padding: 0px 20px;
    }
    .allbookslist .viewbook .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;
    }
    .allbookslist .viewbook .viewcontent .viewrow .imagebox
    .allbookslist .viewbook .viewcontent .viewrow .content {
        width: 100%;
        height: auto;
    }
    .allbookslist .viewbook .viewcontent .viewrow .imagebox img {
        width: 100%;
        height: auto;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .allbookslist {
        padding: 0px 20px;
    }
    .allbookslist .viewbook .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;
    }
    .allbookslist .viewbook .viewcontent .viewrow .imagebox
    .allbookslist .viewbook .viewcontent .viewrow .content {
        width: 100%;
        height: auto;
    }
    .allbookslist .viewbook .viewcontent .viewrow .imagebox img {
        width: 100%;
        height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {

    .allbookslist .viewbook .viewcontent .viewrow {
        width: 49%;
        margin: 0px 2% 2% 0px !important;
    }
    .allbookslist .viewbook .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px !important;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1280px) {
    .allbookslist .viewbook .viewcontent .viewrow {
        width: 31%;
        margin: 0px 2% 2% 0px;
    }
    .allbookslist .viewbook .viewcontent .viewrow:nth-child(3n) {
        margin-right: 0px;
    }
    .viewbook .viewcontent .viewrow .description {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .allbookslist {
        padding: 0px 20px;
    }

    .allbookslist .viewbook .viewcontent .viewrow .imagebox {
        height: auto;
    }
}