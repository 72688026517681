.viewuserinformation {
    padding: 39px 56px 24px;
    background: #D5E8F4;
    margin-bottom: 24px;
}
.viewuserinformation.taketourwrapper {
    padding-bottom: 21px;
}
.viewuserinformation .viewcontent {
    max-width: 1440px;
    margin: auto;
}
.viewuserinformation .viewcontent .viewrow {
    display: flex;
    align-items: center;
}
.viewuserinformation .viewcontent .viewrow .userpic {
    width: 100px;
    height: 100px;
    margin-right: 24px;
    border-radius: 360px;
    position: relative;
}
.viewuserinformation .viewcontent .viewrow .userpic img.img.pro {
    border: 4px solid #FFD700;
}
.viewuserinformation .viewcontent .viewrow .userpic .pro-icon{
    bottom: 0;
    right: 12px;
    border: 4px solid #FFD700;
}
.viewuserinformation .viewcontent .viewrow .userpic img {
    width: 100px;
    height: 100px;
    border-radius: 360px;
}
.viewuserinformation .viewcontent .viewrow .username h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #11072F;
    margin-bottom: 0px;
}
.viewuserinformation .viewcontent .taketourcontent {
    text-align: right;
    margin-top: -10px;
}
.viewuserinformation .viewcontent .taketourcontent a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #0771EE;
}

.verifymessage {
    padding: 24px 56px;
}
.verifymessage .viewcontent {
    max-width: 1440px;
    margin: auto;
    font-family: 'Roboto', sans-serif;
}
.verifymessage .viewcontent .viewrow {
    max-width: 878px;
    padding: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #2B2B39;
    background: #FBFBFB;
    border-radius: 10px;
}
.verifymessage.doyoucomplete .viewcontent .viewrow {
    max-width: 854px;
    padding: 36px;
}
.verifymessage .viewcontent .viewrow p {
    margin: 0px;
}
.verifymessage .viewcontent .viewrow a.verify {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #0771EE;
    margin: 24px 0px 55px;
    display: inline-block;
}

.verifymessage .viewcontent .viewrow .formaction {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 31px;
}

.verifymessage .viewcontent .viewrow .formaction a {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0072BB;
    width: 148px;
    text-align: center;
    padding: 11px 0px;
    background: #FEFEFE;
    border: 1px solid #0072BB;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.5s ease;
}

.verifymessage .viewcontent .viewrow .formaction a.yes {
    color: #fff;
    background: #0072BB;
}

.verifymessage .viewcontent .viewrow .formaction a:hover {
    opacity: 0.85;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
    .taketourcontent {
        display: none;
    }
    .viewuserinformation {
        padding: 20px;
    }
    .viewuserinformation .viewcontent .viewrow .userpic {
        width: 50px;
        height: 50px;
        margin-right: 15px;
    }
    .viewuserinformation .viewcontent .viewrow .userpic img {
        width: 50px;
        height: 50px;
    }
    .viewuserinformation .viewcontent .viewrow .username h2 {
        font-size: 16px;
        line-height: 20px;
    }
    .viewuserinformation .viewcontent .taketourcontent {
        text-align: center;
        margin-top: 10px;
    }
    .viewuserinformation .viewcontent .taketourcontent a {
        font-size: 14px;
        line-height: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .viewuserinformation {
        padding: 20px;
    }
    .viewuserinformation .viewcontent .viewrow .userpic {
        width: 90px;
        height: 90px;
    }
    .viewuserinformation .viewcontent .viewrow .userpic img {
        width: 90px;
        height: 90px;
    }
    .viewuserinformation .viewcontent .viewrow .username h2 {
        font-size: 21px;
        line-height: 28px;
    }
    .viewuserinformation .viewcontent .taketourcontent a {
        font-size: 15px;
    }
}