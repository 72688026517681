.player .vp-video-wrapper .vp-preview{
    background-size: cover!important;
}
.vieweducationfilter {
    padding: 14px 0px;
}
.vieweducationfilter .vieweducationcontent {
    max-width: 1440px;
    padding: 0px 56px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vieweducationfilter .vieweducationcontent .userpic {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}
.vieweducationfilter .vieweducationcontent .userpic img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
}
.vieweducationfilter .searchbox {
    flex: 1;
}
.vieweducationfilter .searchbox form {
    display: flex;
    margin: 0px 24px 0px 0px;
}

.vieweducationfilter .searchbox .search_filter {
    flex: 1;
}
.vieweducationfilter .searchbox form .formtext {
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 30px 0px 0px 30px;
    height: 60px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
    font-family: 'Roboto', sans-serif;
    padding: 0px 24px;
    outline: none;
    box-shadow: none;
    width: 100%;
}
.vieweducationfilter .searchbox form .form_action .form_submit {
    background: #0072BB url(../../assets/images/search-icon.svg) no-repeat 50% 50%;
    border-radius: 0px 30px 30px 0px;
    border: 0px;
    color: #fff;
    height: 60px;
    width: 70px;
    text-align: center;
    cursor: pointer;
    font-size: 0px;
}

.searchtabs ul,
.searchtabs ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.searchtabs ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
.searchtabs ul li {
    min-width: 174px;
}
.searchtabs ul li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #11072F;
    display: flex;
    background: #FFFFFF;
    border: 0.8px solid #0072BB;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    height: 58px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.searchtabs ul li a:hover {
    color: #fff;
    background: #0072BB;
}
.viewvideocourselist {
    padding: 24px 0px;
    border-bottom: 1px solid #ECECEC;
}
.viewvideocourse {
    max-width: 1142px;
    margin: auto;
    position: relative;
}
.viewvideocourse > h2 {
    max-width: 1104px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin: 0px 0px 24px 0px;
}

.viewvideocourse .viewcontent {
    max-width: 1128px;
    overflow: visible;
    margin: auto;
}
.viewvideocourse .viewcontent .viewrow {
    width: 31.33%;
    float: left;
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    background: #FBFBFB;
    border-radius: 10px;
}
.viewvideocourse .viewcontent .viewrow .imagebox {
    min-height: 230px;
    position: relative;
    border-radius: 10px 10px 0px 0px;
}
.viewvideocourse .viewcontent .viewrow .imagebox span {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.75) url(../../assets/images/play.svg) no-repeat 50% 50%;
    margin: auto;
    left: 0px;
    right: 0px;
    font-size: 0px;
    cursor: pointer;
}
.viewvideocourse .viewcontent .viewrow .imagebox img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
}
.viewvideocourse .viewcontent .viewrow .titlebox {
    display: flex;
    align-items: center;
    padding: 10px 10px 5px;
}
.viewvideocourse .viewcontent .viewrow .titlebox h2 {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #11072F;
    flex: 1;
    font-family: 'Roboto', sans-serif;
}
.viewvideocourse .viewcontent .viewrow .titlebox h2 a {
    color: #11072F;
    text-decoration: none;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul,
.viewvideocourse .viewcontent .viewrow .sharelink ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul {
    display: flex;
    align-items: center;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li a {
    width: 20px;
    height: 20px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li.bookmark a {
    margin-right: 26px;
     background-image: url(../../assets/images/bookmark.svg); 
    background-size: 12px;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li.share a {
    background-image: url(../../assets/images/share.svg);
    background-size: 14px;
}
.viewvideocourse .viewcontent .viewrow .description {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    padding: 0px 10px;
}
.viewvideocourse .viewcontent .viewrow .createdby {
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: #909090;
    text-align: right;
    padding: 8px 10px 5px;
}
.viewvideocourse .viewall {
    margin: 24px auto auto auto;
    max-width: 1104px;
}
.viewvideocourse .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-decoration: underline;
    color: #0771EE;
}

.ant-carousel .slick-list .slick-slide>div{
    margin: 0px 12px;
}
.ant-carousel .slick-arrow {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}
.ant-carousel .slick-prev {
    left: -54px;
}
.ant-carousel .slick-next {
    right: -54px;
}
.ant-carousel .slick-arrow:before {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all .5s ease;
    border-bottom: 2px solid #0072BB;
    border-right: 2px solid #0072BB;
}

.ant-carousel .slick-arrow.slick-prev:before {
    transform: rotate(135deg);
    left: 5px;
}

.ant-carousel .slick-arrow.slick-next:before {
    right: 2px;
    transform: rotate(-45deg);
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    
    .viewvideocourselist {
        padding-left: 55px;
        padding-right: 55px;
    }
    .viewvideocourse > h2 {
        font-size: 26px;
        line-height: 30px;
        margin: 0px auto 15px auto;
    }
    .viewcontent .slick-list .slick-slide>div {
        margin: 0px;
    }
    .ant-carousel .slick-arrow {
        width: 25px;
        height: 25px;
    }
    .ant-carousel .slick-prev {
        left: -35px;
    }
    .ant-carousel .slick-next {
        right: -35px;
    }
    .vieweducationfilter .searchbox form {
        margin: 0px;
    }
    .vieweducationfilter {
        padding: 0px;
    }
    .vieweducationfilter .vieweducationcontent {
        flex-wrap: wrap;
        padding: 14px 20px;
    }
    .vieweducationfilter .vieweducationcontent .userpic,
    .vieweducationfilter .vieweducationcontent .userpic img {
        width: 50px;
        height: 50px;
    }
    .searchtabs {
        flex: 100%;
    }
    .vieweducationfilter .searchbox form .formtext {
        height: 50px;
        font-size: 13px;
    }
    .vieweducationfilter .searchbox form .form_action .form_submit {
        width: 60px;
        height: 50px;
    }
    .searchtabs ul {
        gap: 0px;
        flex-wrap: wrap;
        text-align: center;
    }
    .searchtabs ul li {
        min-width: auto;
        flex: 48%;
        width: 48%;
        display: inline-block;
        vertical-align: middle;
        margin-top: 3%;
        margin-right: 3%;
    }
    .searchtabs ul li:nth-child(2n) {
        margin-right: 0px;
    }
    .searchtabs ul li a {
        font-size: 14px;
        height: 40px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .vieweducationfilter {
        padding: 0px;
    }
    .vieweducationfilter .vieweducationcontent {
        padding: 14px 20px;
    }
    .vieweducationfilter .vieweducationcontent .userpic,
    .vieweducationfilter .vieweducationcontent .userpic img {
        width: 40px;
        height: 40px;
    }
    .vieweducationfilter .searchbox form .formtext {
        height: 40px;
        font-size: 13px;
    }
    .vieweducationfilter .searchbox form .form_action .form_submit {
        width: 50px;
        height: 40px;
    }
    .searchtabs {
        flex: 1 1 40%;
    }
    .searchtabs ul {
        gap: 0px;
    }
    .searchtabs ul li {
        flex: 1;
        min-width: 120px;
        margin-right: 2%;
    }
    .searchtabs ul li:last-child {
        margin-right: 0px;
    }
    .searchtabs ul li a {
        font-size: 14px;
        height: 40px;
    }
    .viewvideocourselist {
        padding-left: 55px;
        padding-right: 55px;
    }
    .viewvideocourse > h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .viewcontent .slick-list .slick-slide>div {
        margin: 0px 8px;
    }
    .ant-carousel .slick-arrow {
        width: 35px;
        height: 35px;
    }
    .ant-carousel .slick-prev {
        left: -45px;
    }
    .ant-carousel .slick-next {
        right: -45px;
    }
}