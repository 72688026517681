.modal-buttons {
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.modal-buttons button {
    margin: 20px 5px 0;
}
.signinpage {
    position: relative;
}
.signinpage::before {
    display: none;
}
.signinpage .signupwrapper {
    gap: 0px;
    background: url(../../assets/images/signin-bg.svg) no-repeat 0% 0%;
}
.signinpage .signupintroduction {
    background: none;
}
.signinpage .signupintroduction h3 {
    width: auto;
    padding-bottom: 0px;
}
.signinpage .realtorimagebox {
    position: relative;
}
.signinpage .realtorimagebox {
    position: relative;
    margin-top: 66px;
    padding-top: 130px;
    width: 480px;
}
.signinpage .realtorimagebox .realtor1 {
    position: absolute;
    top: 0px;
    left: 27px;
}
.signinpage .realtorimagebox .realtor3 {
    position: absolute;
    right: 0px;
    bottom: 4px;
}

.signinpage .registration-form-wrap .registration-form {
    width: 408px;
    float: right;
    margin-right: 27%;
}
.signinpage .registration-form-wrap .registration-form h2 {
    margin-bottom: 30px;
}
.signinpage .registration-form-wrap .registration-form form {
    padding: 0px;
    overflow: hidden;
    width: 100%;
}
.signinpage .registration-form-wrap .registration-form .form-item {
    width: 99%;
    margin: 0px auto 30px;
    float: none;
    display: block;
}
.signinpage .registration-form-wrap .registration-form .form-item.password {
    margin-bottom: 0px;
}
.signinpage .registration-form-wrap .registration-form .form-item:nth-child(even) {
    margin-right: auto;
}
.signinpage .registration-form-wrap .registration-form .social-auth-login .google, 
.signinpage .registration-form-wrap .registration-form .social-auth-login .facebook {
    max-width: 230px;
    margin: auto;
    float: none;
    width: 100%;
}
.signinpage .registration-form-wrap .registration-form .social-auth-login .google {
    margin-bottom: 24px;
}

.signinpage .registration-form-wrap .registration-form .already-account {
    margin-top: 54px;
}
.signinpage .forgotpassword {
    width: 100%;
    text-align: right;
    font-size: 11px;
    line-height: 16px;
    color: #0072BB;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
}
.registration-form-wrap .registration-form .form-action {
    text-align: center;
}
.registration-form-wrap .registration-form .form-action {
    margin-top: 36px;
}
.registration-form-wrap .registration-form h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #11072F;
    margin: 0px;
}

.ant-modal-body .ant-form .form-text {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}

.ant-modal-body #forgetpassword .email label {
    font-size: 18px;
}
.ant-modal-body #forgetpassword .email {
    margin-bottom: 0px;
}
.ant-modal-body #forgetpassword .email + span {
    width: 100%;
    text-align: right;
    font-size: 11px;
    line-height: 16px;
    color: #0072BB;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
}
.ant-modal-body #forgetpassword .modal-buttons .ant-btn {
    color: #0072BB;
    border: 1px solid #0072BB;
    background: none;
}

.ant-modal-body #forgetpassword .modal-buttons .ant-btn.ant-btn-primary {
    color: #fff;
    background: #0072BB;
}

@media only screen and (min-width: 768px) {
.fpass .registration-form-wrap{
    position: relative;
}
.fpass .registration-form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .signinpage::before {
        display: none;
        background: #F9FDFF;
    }
    .signinpage .signupwrapper {
        padding: 0px;
    }
    .signinpage .signupintroduction {
        width: 100%;
        text-align: center;
        padding: 30px 20px;
        background: #F9FDFF;
    }
    .signinpage .realtorimagebox {
        width: 100%;
        position: relative;
        margin-top: 25px;
        text-align: center;
        padding-top: 75px;
    }
    .signinpage .realtorimagebox .realtor1 {
        top: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
        max-width: 250px;
        text-align: left;
    }
    .signinpage .realtorimagebox .realtor1 img {
        max-width: 100px;
    }
    .signinpage .realtorimagebox .realtor2 img {
        width: 100%;
        max-width: 275px;
        margin: auto;
        display: inline-block;
        vertical-align: middle;
    }
    .signinpage .realtorimagebox .realtor3 {
        right: 0px;
        bottom: 44px;
        left: 0px;
        margin: auto;
        max-width: 205px;
        text-align: right;
    }
    .signinpage .realtorimagebox .realtor3 img {
        max-width: 55px;
    }
    .signinpage .registration-form-wrap {
        width: 100%;
        background: #F9FDFF;
        padding: 0px 20px 56px;
    }
    .signinpage .registration-form-wrap .registration-form {
        width: 100%;
        float: none;
        margin: auto;
        max-width: 408px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .signinpage .signupintroduction {
        text-align: center;
    }
    .signinpage .signupintroduction h3 {
        max-width: 100%;
    }
    .signinpage .registration-form-wrap .registration-form {
        width: 380px;
        float: none;
        margin: auto;
    }

    .signinpage .realtorimagebox {
        width: 100%;
        position: relative;
        margin-top: 25px;
        text-align: center;
        padding-top: 75px;
    }
    .signinpage .realtorimagebox .realtor1 {
        top: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
        max-width: 250px;
        text-align: left;
    }
    .signinpage .realtorimagebox .realtor1 img {
        max-width: 100px;
    }
    .signinpage .realtorimagebox .realtor2 img {
        width: 100%;
        max-width: 275px;
        margin: auto;
        display: inline-block;
        vertical-align: middle;
    }
    .signinpage .realtorimagebox .realtor3 {
        right: 0px;
        bottom: 44px;
        left: 0px;
        margin: auto;
        max-width: 205px;
        text-align: right;
    }
    .signinpage .realtorimagebox .realtor3 img {
        max-width: 55px;
    }
}