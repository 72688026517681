.forums .maindiv {
    max-width: 1440px;
    padding: 0px;
}
.discussion-main {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
}
.discussion-main a {
    color: #999;
    display: inline-block;
    vertical-align: middle;
}
.discussion-main a:hover {
    color: #0072BB;
}
.forums.maincontent .maindiv .midcontent {
    float: none;
    margin: auto;
    overflow: hidden;
}
.forums.maincontent.sidebarleft .maindiv .midcontent {
    width: 73%;
    float: left;
    margin-left: 25.5%;
    margin-right: 0px;
}
.subcat-topiclist,
.topicListContainer {
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
}
.subcat-topiclist .content-top-sub,
.topicListContainer .content-top-sub {
    width: 73%;
    float: left;
    margin-left: 25.5%;
    margin-right: 0px;
}
.subcat-topiclist .forumspostscontent,
.topicListContainer .subcat-topiclist {
    width: 73%;
    float: left;
    margin-left: 25.5%;
    padding-bottom: 56px;
}
.topicListContainer .subcat-topiclist .forumspostscontent {
    width: 100%;
    margin-left: 0;
    padding-bottom: 0px;
    padding-top: 18px;
}
.topicListContainer .forum {
    width: 100%;
    overflow: hidden;
}
.subcat-topic-warapper.subcat-both-sidebar .content-top-sub {
    width: 49%;
    margin: auto;
    float: none;
    max-width: 706px;
}
.subcat-topic-warapper.subcat-both-sidebar .content-top-sub .forumssearchbox {
    width: 49%;
    max-width: 706px;
}
.subcat-topic-warapper.subcat-both-sidebar .s-title {
    padding-bottom: 0px;
}
.forum {
    min-height: 520px;
}
.s-title {
    font-size: 24px;
    text-align: center;
    color: #000;
    text-transform: capitalize;
    padding-top: 108px;
    padding-bottom: 20px;
}
.s-title.public {
    margin-top: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.subcat-topiclist .comment-form textarea {
    overflow: auto;
    padding: 12px 15px;
    font-size: 14px;
    line-height: 18px;
    height: 80px;
    resize: none;
    letter-spacing: 0.02em;
    color: #2B2B39;
    outline: none;
    background: #FEFEFE;
    border-radius: 6px;
    border: 0px;
}
.content-top-sub .forumssearchbox {
    width: 73%;
    max-width: 1051px;
    position: fixed;
    z-index: 1;
}
.forum-maincat .forumspostscontent {
    padding-top: 0px;
}
.subcat-topiclist .forumspostscontent {
    padding-top: 108px;
}
.forumspostscontent {
    padding-top: 88px;
}
.bothsidebar .catforumspostscontent,.sidebarleft .catforumspostscontent {
    padding-top: 20px;
}
.anonymousforum .forum-maincat .forumspostscontent {
    padding-top: 18px;
}
.forumsposts .viewrow {
    padding: 24px;
    background: #ECECEC;
    border-radius: 10px;
    margin-bottom: 24px;
}
.forumsposts .catviewrow {
    margin-bottom: 24px;
}
.forumsposts .viewrow:last-child,
.forumsposts .catviewrow:last-child {
    margin-bottom: 0px;
}
.forumsposts .viewrow .userinfomation {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}
.forumsposts .viewrow .userinfomation .userpic,
.forumsposts .viewrow .userinfomation .userpic img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 360px;
    position: relative;
}
.comment-list .userpic, .comment-list .userpic img{
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 360px;
}
.forumsposts .viewrow .userinfomation .title h2 {
    margin: 0px;
}
.forumsposts .viewrow .userinfomation .title h2 a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    text-decoration: none;
    text-transform: capitalize;
}
.forumsposts .viewrow .userinfomation .title h2 a:hover{
    color:#0072BB;
}

.forumsposts .viewrow .userinfomation .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}

.forumsposts .viewrow .userinfomation .createdby {
    flex: 1;
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
}
.forumsposts .viewrow .userinfomation .createdby .created {
    margin-bottom: 10px;
}
.forumsposts .viewrow .userinfomation .createdby button {
    width: 25px;
    height: 25px;
    padding: 0px;
    font-size: 0px;
    border: 0px;
    display: inline-block;
    vertical-align: middle;
    margin: 10px 0px 5px;
    cursor: pointer;
    box-shadow: none;
    background: url(../../../assets/delete.png) no-repeat 50% 50%;
    background-size: 20px;
}
.forumsposts .viewrow .userinfomation .userconnect a {
    width: 40px;
    height: 40px;
    border-radius: 360px;
    display: block;
    font-size: 0px;
    cursor: pointer;
    background: #fff url(../../../assets/images/userfriend.svg) no-repeat 50% 50%;
}
.forumsposts .viewrow .userinfomation .unfollowbutton {
    width: 40px;
    height: 40px;
    border-radius: 360px;
    display: flex;
    font-size: 0px;
    align-items: center;
    cursor: pointer;
    background: #fff;
}
.forumsposts .viewrow .userinfomation .unfollowbutton .dot {
    width: 40px;
    height: 12px;
    display: block;
    background: url(../../../assets/images/dot.svg) repeat-y 50% 50%;
}
.forumsposts .viewrow .contentbox {
    display: flex;
    gap: 8px;
}
.forumsposts .viewrow .contentbox .content {
    flex: 1;
}
.forumsposts .viewrow .contentbox .followpost {
    min-width: 98px;
}
.forumsposts .viewrow .content .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    flex: 1;
}
.forumsposts .viewrow .content .body {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 6px 0px 10px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}

.forumsposts .viewrow .contentbox .followpost a {
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #fff;
    padding: 6px 14px;
    text-decoration: none;
    border-radius: 4px;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s ease;
    background-color: #0072BB;
}
.forumsposts .viewrow .contentbox .followpost a:hover {
    color: #0072BB;
    background-color: #fff;
}

.forumsposts .postsocialshare {
    padding: 0px 0px 12px;
}
.forumsposts .commentformwrapper {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.forumsposts .commentformwrapper {
    padding: 0px;
}
.forumsposts .commentformwrapper textarea {
    padding: 12px 15px;
    font-size: 14px!important;
    line-height: 18px;
    height: 80px!important;
    overflow: auto!important;
}
.commentlistwrapper .comment-list {
    width: 100%;
    overflow: hidden;
}
.commentlistwrapper .comment-list .commentitem {
    width: 100%;
    overflow: hidden;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 12px;
}
.comment-list .commentitem .userinfomation {
    align-items:initial;
    gap: 0px;
    margin-bottom: 0px;
}
.comment-list .commentitem .userinfomation .userpic {
    margin-right: 12px;
}
.comment-list .commentitem .userinfomation .userpic, 
.comment-list .commentitem .userinfomation .userpic img {
    width: 40px;
    height: 40px;
}
.comment-list .commentitem .userinfomation .userpic .pro-icon{
    width: 15px;
    height: 15px;
    background-size: 15px;
    bottom: 0px;
    right: 0px;
    padding: 5px;
}
.comment-list .commentitem .commentcontent {
    flex: 1;
}
.comment-list .commentitem .commentusername {
    font-size: 11px;
    line-height: 12px;
    color: #11072F;
    text-transform: capitalize;
    margin-bottom: 3px;
}
.comment-list .commentitem .commentdesc {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.comment-list .commentitem .commentposted {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #909090;
    margin-top: 5px;
    text-align: right;
}
.loading {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 10px #00276617;
    width: 100%;
    padding: 20px;
}
  
.skeleton + .skeleton {
    margin-top: 20px;
}

.addforumspost .ant-modal {
  width: 100% !important;
  max-width: 856px;
  padding: 0px 20px;
}
.addforumspost .ant-modal-content {
  border-radius: 10px;
}

.addforumspost .ant-modal-body {
  width: 100%;
  padding: 0px;
  overflow: auto;
  background: #FBFBFB;
  border-radius: 10px;
  position: relative;
}

.addforumspost .ant-modal-close {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: 24px;
  top: 24px;
  border-radius: 36px;
  cursor: pointer;
  background:#fff url(../../../assets/images/popupclose.svg) no-repeat 50% 50%;
}
.addforumspost .ant-modal-close .ant-modal-close-x {
  display: none;
}
.addforumspost .header {
    text-align: center;
    padding: 28px;
    border-bottom: 1px solid #EEEEEE;
    margin: 0px 12px;
}
.addforumspost .header h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #11072F;
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
}
.addforumspost .userinfomation {
    display: flex;
    align-items: center;
    padding: 24px 24px 0px;
}
.addforumspost .userinfomation .userpic {
    margin-right: 6px;
}
.addforumspost .userinfomation .userpic, 
.addforumspost .userinfomation .userpic img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 360px;
    position: relative;
}
.addforumspost .userinfomation .userpic .pro-icon{
    width: 14px;
    height: 14px;
    background-size: 14px;
    bottom: -2px;
    right: -2px;
    padding: 5px;
}
.addforumspost .userinfomation .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #11072F;
    text-transform: capitalize;
}
.addforumspost .userinfomation .description {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.addforumspost .userinfomation .category {
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    padding: 5px 25px 5px 12px;
    border-radius: 20px;
    margin-left: 10px;
    background: #fff url(../../../assets/images/close-button.svg) no-repeat right 8px center
}
.forumpostformwrapper {
    padding:16px 24px 24px;
}
.forumpostformwrapper .ant-form-item .ant-input,
.forumpostformwrapper .ant-form-item textarea {
    width: 100%;
    height: 40px;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    overflow: hidden;
    outline: none;
    resize: none;
    background: #FEFEFE;
    border-radius: 6px;
}

.forumpostformwrapper .ant-form-item textarea.ant-input {
    height: 120px;
}
.forumpostformwrapper .ant-form-item:last-child,
.forumpostformwrapper .formaction {
    text-align: center;
    margin-bottom: 0px;
}

.forumpostformwrapper .formaction .ant-btn,
.forumpostformwrapper .ant-form-item:last-child .ant-btn {
    min-width: 166px;
    border-radius: 6px;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: none;
    text-align: center;
}

.forumpostformwrapper .ant-collapse {
    border: 0px;
    border-radius: 0px;
    margin: 5px;
}
.forumpostformwrapper .category-list {
    max-height: calc(100vh - 150px);
    overflow: auto;
    padding-right: 0px;
}
.forumpostformwrapper .category-list .ant-radio-group {
    width: 100%;
}
.forumpostformwrapper .category-list label.radio-item {
    margin: 0px;
    border: 0px;
    padding: 15px 12px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    box-shadow: 0px 0px 4px #0072bb;
    border-radius: 4px;
    margin-bottom: 16px;
    white-space: break-spaces;
    word-break: break-word;
}
.forumpostformwrapper .category-list label.radio-item:last-child {
    margin-bottom: 0px;
}
.forumpostformwrapper .category-list label.radio-item:hover {
    color: #fff;
    background-color: #0072bb;
}
.forumpostformwrapper .category-list label.radio-item .ant-radio {
    display: none;
}
.forumpostformwrapper .category-list label.radio-item span {
    padding: 0px;
}
.forumpostformwrapper .ant-collapse > .ant-collapse-item {
    border: 0px;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    box-shadow: 0px 0px 4px #0072bb;
}
.forumpostformwrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 20px 16px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    background-color: #fff;
    border-radius: 4px;
}
.forumpostformwrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header span.anticon {
    display: none;
}
.forumpostformwrapper .ant-collapse > .ant-collapse-item:last-child {
    margin-bottom: 0px;
}

.addforumspost .steps-content .back-button {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    border-radius: 360px;
    cursor: pointer;
    background:#fff !important;
    position: absolute;
    left: 24px;
    top: 24px;
}
.addforumspost .steps-content .back-button span.anticon {
    width: 5px;
    margin-left: -18px;
    margin-top: 3px;
}

.addforumspost .back-button::after {
    width: 15px;
    height: 2px;
    content: "";
    position: absolute;
    left: 0;
    right: -4px;
    bottom: 0;
    top: 0px;
    margin: auto;
    background: #0072BB;
}

.addforumspost .header.thankyou {
    margin: 0px;
    border-bottom: 0px;
    text-align: left;
    padding-top: 48px;
}
/* scrollbar */
.addforumspost ::-webkit-scrollbar,
.forumpostformwrapper .category-list ::-webkit-scrollbar {
    width: 6px;
}
.addforumspost  ::-webkit-scrollbar-track,
.forumpostformwrapper .category-list  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.addforumspost ::-webkit-scrollbar-thumb,
.forumpostformwrapper .category-list ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.addforumspost ::-webkit-scrollbar-thumb:hover,
.forumpostformwrapper .category-list ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
/* scrollbar */

.addforumspost .thankyoumessage {
    font-size: 18px;
    line-height: 30px;
    color: #2B2B39;
    padding-top: 0px;
    max-height: calc(100vh - 260px);
    overflow: auto;
}
.addforumspost .thankyoumessage .ant-checkbox-group {
    margin: 24px 0px;
}
.addforumspost .thankyoumessage .ant-checkbox-group label {
    font-size: 18px;
    line-height: 30px;
    color: #2B2B39;
    margin-bottom: 12px;
}
.addforumspost .thankyoumessage .intro-message-option {
    margin: 20px 0px;
}
.intro-message-option .ant-form-item,
.addforumspost .thankyoumessage .ant-checkbox-group .ant-col:last-child label {
    margin-bottom: 0px;
}
.thankyoumessage .ant-form-item .ant-checkbox-checked::after {
    display: none;
}
.addforumspost .thankyoumessage .ant-checkbox-group label .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid #C4C4C4;
}
.addforumspost .thankyoumessage .ant-checkbox-inner .ant-checkbox + span {
    padding-left: 20px;
}
.addforumspost .thankyoumessage .package-message,
.addforumspost .thankyoumessage .agree-message {
    margin-bottom: 24px;
}
.addforumspost .thankyoumessage .agreement .ant-checkbox-wrapper {
    display: flex;
    font-size: 18px !important;
    line-height: 22px;
    color: #2B2B39;
}
.addforumspost .thankyoumessage .agreement .ant-checkbox-wrapper .ant-checkbox + span {
    margin-top: -4px;
    font-size: 16px;
}

.addforumspost .thankyou.formaction {
    text-align: right;
    margin: 25px auto 20px;
    padding: 0px 20px;
}
.addforumspost .thankyou.formaction .edit {
    color: #0072BB;
    border: 1px solid #0072BB;
    background: #fff;
}

body.modalopen {
    overflow: hidden;
}
.ant-modal-mask {
    background-color: rgba(196, 196, 196, 0.5);
}
.registrationpopup .ant-modal {
    width: 100% !important;
    max-width: 632px;
    padding: 0px 20px;
    margin-top: 60px !important;
}
/* scrollbar */
.registrationpopup ::-webkit-scrollbar {
    width: 6px;
}
.registrationpopup  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.registrationpopup ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.registrationpopup ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
/* scrollbar */
.registrationpopup div[class*=registrationform] {
    width: auto!important;
    float: unset!important;
    max-width: 100%;
    max-height: calc(100vh - 135px);
}
.registrationpopup div[class*=registrationform] .socialauthlogin a {
    padding: 13px 12px 13px 35px;
    width: 190px;
}
.registrationpopup .alreadyaccount {
    padding-bottom: 30px;
}

.registrationpopup .ant-modal-content {
    border-radius: 5px;
}
.registrationpopup .ant-modal-body {
    width: 100%;
    padding: 0px;
    overflow: auto;
    margin-top: 0px !important;
    background: #FCFCFC;
    border-radius: 6px;
    position: relative;
}
.registrationpopup .registrationform {
    width: 100%;
}
.registrationpopup .ant-modal-close {
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    right: 24px;
    top: 29px;
    border-radius: 36px;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
    background: url(../../../assets/images/popupclose.svg) no-repeat 50% 50%;
}
.registrationpopup .ant-modal-close .ant-modal-close-x {
    display: none;
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
    .forumsposts .viewrow .userinfomation .createdby {
        flex-wrap: wrap;
        text-align: left;
    }
    .forumsposts .viewrow .userinfomation .createdby .created {
        flex: 1 100%;
        margin-right: 0px !important;
        margin-bottom: 5px !important;
    }
    .forumsposts .viewrow .userinfomation .createdby .date {
        flex: 1 100%;
    }
}

@media only screen and (min-width: 401px) and (max-width: 767px) {

}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .registrationpopup .ant-modal-close {
        width: 30px;
        height: 30px;
        right: 0px;
        top: -45px;
        background:#fff url(../../../assets/images/popupclose.svg) no-repeat 50% 50%;
    }
    .forumspostscontent {
        padding: 0px 20px;
    }
    .subcat-topiclist .forumspostscontent {
        padding: 0px 0px;
    }
    .forums.maincontent.sidebarleft .maindiv .midcontent {
        width: 100%;
        margin: 0px;
        overflow: hidden;
    }
    .forum {
        min-height: 100px;
    }
    .forumsposts .viewrow {
        padding: 15px;
        margin-bottom: 15px;
        word-break: break-word;
    }
    .registrationformwrap {
        width: 100%;
    }
    .registrationpopup div[class*=registrationform] {
        max-width: 100%;
        margin: auto;
    }
    .registrationformwrap .registrationform h2 {
        font-size: 20px;
    }
    .addforumspost .header {
        padding: 15px 50px 15px 15px;
        margin: 0px;
    }
    .addforumspost .header.thankyou {
        padding-top: 25px;
    }
    .addforumspost .header h2 {
        font-size: 18px;
    }
    .addforumspost .ant-modal-close {
        width: 30px;
        height: 30px;
        right: 10px;
        top: 14px;
    }
    .addforumspost .steps-content .back-button {
        width: 30px;
        height: 30px;
        left: 10px;
        top: 14px;
    }
    .addforumspost .steps-content .back-button span.anticon svg {
        width: 15px;
    }
    .addforumspost .steps-content .back-button::after {
        width: 12px;
        right: 1px;
    }
    .forumpostformwrapper .ant-form-item .ant-input, 
    .forumpostformwrapper .ant-form-item textarea {
        font-size: 14px;
        line-height: 26px;
    }
    .addforumspost .userinfomation {
        padding: 15px 15px 0px;
        align-items: initial;
    }
    .addforumspost .userinfomation .category {
        margin-top: 5px;
        display: block;
        margin-left: 0px;
    }
    .forumpostformwrapper {
        padding: 15px 15px 25px;
    }
    .addforumspost .thankyou.formaction button {
        min-width: auto;
        font-size: 13.5px;
    }
    .forumpostformwrapper .formaction .ant-btn, 
    .forumpostformwrapper .ant-form-item:last-child .ant-btn {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
    }
    .forumpostformwrapper .ant-collapse > .ant-collapse-item {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 19px;
    }
    .forumpostformwrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 8px 13px;
        padding-left: 35px;
    }
    .forumpostformwrapper .category-list label.radio-item {
        padding: 5px 16px;
        font-size: 14px;
        line-height: 24px;
    }
    .forumpostformwrapper .category-list label.radio-item:last-child {
        margin-bottom: 0px;
    }
    .addforumspost .header.thankyou h2 {
        font-size: 16px;
        line-height: 25px;
    }
    .addforumspost .thankyoumessage {
        font-size: 15px;
        line-height: 25px;
    }
    .addforumspost .thankyoumessage .ant-checkbox-group label {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 15px;
        display: flex;
    }
    .addforumspost .thankyoumessage .agreement .ant-checkbox-wrapper {
        font-size: 15px !important;
        line-height: 25px;
    }
    .addforumspost .thankyoumessage .formaction {
        text-align: right;
        margin-top: 30px;
    }
    .addforumspost .thankyoumessage .formaction .ant-btn {
        min-width: 85px;
    }
    .subcat-topiclist .content-top-sub, 
    .topicListContainer .content-top-sub {
        width: 100%;
        margin-left: 0px;
        padding: 0px 20px;
    }
    .subcat-topic-warapper.subcat-both-sidebar .content-top-sub {
        width: 100%;
        margin-left: 0px;
        padding: 0px 20px;
        max-width: 100%;
    }
    .subcat-topic-warapper.subcat-both-sidebar .content-top-sub .forumssearchbox {
        width: 100%;
        max-width: 100%;
    }
    .content-top-sub .forumssearchbox {
        width: 100%;
        gap: 0px;
        position: relative;
        padding-bottom: 0px;
    }
    .subcat-topiclist .forumspostscontent, 
    .topicListContainer .subcat-topiclist {
        max-width: 100%;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .subcat-topiclist .forumspostscontent {
        padding: 20px;
    }
    .s-title {
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 19px;
    }
    .subcat-topiclist,
    .topicListContainer {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .subcat-topiclist .content-top-sub,
    .topicListContainer .content-top-sub,
    .subcat-topiclist .forumspostscontent,
    .topicListContainer .subcat-topiclist {
        width: 71%;
        margin-left: 27%;
    }
    .forums.maincontent.sidebarleft .maindiv .midcontent {
        width: 71%;
        margin-left: 27%;
    }
    .content-top-sub .forumssearchbox {
        width: 71%;
    }
    .s-title {
        padding-top: 88px;
        padding-bottom: 0px;
    }
    .s-title.public {
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .forum-maincat .forumspostscontent {
        padding-top: 20px;
    }
    .forumspostscontent {
        padding-top: 88px;
    }
    .forums.bothsidebar .maindiv .leftsidebar {
        width: 22%;
        float: left;
    }
    .forums.bothsidebar .maindiv .rightsidebar {
        width: 25%;
        float: right;
    }
    .forums.bothsidebar .maindiv .midcontent {
        width: 46%;
    }
    .forum {
        min-height: 100px;
    }
    .forumsposts .viewrow {
        padding: 24px 15px;
    }
    .forumsposts .viewrow .userinfomation {
        gap: 15px;
    }
    .forumsposts .viewrow .userinfomation .userpic,
    .forumsposts .viewrow .userinfomation .userpic img {
        width: 40px;
        height: 40px;
    }
    .forumsposts .viewrow .userinfomation .title h2 a {
        font-size: 15px;
        line-height: 25px
    }
    .forumsposts .viewrow .userinfomation .category {
        font-size: 13px;
        line-height: 18px;
    }
    .forumsposts .viewrow .userinfomation .userconnect a {
        width: 35px;
        height: 35px;
    }
    .forumsposts .viewrow .userinfomation .createdby {
        font-size: 12px;
        line-height: 12px;
    }
    .forumsposts .viewrow .content .title {
        font-size: 16px;
        line-height: 24px;
    }
    .forumsposts .viewrow .content .body {
        padding: 6px 0px 15px;
        font-size: 14px;
        line-height: 22px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1280px) {
    .forumsposts .viewrow .userinfomation {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 10px;
        gap: 10px;
        line-height: 16px;
    }
    .forumsposts .viewrow .content .title {
        font-size: 16px;
        line-height: 22px;
    }
    .forumsposts .viewrow .content .body {
        padding: 6px 0px 10px;
        font-size: 14px;
        line-height: 22px;
    }
    .forumsposts .viewrow .userinfomation .createdby {
        flex: 100%;
        display: flex;
        align-items: center;
        gap: 0px;
        justify-content: space-between;
    }
    .forumsposts .viewrow .userinfomation .createdby .created {
        margin-bottom: 0px;
        margin-right: 10px;
    }
    .forumsposts .viewrow .contentbox {
        flex-wrap: wrap;
        padding-bottom: 15px;
    }
    .forumsposts .viewrow .contentbox .content {
        flex: 100%;
    }
    
}