.conversation {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 16px;
}
.chatMenuItem {
  position: relative;
}
.chatMenuItem a {
  color: #000;
}
.active .conversation {
  background: #ECF3FF;
  color: #000;
  border-left: 4px solid #77A7FF;
}
.message-unread {
  font-size: 10px;
  margin: 0 10px;
  color: #0ecd63;
  letter-spacing: 0.5px;
}

.conversationhead {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdfe2;
  -webkit-box-shadow: 0 2px 3px 0 rgb(56 65 75 / 10%);
  -moz-box-shadow: 0 2px 3px 0 rgba(56,65,75,.1);
  box-shadow: 0 2px 3px 0 rgb(56 65 75 / 10%);
  background: #fff;
}

.conversationhead>div{
  position: relative;
}
.conversationhead>div .pro-icon{
  right: 20px;
}
.conversationhead .lazy-load-image-background{
  height: 50px!important;
  width: 50px!important;
  margin-right: 20px!important;
}
  .conversation:hover {
    background-color: #ECF3FF;
  }
  .conversationImageContainer {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  .conversationImageContainer .lazy-load-image-background{
    width: 100%!important;
    height: auto!important;
  }
  .online-status .conversationImageContainer img{
    border: 2px solid #04d800;
  }
  .offline-status .conversationImageContainer img{
    border: 2px solid #cccccc;
  }
  .offline-status .conversationImageContainer img.img.pro {
    border: 2px solid #FFD700;
  }
  .conversationImageContainer .pro-icon{
    z-index: 1;
  }
  .conversationImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  .online, .offonline {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    border: 2px solid #fff;
    bottom: 0px;
}
.online{
  background-color: limegreen;
}
.offonline{
  background-color: #ccc;
}
.asonline img {
  border: 2px solid limegreen;
}
.conversationName .say {
  font-size: 14px;
  font-weight: normal;
  color: #675757;
}
.chatMenuItem a.unread:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0px;
  bottom: 0px;
  width: 12px;
  height: 12px;
  margin: auto;
  border-radius: 36px;
  background: #1876f2;
}
.new-message .conversationName .say {
  color: #1890ff;
  font-weight: bold;
}
  .conversationhead .conversationName{
    font-weight: bold;
    font-size: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .conversation{
      justify-content: center;
      padding: 10px 0;
      margin:0;
      display: block;
      text-align: center;
    }
    .conversationhead {
      padding: 0 10px 10px;
    }
    .chatMenuItem .conversationName .fname{
      font-size: 14px;
    }
    .chatMenuItem .conversationName .lname, .chatMenuItem .conversationName .say {
      display: none;
    }
    .chatMenuItem .conversationImg{
      margin-right: 0;
    }
    .chatMenuItem a.unread:after{
      content: none;
    }

  }