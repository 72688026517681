.leftsidemenu {
    position: fixed;
    max-width: 248px;
    width: 18.31%;
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
}

.leftsidemenu ul,
.leftsidemenu ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.leftsidemenu ul {
    padding: 18px 28px;
}
.leftsidemenu ul li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #2B2B2B;
    text-decoration: none;
    margin-bottom: 16px;
    display: block;
}

.leftsidemenu ul li:last-child a {
    margin-bottom: 0px;
}