.bell-notification {
  position: relative;
  background: #f2f2f2;
  min-height: calc(100vh - 430px);
}
.name-s {
  text-transform: capitalize;
}
.bell-notification>div {
  max-width: 680px;
  margin: 100px auto 50px auto;
  border: 1px solid #dcdcdc;
  padding: 20px 10px 10px;
  border-radius: 10px;
  background: #fff;
}
.bell-notification h2{
  font-size: 25px;
  line-height: 24px;
  padding: 0px 10px;
  color: #1c1e21;
  font-weight: 500;
  border-bottom: 0px;
}
header .bell-notification {
  background: none;
  min-height: 40px;
}
header .bell-notification>div.bell-notification {
  max-width: 500px;
  margin: auto;
  border: 0px;
  padding: 0px;
  border-radius: 0px;
}
.bell-notification button {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  outline: none!important;
  line-height: unset;
  position: relative;
  border-radius: 36px;
  box-shadow: none;
  transition: none;
  border: 0px;
  background: #e4e6eb url(../../assets/images/alert-notification.svg) no-repeat 50% 45%;
}

.bell-notification .counter {
  width: 20px;
  height: 20px;
  position: absolute !important;
  background: red;
  border-radius: 36px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  top: -5px;
  vertical-align: middle;
  right: -5px;
  line-height: 20px;
}
.bell-notification button:focus,
.bell-notification button:hover,
.bell-notification button.ant-popover-open {
  background: #e7f3ff url(../../assets/images/alert-notification.svg) no-repeat 50% 45%;
}
.ant-popover.onlynotification {
  position: fixed !important;
  top: 60px !important;
  right: 20px;
  left: auto !important;
  width: 360px;
}
.ant-popover.onlynotification .ant-popover-content {
  padding: 20px 10px;
  background: #fff;
  max-height: calc(100vh - 80px);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 10px !important;
  overflow: auto;
}
.ant-popover.onlynotification .ant-popover-content .ant-popover-arrow {
  display: none;
}
.ant-popover.onlynotification .ant-popover-content .ant-popover-inner {
  background: none;
  box-shadow: none;
}
/* scrollbar */
.ant-popover.onlynotification ::-webkit-scrollbar {
  width: 6px;
}
.ant-popover.onlynotification ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
.ant-popover.onlynotification ::-webkit-scrollbar-thumb {
  background: #8b8888; 
  border-radius: 10px;
}
.ant-popover.onlynotification ::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4; 
}
/* scrollbar */

.ant-popover.onlynotification .ant-popover-title {
  font-size: 25px;
  line-height: 24px;
  padding: 0px 10px;
  color: #1c1e21;
  font-weight: 500;
  border-bottom: 0px;
}
.ant-popover.onlynotification .ant-popover-content .ant-popover-inner-content {
  padding: 0px;
  color: #1c1e21;
}
.ant-popover.onlynotification .ant-popover-title .seeallnotification {
  text-align: right;
  margin-bottom: 10px;
}
.ant-popover.onlynotification .ant-popover-title a {
  font-size: 16px;
  line-height: 14px;
  font-weight: 400;
  color: #1876f2;
  display: inline-block;
  vertical-align: middle;
}
.ant-popover.onlynotification .ant-popover-title a:hover,
.ant-popover.onlynotification button.mark-read:hover{
  border-bottom: 1px solid #000;
}
.ant-popover.onlynotification button.mark-read {
  border: none;
  font-size: 16px;
  line-height: 14px;
  font-weight: 400;
  color: #1876f2;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  height: auto;
}
.no-notification {
  font-size: 1.1rem;
  color: #1876f2;
  font-weight: 400;
  text-align: center;
}
.no-notification .item-row {
  text-align: left;
}
.ant-popover-inner-content .item-row,
.bell-notification .item-row {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  padding: 10px 30px 10px 10px;
  border-radius: 6px;
}
.ant-popover-inner-content .item-row:last-child,
.bell-notification .item-row:last-child {
  margin-bottom: 0px;
}
.ant-popover-inner-content .item-row.new a::after,
.bell-notification .item-row.new a::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0px;
  bottom: 0px;
  width: 12px;
  height: 12px;
  margin: auto;
  border-radius: 36px;
  background: #1876f2;
}
.bell-notification .item-row {
  padding: 10px 40px 10px 10px;
  border-radius: 6px;
}
.bell-notification .item-row:hover,
.ant-popover-inner-content .item-row:hover {
  background: #f2f2f2;
}
.bell-notification .item-row a {
  font-size: 1rem;
  line-height: 1.3rem;
  color: #1c1e21;
}
.ant-popover-inner-content .item-row a {
  color: #1c1e21;
  font-size: 1rem;
  line-height: 1.3rem;
}
.ant-popover-inner-content .item-row a > div,
.bell-notification .item-row a > div {
  display: flex;
  align-items: center;
}
.ant-popover-inner-content .picbox,
.bell-notification .item-row .picbox {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
}

.ant-popover-inner-content .picbox img,
.bell-notification .item-row .picbox img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 360px;
}
.ant-popover-inner-content .item-row .timelog,
.bell-notification .item-row .timelog {
  color: #1876f2;
  font-weight: bold;
}
.ant-popover-inner-content .picbox::after,
.bell-notification .item-row .picbox::after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 26px;
  height: 26px;
  margin: auto;
  border-radius: 36px;
}
.ant-popover-inner-content .picbox.comment::after,
.bell-notification .item-row .picbox.comment::after {
  background: #1876f2 url(../../assets/images/notification-comment.svg) no-repeat 50% 50%;
  background-size: 14px;
}
.ant-popover-inner-content .picbox.like::after,
.bell-notification .item-row .picbox.like::after {
  background: #1876f2 url(../../assets/images/notification-dislike.svg) no-repeat 50% 50%;
  background-size: 14px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .ant-popover.onlynotification {
    width: 90%;
    max-width: 360px;
    top: 62px !important;
    right: 5%;
  }
  .ant-popover.onlynotification .ant-popover-content {
    max-height: calc(100vh - 90px);
  }
  .bell-notification {
    padding: 0px 20px;
    min-height: 150px;
  }
  header .bell-notification {
    margin-right: 10px;
    padding: 0px;
    min-height: 40px;
  }
  .bell-notification h2,
  .ant-popover.onlynotification .ant-popover-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .bell-notification .item-row a,
  .ant-popover-inner-content .item-row a {
    font-size: 0.85rem;
    line-height: 1.2rem;
  }
  .ant-popover-inner-content .picbox,
  .bell-notification .item-row .picbox,
  .ant-popover-inner-content .picbox img, 
  .bell-notification .item-row .picbox img {
    width: 45px;
    height: 45px;
  }
  .ant-popover-inner-content .picbox::after,
  .bell-notification .item-row .picbox::after {
    bottom: -5px;
  }
  
}