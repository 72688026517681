.allsponsorspage.maincontent {
    padding-bottom: 16px;background: linear-gradient(93deg, white 55%, #cacaca); 
 }
.allsponsorslist {
    padding-top: 0px;
}
.allsponsorslist.viewpodcastslist {
    padding: 0px;
    border-bottom: 0px;
}
.allsponsorslist .viewsponsor {
    max-width: 1126px;
}
.allsponsorslist .viewsponsor .viewcontent {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.allsponsorslist .viewsponsor .viewcontent .viewrow {
    display: inline-block !important;
    max-height: none;
    border-radius: 0;
    overflow: hidden;
    aspect-ratio: auto;
    margin: 1.6% .8%;
    max-width: 31.7%;
    width: 31.33%;
}
.allsponsorslist .viewsponsor .viewcontent .viewrow .sponsorcontent {
    min-height: 210px;
}
/* .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox {
    width: 108px;
    float: left;
} */
/* .allsponsorslist .viewsponsor .viewcontent .viewrow .content {
    width: 125px;
    float: left;
} */
.allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox .lazy-load-image-background{
    display: block!important;
}
.allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox img {
   
    display: flex;
    width: 100%;
    
    overflow: hidden;
    max-height: 220px;
    padding-top: 10px;
    background: linear-gradient(270deg,#0031c3,#5fb2d3);
}
.allsponsorslist .viewsponsor .viewcontent .viewrow .description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.allsponsorslist .viewsponsor .sharelinkarea {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-top: 12px;
    justify-content: space-between;
}
.allsponsorslist .viewsponsor .sharelinkarea .tags {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.allsponsorslist .viewsponsor .viewcontent .viewrow .sharelink ul {
    position: relative;
}

.banner-sponsor .banner-sponsor-content { max-width: 1142px; position: absolute; margin: auto; left: 0; right: 0; bottom: 6em; color: #fff; } 
.banner-sponsor { position: relative; } 
.banner-sponsor .banner-sponsor-content h2.banner-sponsor-title { text-transform: uppercase; color: #fff; font-family: 'Roboto'; font-size: 72px; line-height: normal; margin: 0; font-weight: 700; } 
.banner-sponsor .banner-sponsor-content .banner-sponsor-sub { font-weight: 300; font-size: 26px; line-height: 1.2em; } 

.allsponsorspage .educationlistfilterslider .viewcontent .viewrow a:before { content: ''; width: 1px; height: 71%; position: absolute; right: 0; background: #cbcbcb; margin: auto; top: 0; bottom: 0; display: block; } 
.allsponsorspage .educationlistfilterslider .viewcontent .slick-slide:last-child .viewrow  a:before { display: none; }

.allsponsorspage .educationlistfilterslider {
    margin-top: -50px;
    max-width: 1142px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 4px 18px 0px #5c5a5a;
    padding: 0;
}
.allsponsorspage .educationlistfilterslider .viewcontent .viewrow a{
    border: 2px solid #cbcbcb;
    border-top: 0;
    border-bottom: 0;
    height: 100%;
    border-radius: 0;
    border-width: 0 0px 0 0;
    min-height: 100px;
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    color: #003c62;
    padding: 0;    background: #fff
}

.sponsorsearch { max-width: 1142px; margin: auto; padding: 3em 10px; display: flex; justify-content: center; flex-wrap: wrap; } 
.sponsorsearch .form-title { width: 100%; max-width: 650px; font-size: 28px; line-height: normal; text-transform: uppercase; color: #818181; } 
.sponsorsearch .formbox { width: 100%; max-width: 650px;position: relative; }
.sponsorsearch .formbox .item{background: #fff;width: 100%; display: flex; height: 45px; border: 2px solid #003b60; border-radius: 9px; margin: 7px 0 0;}
.sponsorsearch .formbox .item label { width: 130px; background: #003b60; color: #fff; display: flex; justify-content: center; align-items: center; font-size: 22px; font-weight: 300; text-transform: uppercase; border-right: 2px solid #003b60; border-radius: 5px 0 0 5px; }
.sponsorsearch .formbox .item select { width: calc(100% - 140px); border: 0; padding-left: 10px; border-radius: 10px; outline: none; margin-right: 10px; background-color: #fff;}
.sponsorsearch .formbox .item input.formtext { border: 0; width: calc(100% - 130px); padding: 0 25px 0 15px; outline: none; border-radius: 10px; } 
.sponsorsearch .formbox .item:last-child label { background: #006ead; } 
.sponsorsearch input.form-submit { position: absolute; right: 5px; bottom: 8px; width: 25px; text-indent: -99999px; overflow: hidden; border: 0; background: url(../../assets/images/search-icon-blue.svg) no-repeat center center;cursor: pointer; }
.item.city-wrapper .ant-select {
    width: calc(100% - 200px);
    padding: 0 10px;
}
.item.city-wrapper .ant-select .ant-select-selector {
    height: auto!important;
    border: none!important;
    &:hover {
        border: none!important;
    }
}
.allsponsorspage .educationlistfilterslider .viewcontent .viewrow a.active{
    color: #fff;
    background: #0072BB;
}

.allsponsorspage .educationlistfilterslider .viewcontent {
    max-width: 1142px;
    padding: 0px 0;
}
.allsponsorspage .viewvideocourse > h2{
    margin: 24px auto 0px auto;
}
.allsponsorspage .ant-carousel .slick-list * { width: 100%; padding: 0 !important; margin: 0 !important; gap: 0; }

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .allsponsorslist {
        padding: 0px 0;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;max-width: 100%;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox
    .allsponsorslist .viewsponsor .viewcontent .viewrow .content {
        width: 100%;
        height: auto;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox img {
        width: 100%;
        height: auto;
    }

    .banner-sponsor .banner-sponsor-content h2.banner-sponsor-title  { font-size: 40px; } 
    .allsponsorspage.maincontent .ant-carousel .slick-prev { left: 10px; z-index: 9; } .allsponsorspage.maincontent .ant-carousel .slick-next { right: 10px; }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .allsponsorslist {
        padding: 0;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;max-width: 100%;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox
    .allsponsorslist .viewsponsor .viewcontent .viewrow .content {
        width: 100%;
        height: auto;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox img {
        width: 100%;
        height: auto;
    }

    .banner-sponsor .banner-sponsor-content h2.banner-sponsor-title  { font-size: 40px; } 
    .allsponsorspage.maincontent .ant-carousel .slick-prev { left: 10px; z-index: 9; } .allsponsorspage.maincontent .ant-carousel .slick-next { right: 10px; }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {

    .allsponsorslist .viewsponsor .viewcontent .viewrow {
        width: 49%;
        margin: 0px 2% 2% 0px !important;
        max-width: 49%;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px !important;
    }

    .banner-sponsor .banner-sponsor-content h2.banner-sponsor-title  { font-size: 52px; }
    .allsponsorspage.maincontent .ant-carousel .slick-prev { left: 10px; z-index: 9; } .allsponsorspage.maincontent .ant-carousel .slick-next { right: 10px; }
}

@media only screen and (min-width: 980px) and (max-width: 1280px) {
    .allsponsorslist .viewsponsor .viewcontent .viewrow {
        width: 31%;
        margin: 0px 2% 2% 0px;
    }
    .allsponsorslist .viewsponsor .viewcontent .viewrow:nth-child(3n) {
        margin-right: 0px;
    }
    .viewsponsor .viewcontent .viewrow .description {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
    .allsponsorspage.maincontent .ant-carousel .slick-prev { left: 10px; z-index: 9; } .allsponsorspage.maincontent .ant-carousel .slick-next { right: 10px; }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .allsponsorslist {
        padding: 0px 0;
    }

    .allsponsorslist .viewsponsor .viewcontent .viewrow .imagebox {
        height: auto;
    }

    .allsponsorspage .educationlistfilterslider .slick-slide:before { content: ''; width: 1px; height: 71%; position: absolute; right: 0; background: #cbcbcb; margin: auto; top: 0; bottom: 0; display: block; z-index: 9; } 
    .allsponsorspage .educationlistfilterslider .viewcontent .viewrow a:before { display: none; } 
    .allsponsorspage .educationlistfilterslider .viewcontent .viewrow a { padding: 0 20px; width: 100%; margin: 0 13px 0 0 !important; } 
    .educationlistfilterslider .viewcontent .ant-carousel .slick-slide * { width: 100%; margin: 0; }
}
@media only screen and (max-width: 1280px) {
.banner-sponsor .banner-sponsor-content{padding: 0 10px;}
.allsponsorslist .viewsponsor {
    padding: 0 10px;
}
}

@media screen and (max-width: 767px) {
    .allsponsorspage  .banner-sponsor  { min-height: 320px; overflow: hidden; } 
    .allsponsorspage  .banner-sponsor  img { position: absolute; object-fit: cover; top: 0; left: 0; max-width: none; min-height: 100%; width: auto; } 
    .banner-sponsor .banner-sponsor-content { z-index: 3; text-align: center; } 
    .banner-sponsor .banner-sponsor-content .banner-sponsor-sub { font-size: 20px; }
    .sponsorsearch .form-title { font-size: 24px; }
    .allsponsorslist .viewsponsor .viewcontent { max-width: 450px; }
}

.banner-sponsor img {
    width: 100%;
}