.messengerpage {
  padding-bottom: 0;
}
/* scrollbar */
.messengerpage ::-webkit-scrollbar {
  width: 6px;
}

.messengerpage  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
.messengerpage ::-webkit-scrollbar-thumb {
  background: #8b8888; 
  border-radius: 10px;
}
.messengerpage ::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
}
.messenger {
    height: calc(100vh - 70px);
    display: flex;
  }
  
  .chatMenu {
    flex: 2;
    background: #fbfbfb;
    padding: 0 5px;
    border-right: 1px solid #ccc;
  }
  .chatMenuWrapper{
    height: 100%;
    overflow: auto;
  }
  .chatMenuWrapper h2 {
    padding: 20px 10px;
    margin: 0;
    color: #000;
}
  .chatMenuInput {
    width: 90%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid gray;
  }
  
  .chatBox {
    flex: 6;
   }
  
  .chatBoxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .chatBoxTop {
    height: 100%;
    overflow: auto;
    padding: 20px;
    margin-right: 10px;
  }
  
  .chatBoxBottom {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .chatMessageInput {
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    border-radius: 50px;
    background: #f0f2f5;
    outline: none;
    border: none;
  }
 
  .chatSubmitButton {
    height: 40px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
  }
    
  .chatBoxWrapper{
    height: 100%;
  }
  
  .noConversationText {
    position: absolute;
    top: 50%;
    font-size: 30px;
    color: rgb(183 171 171);
    cursor: default;
    line-height: 1;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .chatOnline {
    flex: 2;
    background: #fbfbfb;
    padding: 0 5px;
    border-right: 1px solid #ccc;
  }
  .chatOnlineWrapper{
    height: 100%;
    overflow: auto;
  }
  .chatOnlineWrapper h2 {
    padding: 20px 10px;
    margin: 0;
    color: #000;
}
  
  @media screen and (max-width: 768px) {
    .chatMenu {
      flex: 1;
      max-width: 90px;
    }
  
    .chatMenuInput {
      display: none;
    }
  
    .chatBox{
      flex: 10;
    }
    .messenger {
      height: calc(100vh - 150px);
    }
    .chatOnline{
      flex: 1px;
    }
    .messageText a {
      word-break: break-all;
    }
    .chatMenuWrapper h2 {
      font-size: 0;
      width: 50px;
      height: 50px;
    }
  }