.coursedetialspage {
    background: #2c3e50;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,  #2c3e50, #3498db);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #2c3e50, #3498db); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
}
/* .coursedetialspage .breadcrumb ul, .coursedetialspage .breadcrumbwrapper {
    background: #080808;
} */
.coursedetialspage .breadcrumb ul {
    padding: 24px 0px 31px 0px;
    justify-content: center;
}
.coursedetialspage .breadcrumb ul li,
.coursedetialspage .breadcrumb ul li a {
    color: #F2F2F2;
}
.coursedetialspage .breadcrumb ul>li+li:before {
    color: #F2F2F2;
}
.coursedetailsview {
    max-width: 1050px;
    margin: auto;
    padding-bottom: 142px;
}
.coursevideosliderview,
.coursevideosliderview .viewcontent {
    overflow: hidden;
    width: 100%;
}
.coursevideosliderview .viewcontent .coursevideo {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    position: relative;
}
.coursevideosliderview .viewcontent .coursevideo iframe,
.coursevideosliderview .viewcontent .coursevideo object,
.coursevideosliderview .viewcontent .coursevideo embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.course-detail-wrapper {
    max-width: 900px;
    margin: auto;
}
.courseintroductionwrapper {
    max-width: 940px;
    padding: 0px 20px;
    margin: auto auto 20px;
}
.courseintroduction h2.title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    margin: auto;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
}
.courseintroduction .description {
    font-size: 17px;
    line-height: 28px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    padding: 0 0px 16px;
    text-align: center;
}

.courseintroduction .category label {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    font-weight: bold;
}
.courseintroduction .category span {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #F2F2F2;
}
/* .coursecontent .viewcontent-accordion .ant-collapse-borderless {
    background: transparent;
} */
.coursevideopagerlist .viewcontent {
    padding: 0px;
}
.coursevideopagerlist .viewcontent .slick-list .slick-track {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.coursevideopagerlist .viewcontent .slick-list .slick-track .slick-slide {
    width: 116px !important;
    flex: 1 1 116px;
    flex-grow: 0;
    margin: 8px 8px;
}
.coursevideopagerlist .viewcontent .ant-carousel .slick-list .slick-slide>div {
    margin: 0px 0px;
}
.coursevideopagerlist .viewcontent .viewrow {
    width: 116px;
    font-size: 13px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.02em;
    font-family: 'Roboto', sans-serif;
    color: #F2F2F2;
    padding-top: 4px;
    cursor: pointer;
    margin: 8px 4px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

/* .coursevideopagerlist .viewcontent .viewrow[title]:hover:after {
    content: attr(title);
    padding: 4px 8px;
    color: #333;
    position: absolute;
    left: 0; 
    top: 100%;
    white-space: nowrap; 
    z-index: 20px;
    border-radius: 5px; 
    box-shadow: 0px 0px 4px #222;  
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);  
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #eeeeee),color-stop(1, #cccccc));
    background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);  
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);  
    background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);  
    background-image: -o-linear-gradient(top, #eeeeee, #cccccc);  
}
 */
/* .coursevideopagerlist .viewcontent .ant-carousel .slick-list .slick-slide {
    pointer-events:all;
} */
.coursevideopagerlist .viewcontent .viewrow::after,
.coursevideopagerlist .viewcontent .viewrow::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    height: 6px;
    background: #fff;
    display: block;
    right: 0;
    border-radius: 360px;
}
.coursevideopagerlist .viewcontent .viewrow::before {
    z-index: 1;
}
.coursevideopagerlist .viewcontent .viewrow.active::before{
    /* animation:slanim 11s 1; */
    background:#0072BB;
    color: #ffffff;
}
.coursevideopagerlist .viewcontent .viewrow.completed::before{
    /* animation:slanim 11s 1; */
    background:#089c31;
}
.progress-container ul {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 600;
    font-size: 12px;
    margin: auto;
    padding-bottom: 32px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
.progress-container ul li {
    display: block!important;
    float: left;
    padding-right: 8px;
    width: 10%!important;
    box-sizing: border-box;
    color: #858d94!important;
    text-align: center!important;
}
.progress-container ul li.active{
    color: #fff!important;
}
@keyframes slanim{
    0%{
        right:100%
    }
    100%{
        right:0%
    }
}

@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};
/* 
.coursevideosliderview {
    position: relative;
}
.coursevideosliderview .viewcontent .viewrow {
    display: none;
}
.coursevideosliderview .viewcontent #row1.viewrow {
    display: block;
} */

.coursevideosliderview .viewcontent .viewrow .headings {
    font-style: normal;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    flex: 1;
}

.coursevideosliderview .ant-carousel .slick-arrow::after {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all .5s ease;
    border-bottom: 1px solid #0072BB;
    border-right: 1px solid #0072BB;
}

.coursevideosliderview .ant-carousel .slick-arrow::before {
    border-bottom: 1px solid #0072BB;
    border-right: 1px solid #0072BB;
}

.coursevideosliderview .ant-carousel .slick-arrow.slick-next:after {
    right: -4px;
    transform: rotate(-45deg);
}
.coursevideosliderview .ant-carousel .slick-arrow.slick-prev:after {
    transform: rotate(135deg);
    left: 10px;
}
.coursevideosliderview .ant-carousel .slick-arrow.slick-prev:before {
    left: 0px;
}
.coursevideosliderview .ant-carousel .slick-arrow.slick-next:before {
    right: 5px;
}
.coursevideosliderview .ant-carousel .slick-prev {
    left: -64px;
}
.coursevideosliderview .ant-carousel .slick-next {
    right: -64px;
}
.coursevideosliderview .ant-carousel .slick-arrow,
.ant-carousel .slick-prev:hover, 
.ant-carousel .slick-next:hover, 
.ant-carousel .slick-prev:focus, 
.ant-carousel .slick-next:focus {
    background: #fff;
}

/* .coursevideosliderview .viewcontent .coursevideo {
    position: relative;
    object-fit: cover;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
    width: 100%;
    max-width: 992px;
    height: auto;
} */
.coursevideosliderview .viewcontent .coursevideo span {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.75) url(../../../assets/images/play.svg) no-repeat 50% 50%;
    margin: auto;
    left: 0px;
    right: 0px;
    font-size: 0px;
    cursor: pointer;
}
.coursevideosliderview .viewcontent .coursevideo img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.coursevideosliderview .viewcontent .viewrow .courseshare {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coursevideosliderview .viewcontent .viewrow .courseshare ul {
    display: flex;
    align-items: center;
}

.coursevideosliderview .viewcontent .viewrow .courseshare ul, 
.coursevideosliderview .viewcontent .viewrow .courseshare ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
/* .coursevideosliderview .viewcontent .viewrow .courseshare ul li a,
.coursevideosliderview .viewcontent .viewrow .courseshare ul li .share-icon {
    width: 24px;
    height: 24px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #FBFBFB;
} */
.coursevideosliderview .viewcontent .viewrow .courseshare ul li.bookmark a {
    margin-right: 16px;
    background-image: url(../../../assets/images/bookmark.svg);
    background-size: 12px;
}
/* .coursevideosliderview .viewcontent .viewrow .courseshare ul li.share .share-icon {
    background-image: url(../../../assets/images/share.svg);
    background-size: 14px;
} */
.coursevideosliderview .viewcontent .viewrow .courseshare ul li.share .share-icon:hover{
    color: #fff!important;
}
.coursevideosliderview .viewcontent .viewrow .courseshare .makenote a {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    font-family: 'Roboto', sans-serif;
    text-decoration-line: underline;
    color: #0771EE;
}
.coursecontent {
    overflow: hidden;
    margin-top: 25px;
}
.courseintroduction .description p {
    font-size: 17px;
}
.coursecontent .description p {
    color: #e2e2e2;
    font-size: 17px;
    line-height: 28px;
}
.coursecontent .description a {
    color: #fff;
    text-decoration: underline;
}
.coursecontent .description ul {
    margin-bottom: 20px;
}
.coursecontent .description ul li {
    color: #e2e2e2;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 15px;
}
.coursecontent .blocktitle {
    font-size: 30px;
    line-height: 30px;
    text-align: justify;
    font-family: "Roboto",sans-serif;
    letter-spacing: .02em;
    color: #f2f2f2;
    margin: 0px 0px 25px;
    border-bottom: 1px solid #fff;
    display: inline-block;
    padding-bottom: 4px;
    padding-right: 4px;
    font-weight: bold;
}

.coursecontent .viewcontentlist {
    padding: 0px;
    border-radius: 0px;
    background: none;
    box-shadow: none;
}
.social-share-wrapper button.ant-modal-close {
    top: 0px;
    right: 0;
}
.social-share-wrapper .social-share button span {
    text-align: center;
    display: block;
    line-height: 1;
    margin: 0 10px;
    margin-bottom: 10px;
}
.social-share-wrapper .social-share .share-title {
    margin-top: -18px;
    font-weight: bold;
}
.social-share-wrapper .copy-div {
    margin-top: 20px;
    position: relative;
    background: #eeee;
    overflow: hidden;
}

.social-share-wrapper .copy-text {
    padding: 20px;
    width: calc(100% - 100px);
    float: left;
    padding-right: 0;
}

.social-share-wrapper .copy-button {
    float: right;
    padding: 20px;
}

.social-share-wrapper .copy-button  button {
    min-width: unset!important;
    background: transparent!important;
    color: #0072BB!important;
    box-shadow: none!important;
    padding: 0!important;
    margin: 0!important;
    height: auto!important;
}

.social-share-wrapper .copy-div:hover .copy-button {
    opacity: 1;
}
/* .coursecontent .viewcontent .ant-collapse-item {
    padding: 20px 16px;
    background: rgba(150,150,150,0.5);
    border-radius: 5px;
    margin-bottom: 2px;
    border: 0px;
}
.coursecontent .viewcontent .ant-collapse-item.ant-collapse-item-active {
    background: rgba(150,150,150,0.7);
}
.coursecontent .viewcontent .ant-collapse-item:last-child {
    margin-bottom: 0px;
}
.coursecontent .viewcontent .ant-collapse-item .ant-collapse-header {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    padding: 0px 28px 0px 0px;
}
.coursecontent .viewcontent .ant-collapse-item .ant-collapse-header span.ant-collapse-arrow {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
    background: url(../../../assets/images/whitearrow.svg) no-repeat 50% 50%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    transform: none;
}
.coursecontent .viewcontent .ant-collapse-item.ant-collapse-item-active .ant-collapse-header span.ant-collapse-arrow {
    transform: rotate(180deg);
    background: url(../../../assets/images/whitearrow.svg) no-repeat 50% 50%;
}
.coursecontent .viewcontent .ant-collapse-item span svg {
    display: none !important;
}
.coursecontent .viewcontent .ant-collapse-item .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
}

.coursecontent .viewcontent .ant-collapse-item .contentbody .description {
    padding: 15px 0px;
    font-size: 15px;
    line-height: 21px;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02em;
    color: #F2F2F2;
}

.coursecontent .viewcontent .ant-collapse-item .contentbody .totaltimes {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    width: 100%;
    text-align: right;
}

.coursecontent .viewcontent .ant-collapse-item .ant-collapse-extra {
    display: block;
    float: unset;
    margin-top: 10px;
}
.coursecontent .viewcontent .ant-collapse-item.ant-collapse-item-active .ant-collapse-extra {
    display: none;
} */
ul.video-course-item li {
    padding: 20px 16px;
    background: rgba(150,150,150,0.5);
    border-radius: 5px;
    margin-bottom: 2px;
    border: 0px;
    list-style: none;
    font-size: 18px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.02em;
    color: #F2F2F2;
    cursor: pointer;
}
ul.video-course-item li:hover {
    color: #000000;
}
ul.video-course-item {
    padding: 0;
}
.navButtons button {
    min-width: 180px;
    height: 40px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
    margin: 0 20px;
    text-transform: uppercase;
    padding: 0 20px;
}
.navButtons {
    text-align: center;
    margin-top: 35px;
}
.taglist {
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
}
.taglist span {
    color: #F2F2F2;
    font-size: 18px;
    line-height: 22px;
    margin-right: 16px;
}

.taglist ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
}

.taglist ul li {
    display: inline-block;
    margin: 10px;
}

.taglist ul li a {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.02em;
    padding: 6px 13px;
    color: #F2F2F2;
    background: #2B2B2B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    text-transform: capitalize;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .coursedetialspage .breadcrumb ul {
        padding: 15px 0px 15px 0px;
        flex-wrap: wrap;
    }
    .coursedetialspage .breadcrumb ul li,
    .coursedetialspage .breadcrumb ul li a {
        font-size: 15px;
        line-height: 20px;
    }
    .coursedetailsview {
        max-width: 100%;
        padding-bottom: 50px;
    }
    .progress-container ul {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        flex-wrap: wrap;
    }
    .coursevideopagerlist .viewcontent .viewrow {
        min-width: 75px;
        margin: 2%;
        font-size: 12px;
        line-height: 20px;
    }
    .coursevideopagerlist .viewcontent .viewrow::after, 
    .coursevideopagerlist .viewcontent .viewrow::before {
        height: 5px;
    }
    .coursevideosliderview .viewcontent .coursevideo>div {
        height: auto !important;
    }
    .coursedetailsview .react-player__preview {
        height: auto !important;
        padding-top: 22%;
        padding-bottom: 22%;
    }
    .coursevideosliderview .viewcontent .coursevideo,
    .coursevideosliderview .viewcontent .coursevideo img {
        width: 100%;
    }
    .coursedetailsview .coursevideosliderview {
        padding: 0px 20px;
    }
    .courseintroduction h2.title,
    .coursecontent .blocktitle {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 15px;
        text-align: left;
    }
    .courseintroduction .description,
    .coursecontent .description p,
    .coursecontent .description ul li,
    .courseintroduction .category label,
    .courseintroduction .category span {
        font-size: 15px;
    }
    .coursevideosliderview .ant-carousel .slick-prev {
        left: -35px;
    }
    .coursevideosliderview .ant-carousel .slick-next {
        right: -35px;
    }
    .coursecontent {
        margin-top: 20px;
    }
    .taglist {
        margin-top: 30px;
        padding: 0px 45px;
    }
    .navButtons button {
        width: auto;
        min-width: 120px;
        font-size: 15px;
        line-height: 16px;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto 10px;
    }
    .coursecontent .viewcontent .ant-collapse-item .ant-collapse-header {
        font-size: 16px;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    
    .breadcrumbwrapper .breadcrumb {
        padding: 0px 20px;
    }
    .coursedetailsview {
        padding: 0px 20px 80px;
    }
    .coursevideosliderview .viewcontent .coursevideo img {
        width: 100%;
        height: auto;
    }
    .courseintroduction h2.title {
        font-size: 30px;
        line-height: 35px;
    }
    .courseintroduction .description {
        font-size: 16px;
        line-height: 22px;
        padding: 0 0px 14px;
    }
    .courseintroduction .category label,
    .courseintroduction .category span {
        font-size: 15px;
    }
    .coursecontent .description p,
    .coursecontent .description ul li {
        font-size: 16px;
        line-height: 22px;
    }
    
}