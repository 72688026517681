.educationviewblock .viewcontent .viewrow .videoinformation {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
}

.educationviewblock .viewcontent .viewrow .videoinformation .videobox,
.educationviewblock .viewcontent .viewrow .videoinformation .videobox img {
    width: 136px;
    height: 60px;
    position: relative;
    object-fit: cover;
    border-radius: 4px;
}

.educationviewblock .viewcontent .viewrow .videoinformation .videobox  {
    position: relative;
    padding-bottom: 15%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
  
.educationviewblock .viewcontent .viewrow .videoinformation .videobox iframe,
.educationviewblock .viewcontent .viewrow .videoinformation .videobox object,
.educationviewblock .viewcontent .viewrow .videoinformation .videobox embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.educationviewblock .viewcontent .viewrow .videoinformation .videobox span {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.75) url(../../../assets/images/play.svg) no-repeat 50% 50%;
    margin: auto;
    left: 0px;
    right: 0px;
    font-size: 0px;
    cursor: pointer;
    z-index: 1;
}

.educationviewblock .viewcontent .viewrow .videoinformation .createdby {
    flex: 1;
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
}

.educationviewblock .viewcontent .viewrow .title h2 {
    margin: 0px 0px 9px;
}

.educationviewblock .viewcontent .viewrow .videoinformation .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2B2B39;
}

.educationviewblock .viewcontent .viewrow .videoinformation .createdby .created {
    margin-bottom: 10px;
}

.educationviewblock .viewcontent .viewrow .description {
    font-size: 16px;
    line-height: 26px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .educationviewblock .viewcontent .viewrow .videoinformation {
       flex-wrap: wrap;
       margin-bottom: 15px;
    }
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox, 
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox img {
        width: 100%;
        height: 154px;
    }
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox  {
        padding-bottom: 56.25%;
    }
    .educationviewblock .viewcontent .viewrow .description {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        text-align: left;

    }
    .educationviewblock .viewcontent .viewrow .videoinformation .createdby {
        margin-top: 15px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .educationviewblock .viewcontent .viewrow .videoinformation .createdby .created {
        margin-bottom: 0px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox, 
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox img {
        width: 100%;
        height: 154px;
    }
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox  {
        padding-bottom: 56.25%;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1279px) {

    .educationviewblock .viewcontent .viewrow .videoinformation .videobox img {
        width: 100%;
    }
    .educationviewblock .viewcontent .viewrow .videoinformation .videobox  {
        padding-bottom: 18%;
    }
}