.forums .forumssearchbox {
    max-width: 706px;
    padding: 14px 0px;
}
.sidebarleft1.forums .forumssearchbox {
    width: 76%;
    max-width: 1095px;
}

.forums.maincontent.sidebarleft .maindiv .midcontent .forumssearchbox {
    width: 73%;
    max-width: 1051px;
}
.forumssearchbox .searcharea {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 40px;
}

.forumssearchbox .searcharea button{
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
    padding: 0;
    margin: 0;
    box-shadow: none;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .forums.maincontent.sidebarleft .maindiv .midcontent .forumssearchbox {
        width: 100%;
        padding: 20px;
        position: relative;
    }
    .forumssearchbox .searcharea {
        height: 40px;
        font-size: 15px;
        line-height: 16px;
    }
    .forumssearchbox .userpic {
        margin-right: 10px;
    }
    .forumssearchbox .userpic,
    .forumssearchbox .userpic img {
        width: 40px;
        height: 40px;
    }
    .forumssearchbox .searcharea button {
        font-size: 14px;
    }
    .forums .forumssearchbox {
        padding: 20px;
        gap: 0px;
    }
    .sidebarleft1.forums.maincontent .maindiv .midcontent {
        padding: 0px 20px;
    }
    .sidebarleft1.forums.maincontent .maindiv .midcontent .forumspostscontent {
        padding: 0px 0px;
    }
    .sidebarleft1.forums .forumssearchbox {
        width: 100%;
        padding: 20px 0px;
        max-width: 100%;
    }
    .forums .forumssearchbox .userpic {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .forums.maincontent.sidebarleft .maindiv .midcontent .forumssearchbox {
        width: 71%;
    }
    .forumssearchbox .searcharea {
        height: 40px;
        font-size: 16px;
        line-height: 16px;
    }
    .forumssearchbox .searcharea button {
        font-size: 16px;
        line-height: 16px;
    }
    .forums .forumssearchbox {
        gap: 0px;
        padding-bottom: 24px;
    }
    .forums .forumssearchbox .userpic {
        margin-right: 15px;
    }
}