.message {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
  
  .messageTop{
      display: flex;
  }
.messageTop>div{
  position: relative;
}
.messageTop .pro-icon{
  width: 14px;
  height: 14px;
  background-size: 14px;
  bottom: 0;
  right: 10px;
  padding: 5px;
}
  .messageTop .lazy-load-image-background{
    width: 32px!important;
    height: 32px!important;
    margin-right: 10px;
  }
  
  .messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.messageText{
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #d5e8f4;
    color: #000;
    max-width: 650px;
    margin-bottom: 0;
    font-size: 16px;
}
.messageText p {
  margin-bottom: 0;
}
.message.own .messageText a {
  color: #fff;
  text-decoration: underline;
}

.messageBottom{
    font-size: 12px;
}

.message.own{
    align-items: flex-end;
}

.message.own .messageText{
  background-color: #0a82f9;
  color: #fff;
}