 .google {
    width: 46%;
    float: left;
    margin-right: 7%;
}
.google a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #11072F;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 4px;
    padding: 13px 12px 13px 33px;
    width: 180px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}



.google a {
    margin-bottom: 24px;
    background: url(../../assets/images/googleplus.svg) no-repeat 20px 50%;
    background-size: 16px;
  }