.searchbox {
    flex: 1;
}
.searchbox form,
.searchbox .formbox{
    display: flex;
    margin: 0px 24px 0px 0px;
    width: 100%;
}
.forums .searchbox form {
    margin: 0px 0px 56px 56px;
}
.anonymousforum .searchbox {
    width: 70%;
    position: fixed;
    padding: 14px 0px;
    background: #fff;
}
.anonymousforum .searchbox form {
    max-width: 730px;
    margin: 0px;
}
.searchbox .search-filter {
    flex: 1;
}

.ant-input {
    font-variant:inherit;
    list-style: none;
    font-feature-settings:inherit;
    color: #909090;
}

.ant-input::placeholder {
    color: #909090;
    opacity: 1;
}
.searchbox .formtext {
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 30px 0px 0px 30px;
    height: 60px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    padding: 0px 24px;
    outline: none;
    box-shadow: none;
    width: 100%;
    color: #909090;
    opacity: 1;
}
.searchbox .form-action .form-submit {
    background: #0072BB url(../../../assets/images/search-icon.svg) no-repeat 50% 50%;
    border-radius: 0px 30px 30px 0px;
    border: 0px;
    color: #fff;
    height: 60px;
    width: 70px;
    text-align: center;
    cursor: pointer;
    font-size: 0px;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .searchbox {
        flex: 100%;
        padding: 20px 0px;
    }
    .searchbox form {
        margin: 0px;
    }
    .forums .searchbox form {
        margin: 0px;
    }
    .anonymousforum .searchbox {
        width: 100%;
        padding: 20px 0px;
        position: relative;
    }
    .searchbox form .formtext {
        height: 50px;
        font-size: 13px;
    }
    .searchbox form .form-action .form-submit {
        height: 50px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .searchbox {
        flex: auto;
    }
    .searchbox form {
        margin: 0px 10px 0px 0px;
    }
    .searchbox form .formtext {
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .searchbox form .form-action .form-submit {
        width: 50px;
        height: 40px;
    }
    .forums .searchbox form {
        margin: 0px 0px 24px 14px;
    }
    .anonymousforum .searchbox {
        width: 71%;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1279px) {
    
    .forums .searchbox form {
        margin: 0px 0px 24px 14px;
    }
    
}