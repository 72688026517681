.Page {
  margin-top: 120px;
}
.PageLanding {
  
}
.MainLayout {
  min-height: calc(100vh - 490px);
}

.HeaderImage {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url('../../assets/header.jpg');
  background-size: 100%;
  background-position-y: 50%;
  height: 222px;
  width: 100%;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .Page {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1469px) {
  .Page {
    margin-top: 120px;
  }
}