.sponsordetailwrapper {
    display: flex;position: relative;
    background: linear-gradient(93deg, white 55%, #cacaca); 
}
.sponsordetailwrapper .backbutton {
    position: absolute;
    z-index: 9;
    top: 15px;
    left: 4%;
}
.sponsordetailpage {
    flex: 1;
    padding: 0px 0px 56px 0px;overflow: hidden;
}
.sponsordetail {
    max-width: 1142px;
    margin: auto;
    position: relative;
    z-index: 3;    padding: 0 10px;
}
.downloadbutton {
    width: 100%;
    padding: 24px 0px;
    text-align: right;
    border-bottom: 1px solid #C5C5C5;
}

.downloadbutton a {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #0072BB;
}

.sponsordetailpage .banner-image{position: relative;}
.sponsordetailpage .banner-image .banner-content { max-width: 1142px; position: absolute; margin: auto; left: 0; right: 0; bottom: 2em; color: #fff;padding: 0 45% 0 50px; } 
.sponsordetailpage .banner-image .banner-content h1.title { text-transform: uppercase; color: #fff; font-family: 'Roboto'; font-size: 72px; line-height: 1em; margin: 0; font-weight: 700;position: relative; } 

.sponsordetailcontent { display: flex; flex-direction: row-reverse; padding: 3em 0 0; } 
.sponsordetailcontent .imagebox { flex: 0 45%; width: 45%; margin: -220px 0 0; display: flex; justify-content: center; padding: 0 0 0 20px; } 
.sponsordetailcontent .contentbox { flex: 0 55%; width: 55%; font-size: 16px; color: #000; padding: 0.3em 0 0;} 
.sponsordetailcontent .imagebox  * { width: 100%; } 
.sponsordetailcontent .imagebox img { width: auto; min-width: 75%; } 
.sponsordetailcontent .imagebox span.lazy-load-image-loaded { display: flex !important; justify-content: center; width: 100%; } 

.sponsordetailpage .banner-image .banner-content h1:before { width: 1px; height: 1px; position: absolute; left: -24px; top:16px; border-width: 20px; border-style: solid; border-color: transparent transparent transparent #009dd9; content:''; }

.sponsordetail:before {content: '';width: 100vw;height: 12px;background: #0074b9;position: absolute;left: -50vw;right: -50vw;margin: auto;z-index: -1;top: 12px;}


.contact-section { display: flex; max-width: 780px; padding: 4em 0px 1em; align-items: center; justify-content: left; } 
.contact-section .label { position: relative; font-size: 26px; text-transform: uppercase; color: #1f365b; width: 210px; padding: 0 40px 0 0; } 
.contact-section a { background: #000; color: #fff; text-transform: uppercase; width: 250px; text-align: center; display: flex; justify-content: center; align-items: center; border-radius: 10px; padding: 9px; font-size: 18px; font-weight: 400; } 
.contact-section .label:before, .contact-section .label:after { content: ''; width: 1px; height: 1px; position: absolute; right: 5px; border-width: 10px; border-style: solid; top: 0; bottom: 0; margin: auto; border-color: transparent transparent transparent #009dd9; } 
.contact-section .label:after { right: 17px; }

@media only screen and  (max-width: 940px) {
    .sponsordetailcontent { flex-direction: column; } 
    .sponsordetailcontent .imagebox { flex: 0 100%; width: 100%; margin: 0; padding: 0; } 
    .sponsordetail { max-width: 600px; } 
    .sponsordetailcontent .contentbox { flex: 0 100%; width: 100%; padding: 2em 0 0; text-align: center; } 
    .sponsordetailpage .banner-image .banner-content h1.title { font-size: 52px; } 
    .sponsordetailpage .banner-image .banner-content h1:before { top: 5px; } 
    .sponsordetailpage .banner-image .banner-content { bottom: 30px; padding: 0 0 0 50px; justify-content: center;
        display: flex; }
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
    
    .sponsordetailwrapper {
        flex-wrap: wrap;
    }

    .sponsordetailpage {
        padding: 0 0 56px 0;
    }
    .sponsordetail .sponsordetailcontent {
        flex-wrap: wrap;
    }
    .sponsordetail {
        max-width: 100%;
        margin: auto;
    }
    .sponsordetail .sponsordetailcontent .imagebox {
        max-width: 100%;
        flex: 1 100%;
        margin-right: 0px;
    }
    .sponsordetail .sponsordetailcontent .contentbox {
        flex: 1 100%;
    }
    .sponsordetail .sponsordetailcontent .contentbox .title {
        font-size: 24px;
        line-height: 30px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .auther {
        font-size: 18px;
        line-height: 25px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .category {
        font-size: 15px;
        line-height: 22px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .description {
        font-size: 15px;
    }


    .sponsordetailpage .banner-image .banner-content h1.title { font-size: 40px; } 
    .sponsordetailpage .banner-image .banner-content { bottom: 30px; padding: 0 0 0 50px; z-index: 9; } 
    .sponsordetailpage .banner-image .banner-content h1:before { border-width: 14px;top: 5px; } 
    .sponsordetailpage .banner-image { min-height: 250px; overflow: hidden; } 
    .sponsordetailpage .banner-image img.img { position: absolute; object-fit: cover; top: 0; left: 0; max-width: none; min-height: 100%; width: auto; } 
    .sponsordetailpage .banner-image .banner-content + div div { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

}


@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .sponsordetailpage {
        padding: 0px 0 56px 0;
    }
    
    .sponsordetail .sponsordetailcontent .contentbox .title {
        font-size: 28px;
        line-height: 34px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .auther {
        font-size: 20px;
        line-height: 26px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .category {
        font-size: 15px;
        line-height: 22px;
    }
    .sponsordetail .sponsordetailcontent .contentbox .description {
        font-size: 15px;
    }
}


@media only screen and  (max-width: 520px) {
    .contact-section { flex-direction: column; } 
    .contact-section .label { margin: 0 0 20px; }
}


 .sponsordetailpage .banner-image img{width: 100%;}