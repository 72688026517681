.dashboardpage .maindiv.dashboardwrapper .midcontent {
    width: 100%;
    margin-top: 15px;
    margin-right: 0px;
}
.rightside .maindiv.dashboardwrapper .midcontent {
    width: 76%;
}

.maindiv.dashboardwrapper .midcontent {
    display: flex;
    gap: 4%;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.viewblockcontent {
    width: 48%;
    flex: 48%;
    flex-grow: 0;
    flex-direction: column;
    background: #FBFBFB;
    border-radius: 10px;
    margin-bottom: 46px;
    padding-bottom: 40px;
    position: relative;
}
.viewblockcontent:before {
    width: 100%;
    height: 12%;
    left: 0px;
    content: "";
    position: absolute;
    background: #fff;
}

.viewblockcontent > h2.blocktitle {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin: 0px;
    padding-bottom: 24px;
    background: #fff;
    position: relative;
    z-index: 1;
}
.viewblockcontent .viewcontent {
    width: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    z-index: 1;
    background: #FBFBFB;
}

.viewblockcontent .viewcontent .viewrow {
    padding: 24px;
    border-bottom: 1px solid #B8B8B8;
}
.viewblockcontent .viewcontent .viewrow:last-child {
    border-bottom: 0px;
}

.viewblockcontent .viewcontent .title h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #11072F;
    margin: 0px;
}
.viewblockcontent .viewcontent .title h2 a {
    color: #11072F;
    text-decoration: none;
    transition: all 0.5s ease;
}
.viewblockcontent .viewcontent .title h2 a:hover {
    color: #0771EE;
    text-decoration: none;
}
.viewblockcontent .viewcontent .description {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #2B2B39;
}

.viewblockcontent .viewall {
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 10;
}

.viewblockcontent .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #0771EE;
    cursor: pointer;
}



.react-joyride__spotlight {
    background-color: transparent !important;
    border: 1px solid #0072BB;
    border-radius: 4px !important;
}
.react-joyride__overlay {
    background-color: transparent !important;
}
.react-joyride__tooltip {
    width: 100% !important;
    max-width: 560px !important;
    color: #11072F !important;
    text-align: left !important;
    border-radius: 0px !important;
    padding: 65px 25px 20px 25px !important;
}
#react-joyride-step-0 .react-joyride__tooltip {
    background: url(../../../assets/educationwizard.png) no-repeat 25px 20px;
}
#react-joyride-step-1 .react-joyride__tooltip {
    background: url(../../../assets/networkwizard.png) no-repeat 25px 20px;
}
#react-joyride-step-2 .react-joyride__tooltip {
    background: url(../../../assets/fourmwizard.png) no-repeat 25px 20px;
}
#react-joyride-step-3 .react-joyride__tooltip {
    background: url(../../../assets/pricingwizard.png) no-repeat 25px 20px;
}
#react-joyride-step-4 .react-joyride__tooltip {
    background: url(../../../assets/userwizard.png) no-repeat 25px 20px;
}
#react-joyride-step-5 .react-joyride__tooltip {
    background: url(../../../assets/menuwizard.png) no-repeat 25px 20px;
}
.react-joyride__tooltip > div {
    text-align: left !important;
}
.react-joyride__tooltip div {
    padding: 0px !important;
    color: #2B2B39 !important;
    text-align: left !important;
    font-size: 20px;
    line-height: 30px;
}
.__floater__arrow {
    height: 35px !important;
}
.__floater__arrow span {
    width: 30px;
    height: 30px;
    background: url(../../../assets/trigle-arrow.png) no-repeat 0% 0% !important;
    background-size: 30px !important;
}
.__floater__arrow span svg {
    display: none;
}
.react-joyride__tooltip>div:first-child {
    margin-bottom: 24px;
}
.__floater.__floater__open {
    filter: drop-shadow(2px 2px 10px #747474) !important;
}

.react-joyride__tooltip div button[data-action="primary"] {
    border: 0px !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: bold;
    color: #FFFFFF !important;
    background: #0072BB !important;
    padding: 3px 20px !important;
    border-radius: 2px !important;
}

.react-joyride__tooltip div button[data-action="back"] {
    border: 1px solid #0072BB !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: bold;
    color: #0072BB !important;
    background: #fff !important;
    padding: 3px 20px !important;
    border-radius: 2px !important;
    margin-right: 15px !important;
}

.react-joyride__tooltip div button[data-action="skip"] {
    height: auto;
    border: 1px solid transparent !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #0072BB !important;
    background: #fff !important;
    padding: 10px 0px 0px 0px !important;
}
.react-joyride__tooltip button[data-action="close"] {
    display: none !important;
}

span[x-placement="bottom"] {
    top: -13px !important;
    display: none !important;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .__floater.__floater__open {
        display: none !important;
    }
    .react-joyride__overlay {
        display: none !important;
    }
    .maindiv.dashboardwrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
    .viewblockcontent {
        flex: 100% 1;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .viewblockcontent .viewcontent .viewrow {
        padding: 24px 20px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo {
        gap: 0px;
    }
    .viewblockcontent .viewcontent .title h2 {
        font-size: 16px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .category {
        font-size: 13px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic {
        margin-right: 10px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic, 
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic img {
        width: 50px;
        height: 50px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .createdby {
        flex: 1 1 100%;
        margin-top: 10px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .createdby .created {
        margin-bottom: 5px;
    }
    .forumsviewblock .viewcontent .viewrow .description {
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }
    .viewblockcontent .viewall {
        bottom: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
    .maindiv.dashboardwrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
    .maindiv.dashboardwrapper .midcontent {
        width: 68%;
    }
    .maindiv.dashboardwrapper .rightsidebar {
        width: 30%;
    }
    .viewblockcontent {
        flex: 100% 1;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .createdby {
        gap: 10px
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .createdby .created {
        margin-bottom: 0px;
        line-height: 26px;
    }

    .react-joyride__spotlight {
        background-color: transparent !important;
        border: 1px solid #0072BB;
        border-radius: 4px !important;
    }
    .react-joyride__overlay {
        background-color: transparent !important;
    }
    .react-joyride__tooltip {
        padding: 65px 20px 20px 20px !important;
    }
    .react-joyride__tooltip div {
        font-size: 18px;
        line-height: 26px;
    }    
    .react-joyride__tooltip div button[data-action="primary"] {
        font-size: 15px !important;
    }
    .react-joyride__tooltip div button[data-action="back"] {
        font-size: 15px !important;
    }

}

@media only screen and (min-width: 980px) and (max-width: 1279px) {
    
    .maindiv.dashboardwrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
    .maindiv.dashboardwrapper .midcontent {
        gap: 2%;
    }
    .viewblockcontent {
        flex: 49% 1;
        flex-grow: 0;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation {
        flex-wrap: wrap;
    }
    .forumsviewblock .viewcontent .viewrow .userinformation .createdby {
        flex: 1 1 100%;
        justify-content: space-between;
        margin-top: 10px;
    }
    
}