.viewuserinformation {
  padding: 39px 56px;
  background: #D5E8F4;
}
.viewuserinformation.taketourwrapper {
  padding-bottom: 21px;
}
.viewuserinformation .viewcontent {
  max-width: 1440px;
  margin: auto;
}
.viewuserinformation .viewcontent .viewrow {
  display: flex;
  align-items: center;
}
.viewuserinformation .viewcontent .viewrow .userpic {
  width: 100px;
  height: 100px;
  margin-right: 24px;
  border-radius: 360px;
  position: relative;
}
.viewuserinformation .viewcontent .viewrow .userpic img {
  width: 100px;
  height: 100px;
  border-radius: 360px;
}
.viewuserinformation .viewcontent .viewrow .username h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #11072F;
}
.viewuserinformation .viewcontent .taketourcontent {
  text-align: right;
}
.viewuserinformation .viewcontent .taketourcontent a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #0771EE;
}

.verifymessage {
  padding: 24px 56px;
}
.verifymessage .viewcontent {
  max-width: 1440px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
}
.verifymessage .viewcontent .viewrow {
  max-width: 878px;
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #2B2B39;
  background: #FBFBFB;
  border-radius: 10px;
}
.verifymessage.doyoucomplete .viewcontent .viewrow {
  max-width: 854px;
  padding: 36px;
}
.verifymessage .viewcontent .viewrow p {
  margin: 0px;
}
.verifymessage .viewcontent .viewrow a.verify {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #0771EE;
  margin: 24px 0px 55px;
  display: inline-block;
}

.verifymessage .viewcontent .viewrow .formaction {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 31px;
}

.verifymessage .viewcontent .viewrow .formaction a {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0072BB;
  width: 148px;
  text-align: center;
  padding: 11px 0px;
  background: #FEFEFE;
  border: 1px solid #0072BB;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.5s ease;
}

.verifymessage .viewcontent .viewrow .formaction a.yes {
  color: #fff;
  background: #0072BB;
}

.verifymessage .viewcontent .viewrow .formaction a:hover {
  opacity: 0.85;
}

.maindiv.dashboardwrapper .midcontent {
  display: flex;
  gap: 6%;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.viewblockcontent {
  flex: 47%;
  flex-grow: 1;
  flex-direction: column;
  background: #FBFBFB;
  border-radius: 10px;
  margin-bottom: 46px;
  padding-bottom: 40px;
  position: relative;
}
.viewblockcontent:before {
  width: 100%;
  height: 12%;
  left: 0px;
  content: "";
  position: absolute;
  background: #fff;
}

.viewblockcontent > h2.blocktitle {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #11072F;
  margin: 0px;
  padding-bottom: 24px;
  background: #fff;
  position: relative;
  z-index: 1;
}
.viewblockcontent .viewcontent {
  width: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  z-index: 1;
  background: #FBFBFB;
}

.viewblockcontent .viewcontent .viewrow {
  padding: 24px;
  border-bottom: 1px solid #B8B8B8;
}
.viewblockcontent .viewcontent .viewrow:last-child {
  border-bottom: 0px;
}

.viewblockcontent .viewcontent .title h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #11072F;
  margin: 0px;
}
.viewblockcontent .viewcontent .title h2 a {
  color: #11072F;
  text-decoration: none;
  transition: all 0.5s ease;
}
.viewblockcontent .viewcontent .title h2 a:hover {
  color: #0771EE;
  text-decoration: none;
}
.viewblockcontent .viewcontent .description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #2B2B39;
}

.viewblockcontent .viewall {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 10;
}

.viewblockcontent .viewall a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #0771EE;
  cursor: pointer;
}
