
.connectionsearch .searchbox {
    display: flex;
}
.connectionsearch .searchbox .userpicbox {
    width: 60px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
}
.connectionsearch .searchbox .userpicbox img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
}
.connectionsearch .searchbox .formbox {
    margin-left: 15px;
    margin-right: 15px;
}
.connectionsview .maintitle>h2 {
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: .02em;
    color: #11072f;
    text-align: center;
}
.connectionstabs {
    max-width: 1440px;
    padding: 30px 56px 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.connectionlink ul {
    display: flex;
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.connectionlink ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.connectionlink ul li a {
    font-size: 18px;
    line-height: 100%;
    padding: 0px 35px 0px;
    height: 58px;
    margin: 0px 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #0072BB;
    cursor: pointer;
    text-align: center;
    border-radius: 360px;
    border: 1px solid #0072BB;
    background: #FBFBFB;
}
.connectionlink ul li.active a,
.connectionlink ul li a:hover {
    color: #fff;
    background: #0072BB;
}
.connectionsview {
    max-width: 1130px;
    padding: 20px 0;
    margin: auto;
}
.connectionsview .connectionscontent {
    display: flex;
    flex-wrap: wrap;
}
.connectionsview .connectionscontent .view-row {
    display: flex;
    flex: 1 1 32%;
    flex-grow: 0;
    margin: 0.5%;
    padding: 15px;
    background: #ECECEC;
    border-radius: 10px;
}
.connectionsview .connectionscontent .view-row .userpic {
    width: 60px;
    margin-right: 15px;
    position: relative;
    height: 60px;
}
.connectionsview .connectionscontent .view-row .content {
    flex: 1;
}
.connectionsview .connectionscontent .view-row .name {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #0072bb;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.connectionsview .connectionscontent .view-row .category {
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #11072F;
    margin-bottom: 5px;
}
.connectionsview .connectionscontent .view-row .connected {
    font-size: 14px;
    line-height: 13px;
    color: #808080;
}
.formaction {
    display: flex;
    align-items: center;
}
.formaction button, .formaction a {
    font-family: "Roboto",sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 2px;
    text-decoration: none;
    transition: all .5s ease;
    cursor: pointer;
    border: 1px solid transparent;
    background: #0072bb;
}
.formaction button.removebtn {
    color: #11072f;
    margin-right: 10px;
    border-color: #cccccc;
    background: #fff;
}
.formaction button:hover {
    background: #0072bb;
    color: #fff;
}
.userpic img.connectImg {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .connectionsview .connectionscontent .view-row {
        flex: 1 1 100%;
        margin: 0px 0px 10px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
    .connectionsview .connectionscontent .view-row {
        flex: 1 1 49%;
        margin: 0px 0.5% 1%;
        flex-grow: 0;
    }
    .connectionsview .connectionscontent .view-row .userpic {
        width: 50px;
    }
    .connectionsview .connectionscontent .view-row .userpic img {
        width: auto;
        height: 50px;
    }
    .connectionsview .connectionscontent .formaction {
        flex-wrap: wrap;
    }
    .connectionsview .connectionscontent .formaction button {
        flex: 1 100%;
        max-width: 150px;
        margin-right: 0px;
    }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .connectionstabs {
        flex-wrap: wrap;
        padding: 10px 20px;
    }
    .connectionsearch {
        width: 100%;
    }
    .connectionlink {
        width: 100%;
    }
    .connectionsearch .searchbox .userpicbox {
        width: auto;
        margin-left: 0px;
        margin-right: 15px;
    }
    .connectionsearch .searchbox .userpicbox img {
        width: auto;
        height: 50px;
    }
    .connectionsearch .searchbox .formbox {
        margin-right: 0px;
        margin-left: 0px;
    }
    .connectionsearch .searchbox .formbox .formtext {
        height: 50px;
    }
    .connectionsearch .searchbox .form-action .form-submit {
        height: 50px;
    }
    .connectionlink ul li {
        flex: 1;
    }
    .connectionlink ul li a {
        font-size: 13px;
        padding: 0px 5px 0px;
        height: 40px;
        margin: 0px 5px;
        justify-content: center;
    }
    .connectionsview {
        padding: 10px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 980px) {

    .connectionstabs {
        flex-wrap: wrap;
        padding: 20px 20px;
    }
    .connectionsearch {
        width: 100%;
    }
    .connectionlink {
        width: 100%;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 981px) and (max-width: 1170px) {

}

@media only screen and (min-width: 768px) and (max-width: 1170px) {

    .connectionsearch .searchbox .userpicbox {
        width: auto;
        margin-left: 0px;
        margin-right: 15px;
    }
    .connectionsearch .searchbox .userpicbox img {
        width: auto;
        height: 45px;
    }
    .connectionsearch .searchbox .formbox {
        margin-right: 5px;
        margin-left: 0px;
    }
    .connectionsearch .searchbox .formbox .formtext {
        height: 45px;
    }
    .connectionsearch .searchbox .form-action .form-submit {
        height: 45px;
    }
    .connectionlink {
        flex: 1;
    }
    .connectionlink ul li {
        flex: 1;
    }
    .connectionlink ul li a {
        font-size: 13px;
        padding: 0px 5px 0px;
        height: 45px;
        margin: 0px 5px;
        justify-content: center;
    }
    .connectionsview {
        padding: 10px 20px;
    }

    .connectionsview .connectionscontent .view-row {
        flex: 1 1 49%;
        flex-grow: 0;
    }
    .connectionsview .connectionscontent .view-row .userpic {
        width: 50px;
    }
    .connectionsview .connectionscontent .view-row .userpic img {
        width: auto;
        height: 50px;
    }
}
