.midcontent.resource-document{
    margin: auto!important;
    width: 100%;
    float: unset;
}
.seprator-line{
    border-bottom: 1px solid #CDCDCD;
    padding-top: 60px;
    margin-bottom: 60px;
}

.resource-document h3{
    color: #000;
    font-size: 25px;
    margin-bottom: 20px;
}
ol.item-wrapper {
    background: #ECECEC;
    border-radius: 4px;
    padding: 30px 30px 30px 40px;
}
ol.item-wrapper li {
    font-size: 20px;
    margin-bottom: 20px;
    color: #0072BB;
    cursor: pointer;
    text-transform: capitalize;
}
ol.item-wrapper li:hover {
    color: #083e77;
}
ol.item-wrapper li:last-child{
    margin-bottom:0;
}
.video-title {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.coursevideo {
    border-radius: 7px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) {
    .document-wrapper, .calculator-wrapper,.important-wrapper {
        display: flex;
        flex: 1;
        max-width: 1142px;
        margin: auto;
    }
    .wrapper-left {
        flex: 1;
        margin-right: 25px;
    }
    .wrapper-right{
        flex: 1;
        margin-left: 25px;
    }
}