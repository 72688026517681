.commingsoonpage.maincontent {
    position: relative;
    padding: 0px;
}
.commingsoonpage::before {
    width: 42%;
    height: 100%;
    left: 0px;
    top: 0px;
    content: "";
    position: absolute;
    background: #e7ecfd;
}
.commingsoonpage .signupwrapper {
    gap: 85px;
}
.commingsoonpage .signupintroduction {
    max-width: 569px;
    padding-top: 56px;
    text-align: center;
    background: #e7ecfd;
}
.commingsoonpage .registration-form-wrap {
    width: 604px;
    padding: 56px 0 136px;
}
.commingsoonpage .signupintroduction h1 {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 83px;
    color: #11072F;
}
.commingsoonpage .signupintroduction h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #2B2B39;
}
.commingsoonpage .signupintroduction h3 {
    font-size: 42px;
    line-height: 63px;
    text-align: center;
    color: #2B2B39;
    width: auto;
    padding-bottom: 0px;
}

.commingsoonpage .registration-form-wrap .registration-form .helptext.passwordhelp {
    width: 46%;
    margin: -20px 0px 30px;
    float: right;
    max-width: 200px;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .commingsoonpage::before {
        display: none;
    }
    .commingsoonpage .signupwrapper {
        padding: 0px;
        grid-gap: 0px;
        gap: 0px;
        flex-wrap: wrap;
    }
    .commingsoonpage .signupintroduction {
        max-width: 100%;
        width: 100%;
        padding: 30px 20px;
    }
    .commingsoonpage .signupintroduction h1 {
        font-size: 30px;
        line-height: 35px;
    }
    .commingsoonpage .signupintroduction h2 {
        font-size: 20px;
        line-height: 36px;
    }
    .commingsoonpage .signupintroduction h3 {
        font-size: 22px;
        line-height: 30px;
    }
    .commingsoonpage .registration-form-wrap {
        width: 100%;
        padding: 30px 0px 56px;
        margin: 0px;
        background: #e7ecfd;
    }
    .commingsoonpage .registration-form-wrap .registration-form {
        width: 90%;
        padding: 24px 20px;
        max-width: 604px;
        margin: auto;
    }
    .commingsoonpage .registration-form-wrap .registration-form .helptext.passwordhelp {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .commingsoonpage::before {
        display: none;
    }
    .commingsoonpage .signupwrapper {
        grid-gap: 50px;
        gap: 50px;
    }
    .commingsoonpage .signupintroduction {
        width: 60%;
        padding-left: 24px;
        padding-right: 24px;
    }
    .commingsoonpage .signupintroduction h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .commingsoonpage .signupintroduction h2 {
        font-size: 20px;
        line-height: 26px;
    }
    .commingsoonpage .signupintroduction h3 {
        font-size: 28px;
        line-height: 40px;
        max-width: 100%;
    }
}