.categoryfilterwrapper.stick {
    position: fixed;
    top: 0;
    z-index: 1;
}
.anonymousforum .categoryfilterwrapper {
    padding-top: 88px;
}
.categoryfilterwrapper {
    position: fixed;
    max-width: 312px;
    padding-top: 88px;
    width: 22%;
}
.subcat-topic-warapper .categoryfilterwrapper{
    padding-top: 20px;
}
.discussionblock {
    padding: 14px 0px 14px 45px;
}
.categoryfilter.discussionblock ul li {
    margin-right: 26px;
}
.forumscategory {
    padding: 24px 0px 24px 45px;
    position: relative;
}
.categoryfilter {
    background: #FBFBFB;
    border-radius: 0px 10px 10px 0px;
    margin-bottom: 24px;
}
.categoryfilter:last-child {
    margin-bottom: 0px;
}
.categoryfilter .heading {
    margin: 0px 0px 20px;
}
.categoryfilter h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #11072F;
    margin: 0px;
}
.categoryfilter .heading span {
    width: 24px;
    height: 15px;
    font-size: 0px;
    display: block;
    position: absolute;
    background: url(../../assets/images/blackdownarrow.svg) no-repeat 50% 50%;
    right: 30px;
    top: 28px;
    cursor: pointer;
}
.categoryfilter .heading ul.dropdown {
    display: none;
    position: absolute;
    right: 28px;
    top: auto;
    margin-top: 16px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #0072BB;
}
.categoryfilter .heading ul.dropdown:before {
    content: "";
    position: absolute;
    top: -17px;
    right: 7px;
    border-width: 8px 6px 8px 6px;
    border-style: solid;
    border-color: transparent transparent #0072BB transparent;
}
.categoryfilter .heading ul.dropdown:after {
    content: "";
    position: absolute;
    top: -16px;
    right: 8px;
    border-width: 8px 5px 8px 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}
.categoryfilter .heading ul.dropdown a {
    border: 0px;
    min-width: initial;
    padding: 10px;
}
.categoryfilter .heading ul.dropdown a:hover {
    background-color: transparent;
    color: #0072BB;
}
.categoryfilter ul,
.categoryfilter ul li {
    margin: 0px;
    padding: 0px;
    list-style: none;
    list-style-image: none;
}
.categoryfilter ul {
    /* max-height: 340px; */
    overflow: auto;
    overflow-x: hidden;
}
/* scrollbar */
.categoryfilter ::-webkit-scrollbar {
    width: 6px;
}
.categoryfilter  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.categoryfilter ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.categoryfilter ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
/* scrollbar */

.categoryfilter ul li {
    margin-right: 20px;
    margin-top: 22px;
}
.categoryfilter ul.maincat-list>li:first-child {
    margin-top: 0px;
}
.categoryfilter.discussionblock ul li {
    margin-bottom: 12px;
}

.categoryfilter ul li button,
.categoryfilter ul li a {
    width: 100%;
    min-width: 174px;
    padding: 7px 10px;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #0072BB;
    cursor: pointer;
    text-decoration: none;
    background: #FFFFFF;
    border: 0.6px solid #0072BB;
    border-radius: 4px;
    text-align: center;
    transition: all 0.5s ease;
    box-shadow:none;
}
.categoryfilter ul li button:hover,
.categoryfilter ul li a.active,
.categoryfilter ul li a:hover {
    color: #fff;
    background-color: #0072BB;
}
.categoryfilter.discussionblock ul li a {
    text-align: center;
}
.ant-modal-close {
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    right: 24px;
    top: 24px;
    border-radius: 36px;
    cursor: pointer;
    background: #FBFBFB url(../../assets/images/popupclose.svg) no-repeat 50% 50%;
}
.ant-modal-close .ant-modal-close-x {
    display: none;
}
.filtercategorypopup .ant-modal-body {
    padding-top: 85px;
}
/* scrollbar */
.filtercategorypopup .ant-modal-body ::-webkit-scrollbar {
    width: 6px;
}
.filtercategorypopup .ant-modal-body ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.filtercategorypopup .ant-modal-body ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.filtercategorypopup .ant-modal-body ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
.filterpopdataempty {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
}
/* scrollbar */

ul.filterpopdataoption {
    margin: 0px;
    padding: 10px 0px;
    list-style: none;
    list-style-image: none;
    max-height: calc(100vh - 260px);
    overflow: auto;
    overflow-x: hidden;
}

ul.filterpopdataoption li {
    margin: 0px 5px;
    padding: 0px;
    list-style: none;
    list-style-image: none;
}
ul.filterpopdataoption li a {
    margin: 0px;
    border: 0px;
    padding: 15px 20px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    box-shadow: 0px 0px 4px #0072bb;
    border-radius: 4px;
    margin-bottom: 16px;
    white-space: break-spaces;
    word-break: break-word;
}
ul.filterpopdataoption li:last-child a {
    margin-bottom: 0px;
}
ul.filterpopdataoption li a:hover {
    color: #fff;
    background-color: #0072bb;
}
.ant-modal {
    padding: 0px 20px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .categoryfilterwrapper {
        width: 100%;
        max-width: 100%;
        padding-top: 0px;
        position: relative;
    }
    .subcat-topic-warapper .categoryfilterwrapper {
        padding-top: 0px;
    }
    .anonymousforum .categoryfilterwrapper {
        padding-top: 20px;
    }
    .forums .categoryfilterwrapper .searchbox {
        padding: 20px;
    }
    .categoryfilter {
        padding: 20px;
    }
    .categoryfilter ul {
        max-height: 200px;
        padding-right: 10px;
    }
    .categoryfilter ul ul {
        padding-right: 0px;
    }
    .categoryfilter ul li {
        margin-top: 15px;
        width: 100%;
    }
    .categoryfilter ul li a {
        text-align: center;
        display: block;
    }
    ul.filterpopdataoption li a {
        padding: 10px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .anonymousforum .categoryfilterwrapper {
        padding-top: 68px;
    }
    .categoryfilterwrapper {
        width: 25%;
    }
    .discussionblock,
    .forumscategory {
        padding: 14px;
    }
/*     .categoryfilter ul {
        max-height: 250px;
        overflow: auto;
    } */
    .categoryfilter ul li {
        display: block;
        margin-top: 12px;
    }
    .categoryfilter ul li button,
    .categoryfilter ul li a {
        min-width:auto;
        display: block;
        font-size: 13px;
        line-height: 16px;
    }
    .categoryfilter h2.blocktitle {
        font-size: 15px;
        line-height: 19px;
    }
    .categoryfilter .heading span {
        right: 15px;
        top: 18px;
    }
    .categoryfilter .heading {
        margin: 0px 0px 15px;
    }
    .categoryfilter .heading ul.dropdown {
        right: 13px;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1279px) {
    .discussionblock,
    .forumscategory {
        padding: 14px 25px;
    }
}

/* @media only screen and (min-width: 1280px) and (max-width: 1350px) {
    .categoryfilter ul {
        max-height: 255px;
    }
}

@media only screen and (min-width: 1351px) and (max-width: 1600px) {
    .categoryfilter ul {
        max-height: 340px;
    }
}

@media only screen and (min-width: 1601px) and (max-width: 1900px) {
    .categoryfilter ul {
        max-height: 340px;
    }
} */