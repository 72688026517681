.tipofweekdetails .blogsdetail {
   padding-bottom: 75px;
}
.tipofweekdetails .breadcrumb ul {
   padding-bottom: 25px;
}
.tipofweekdetails .tipofweektitle {
   max-width: 1440px;
   margin: auto;
   padding: 0px 56px;
}
.tipofweektitleinner {
   width: 72%;
   text-align: center;
}
.tipofweekdetails .tipofweektitle .title {
   max-width: 100%;
   font-size: 20px;
   line-height: 23px;
   letter-spacing: 0.02em;
   color: #11072F;
   padding: 0px 0px 6px 0px;
   font-weight: normal;
}
.tipofweekdetails .tipofweektitle .created {
   font-size: 13px;
   line-height: 15px;
   letter-spacing: 0.02em;
   color: #2B2B39;
   padding-bottom: 14px;

}
.tipofweekdetailcontent {
   display: flex;
   max-width: 1440px;
   padding: 0px 56px;
   margin: auto;
}
.tipofweekdetailcontent .blogsdetailcontent {
   width: 72%;
   padding: 0px;
   margin-right: 3%;
}
.tipofweekdetailcontent .thumbnailpager {
   width: 25%;
}
.tipofweekdetailcontent .tipvideo {
   border-radius: 10px;
}
.tipofweekdetailcontent .tipvideo .react-player__preview {
   border-radius: 10px;
}
.tipofweekdetailcontent .tipvideo .react-player__shadow {
   box-shadow: none !important;
   background: rgba(225,225,225,0.8) !important;
}
.tipofweekdetailcontent .tipvideo .react-player__shadow .react-player__play-icon {
   margin-left: 4px !important;
   border-width: 7px 0px 7px 10px !important;
   border-color: transparent transparent transparent #0072BB !important;
}

.tipofweekdetailcontent .thumbnailpager .thumbnailpagerinner {
   padding: 16px;
   background: #F9F9F9;
   border-radius: 10px;
}
.tipofweekdetailcontent ul {
   padding: 0px;
   margin: 22px 0px;
   display: flex;
}
.tipofweekdetailcontent ul li.share {
   margin-bottom: 0px;
}
.tipofweekdetailcontent ul li.share .share-icon {
   font-size: 0px;
   align-items: flex-start;
}
.tipofweekdetailcontent ul li.share .share-icon:before {
   margin-right: 0px;
   background-size: 18px;
   background-color: #FBFBFB;
}
.tipofweekdetailcontent .categoriesbox {
   padding-bottom: 36px;
   background: none;
}
.tipofweekdetailcontent .categoriesbox {
   font-weight: bold;
   font-size: 16px;
   line-height: 19px;
   letter-spacing: 0.02em;
   color: #909090;
}
.tipofweekdetailcontent .categoriesbox .categories {
   max-width: 100%;
   padding: 0px;
}
.tipofweekdetailcontent .categoriesbox .categories strong {
   color: #2B2B39;
   font-weight: normal;
}
.tipofweekdetailcontent .title {
   max-width: 100%;
   font-size: 18px;
   line-height: 22px;
   letter-spacing: 0.02em;
   color: #11072F;
   font-weight: 400;
   padding: 0px;
}
.tipofweekdetailcontent .description {
   max-width: 100%;
   padding: 16px 0px 20px 0px;
   font-size: 13px;
   line-height: 24px;
}
.thumbnailpager .thumbnailcontent {
   display: flex;
   min-height: 100px;
   margin-bottom: 15px;
   border-radius: 4px;
}
.thumbnailpager .thumbnailcontent .leftcontent {
   width: 110px;
}
.thumbnailpager .thumbnailcontent .rightcontent {
   flex: 1;
   line-height: 18px;
   padding-left: 10px;
   position: relative;
}
.thumbnailpager .thumbnailcontent .rightcontent a {
   font-size: 16px;
   line-height: 19px;
   letter-spacing: 0.02em;
   color: #11072F;
   text-overflow:ellipsis;
   overflow:hidden;
   display: -webkit-box !important;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   white-space: normal;
   font-weight: 500;
}
.thumbnailpager .thumbnailcontent .rightcontent .created {
   font-size: 13px;
   line-height: 19px;
   letter-spacing: 0.02em;
   color: #2B2B39;
   text-align: right;
   position: absolute;
   right: 0px;
   bottom: 0px;
}
.thumbnailpager .thumbnailcontent .rightcontent .createdname {
   font-size: 13px;
   line-height: 19px;
   letter-spacing: 0.02em;
   color: #2B2B39;
   text-align: right;
   position: absolute;
   right: 0px;
   bottom: 17px;
}
.tipofweekdetailcontent .thumbnailpager a.active .thumbnailcontent {
   background-color: #EBEBEB;
   padding: 5px;
}
.tipofweekdetailcontent .thumbnailpager .leftcontent .react-player__preview {
   background-repeat: no-repeat;
   border-radius: 4px;
}
.tipofweekdetailcontent .thumbnailpager .leftcontent .react-player__shadow {
   display: none !important;
}
.tipofweekdetailcontent .viewall {
   text-align: right;
   margin-top: 20px;
}
.tipofweekdetailcontent .viewall a {
   font-family: 'Roboto', sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 26px;
   letter-spacing: 0.02em;
   text-decoration: underline;
   color: #0771EE;
}
.tipofweekdetails .actionbutton {
   max-width: 1440px;
   margin: auto;
   padding: 36px 56px 0px 56px;
}
.tipofweekdetails .actionbutton .actionbuttoninner {
   width: 72%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.tipofweekdetails .actionbutton .button button {
   min-width: 184px;
   text-align: center;
   color: #fff;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   letter-spacing: 0.02em;
   height: 40px;
   background: #0072BB;
   border-radius: 4px;
   box-shadow: none;
   text-shadow: none;
   border: 1px solid #0072BB;
}
.tipofweekdetails .actionbutton .button.previous button {
   color: #0072BB;
   background: #fff;
   margin-right: 17px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
   .tipofweekdetails .blogsdetail {
      padding-bottom: 20px;
   }
   .tipofweekdetails .tipofweektitle {
      padding: 0px 20px;
   }
   .tipofweekdetailcontent {
      padding: 0px 20px;
      flex-wrap: wrap;
   }
   .tipofweekdetailcontent .blogsdetailcontent {
      width: 100%;
      margin-right: 0;
   }
  .tipofweektitleinner {
      width: 100%;
   }
   .tipofweekdetailcontent .tipvideo>div {
      height: auto !important;
   }
   .tipofweekdetailcontent .tipvideo .react-player__preview {
      height: auto !important;
      padding-top: 22%;
      padding-bottom: 22%;
   }
   .tipofweekdetailcontent .tipvideo .react-player__shadow {
      width: 40px !important;
      height: 40px !important;
   }
   .tipofweekdetailcontent .thumbnailpager {
      width: 100%;
   }
   .tipofweekdetailcontent ul {
      margin: 12px 0px;
   }
   .tipofweekdetailcontent .categoriesbox {
      padding-bottom: 15px;
   }
   .tipofweekdetailcontent .title {
      font-size: 17px;
   }
   .tipofweekdetailcontent .description {
      padding: 15px 0px 10px 0px;
   }
   .tipofweekdetails .actionbutton {
      padding: 25px 25px;
   }
   .tipofweekdetails .actionbutton .actionbuttoninner {
      width: 100%;
      display: flex;
      flex-direction: column;
   } 
   .tipofweekdetails .actionbutton .button button {
    /* width: 100%;
    min-width: 140px; */
      margin-top: 10px;
   }  
   .tipofweekdetails .actionbutton .button.previous button {
      color: #0072BB;
      background: #fff;
      margin-left: 12px;
   
   }

}