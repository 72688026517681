.guestconnectionsview {
    padding: 16px 16px 8px;
    margin-top: 24px;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;    
    color: #454545;
    background: #FBFBFB;
}
.guestconnectionsview .heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.guestconnectionsview h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #11072F;
    margin: 0px;
    position: relative;
    flex: 1;
}
.guestconnectionsview h2.blocktitle::before {
    content: "";
    width: 60px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    background-color: #363636;
}
.user.guestconnectionsview .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #0771EE;
}

.guestconnectionsview .viewcontent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5%;
}

.guestconnectionsview .viewcontent .viewrow {
    flex: 1 30%;
    margin: 8px 0px;
}
.guestconnectionsview .viewcontent .viewrow .usericon {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FEFEFE;
    border-radius: 3px;
    margin-bottom: 6px;
}
.guestconnectionsview .viewcontent .viewrow .usericon img {
    width: 20px;
    height: 20px;
}
.guestconnectionsview .viewcontent .viewrow .username a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #2B2B39;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.5s ease;
}
.guestconnectionsview .viewcontent .viewrow:hover .username a {
    color: #0771EE;
}
.guestintroductionview {
    padding: 18px 16px 24px;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;    
    color: #454545;
    background: #FBFBFB;
}
.guestintroductionview h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #11072F;
    margin: 0px 0px 24px;
    position: relative;
}

.guestintroductionview h2.blocktitle::before {
    content: "";
    width: 60px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    background-color: #363636;
}

.guestintroductionview .guestintroductioncontent .viewrow {
    margin-bottom: 16px;
}
.guestintroductionview .guestintroductioncontent .viewrow:last-child {
    margin-bottom: 0px;
}

.guestintroductionview .guestintroductioncontent .viewrow .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #454545;
    margin-bottom: 4px;
}
.guestintroductionview .guestintroductioncontent .viewrow .subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.guestviewcontent {
    max-width: 1100px;
    margin: auto;
    overflow: hidden;
}

.guestviewcontent .leftsidebar {
    width: 28%;
    float: left;
}

.guestviewcontent .midcontent {
    width: 70%;
    float: left;
    margin-left: 2%;
}

.guestposts {
    padding: 16px 20px;
    background: #FBFBFB;
    border-radius: 10px;
}
.guestposts h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #11072F;
    margin: 0px 0px 9px;
    position: relative;
}
.guestposts h2.blocktitle::before {
    content: "";
    width: 60px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    background-color: #363636;
}
.guestposts .posts {
    background: #FEFEFE;
    border-radius: 10px;
    margin-top: 18px;
}

.posts .userinfomation {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 24px 24px 12px;
    border-bottom: 1px solid #EEEEEE;
}
.posts .userinfomation .userpic,
.posts .userinfomation .userpic img, 
.posts .userinfomation .userpict,
.posts .userinfomation .userpict img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 360px;
}
.posts .userinfomation .title h2 {
    margin: 0px;
}
.posts .userinfomation .title h2 a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    text-decoration: none;
}
.posts .userinfomation .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.posts .content {
    display: flex;
    padding: 24px 24px 10px;
}
.posts .content .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #11072F;
    flex: 1;
}
.posts .content .createdby {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #909090;
    padding-top: 4px;
}
.posts .content .imagebox {
    flex: 1;
    width: 200px;
    height: 200px;
    border-radius: 6px;
    margin-right: 16px;
    position: relative;
}

.posts .content .imagebox img {
    width: 200px;
    height: 200px;
    border-radius: 6px;
}
.posts .content .imagebox span {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.75) url(../../assets/images/play.svg) no-repeat 50% 50%;
    margin: auto;
    left: 0px;
    right: 0px;
    font-size: 0px;
    cursor: pointer;
}

.posts .content .body {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 12px 0px 24px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}

.guestprofileinformation {
    max-width: 1200px;
    margin: auto auto 16px;
}

.guestprofileinformation .coverimage {
    height: 420px;
    border-radius: 2px;
    background-color: #dcdcdc;
}

.guestprofileinformation .coverimage img {
    height: 420px;
    border-radius: 2px;
    background-color: #dcdcdc;
    object-fit: cover;
    width: 100%;
}

.guestprofiledetails {
    text-align: center;
}
.guestprofiledetails .userimagewrapper{
    position: relative;
    max-width: 180px;
    margin: auto;
}
.guestprofiledetails .userimagewrapper span.lazy-load-image-background{
    width: 100%!important;
    height: auto!important;
}
.guestprofiledetails .userimagewrapper  .pro-icon{
    bottom: -3px;
    right: 33px;
    width: 30px;
    height: 30px;
    background-size: 30px;
    padding: 15px;
}
.guestprofiledetails .userpict {
    width: 180px;
    height: 180px;
    border: 10px solid #fff;
    margin: -160px auto auto;
    border-radius: 60%;
    background: #dcdcdc;
    z-index: 0;
    position: relative;
}
.guestprofiledetails .userpict.img{
    object-fit: cover;
} 
.guestprofiledetails .userpict .img {
    width: 160px;
    height: 160px;
    border-radius: 60%;
    object-fit: cover;
}
.guestprofiledetails .userpict img.img.pro {
    border: 5px solid #FFD700;
}
.guestprofiledetails .userpict .pro-icon{
    background-size: 30px;
    width: 30px;
    height: 30px;
    border: 5px solid #FFD700;
    right: 23px;
    padding: 15px;
}

.guestprofiledetails .username h2{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #11072F;
    margin: 10px 0px 18px;
}
.guestprofiledetails .description{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
}
.guestprofiletabsview {
    max-width: 1100px;
    margin: auto;
    border-top: 1px solid #D5D5D5;
}
.guestprofiletabs {
    display: flex;
    margin-top: 16px;
    margin-bottom: 36px;
}
.guestprofiletabs .lefttab {
    flex: 1;
}
.guestprofiletabs ul,
.guestprofiletabs ul li{
    margin: 0px;
    padding: 0px;
    list-style: none;
    list-style-image: none;
}
.guestprofiletabs ul {
    display: flex;
}
.guestprofiletabs .righttab .formaction button.removebtn {
    background: transparent;
    color: #0072bb;
    border: 0.8px solid #0072BB;
}
.guestprofiletabs .righttab .formaction button.removebtn:hover {
    border: 1px solid transparent;
    background: #0072bb;
    color: #fff;
}
.guestprofiletabs .righttab .formaction button.acceptbtn {
    margin-right: 0;
}

.guestprofiletabs .righttab .formaction button {
    margin: 0 20px;
    height: 38px;
    border: 1px solid transparent;
    background: #0072bb;
    color: #fff;
    font-size: 14px;
}
.guestprofiletabs .lefttab ul li a {
    width: 138px;
    height: 38px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #2B2B39;
    border-radius: 4px;
    text-decoration: none;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 0.8px solid #0072BB;
    transition: all 0.5s ease;
}
.guestprofiletabs .righttab ul li a,
.guestprofiletabs .righttab ul li span.editbutton {
    width: 118px;
    height: 38px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0072BB;
    border: 0.8px solid #0072BB;
    text-decoration: none;
    cursor: pointer;
    margin-right: 24px;
    transition: all 0.5s ease;
}
.guestprofiletabs ul li:last-child a,
.guestprofiletabs .righttab ul li:last-child a {
    margin-right: 0px;
}
.guestprofiletabs .lefttab ul li a .count {
    padding-left: 5px;
}

.guestprofiletabs .righttab ul li.report span {
    width: 60px;
    height: 38px;
    background: #FFFFFF;
    border: 0.8px solid #0072BB;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.guestprofiletabs .righttab ul li.report span .dot {
   font-size: 0px;
   width: 10px;
   height: 40px;
   margin: auto;
   background: url(../../assets/images/dot.svg) repeat-x 50% 50%;
}

.guestprofiletabs .righttab ul li.report {
    position: relative;
}

.guestprofiletabs .righttab ul li.report .dropdown {
    display: none;
    position: absolute;
    right: 0px;
    top: auto;
    margin-top: 16px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #0072BB;
}
.guestprofiletabs .righttab ul li.report .dropdown:before {
    content: "";
    position: absolute;
    top: -21px;
    right: 16px;
    border-width: 10px 7px 10px 7px;
    border-style: solid;
    border-color: transparent transparent #0072BB transparent;
}
.guestprofiletabs .righttab ul li.report .dropdown:after {
    content: "";
    position: absolute;
    top: -19px;
    right: 17px;
    border-width: 10px 6px 10px 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.guestprofiletabs .righttab ul li.report .dropdown li a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0072BB;
    background: none;
    padding: 0px 20px;
    border: 0px;
    border-radius: 0px;
}
.commentformwrapper {
    padding: 20px 24px;
}

.guestposts .commentformwrapper form {
    border-radius: 6px;
    background: #FBFBFB;
    margin: 0px;
    line-height: 14px;
    padding: 15px 16px;
}

.commentreview {
    padding: 0px 24px;
}
.commentreview .commentrow {
    padding: 20px 16px;
    border-radius: 6px;
    background: #FBFBFB;
    display: flex;
    gap: 12px;
}
.forumsposts .commentreview {
    padding: 21px 0px 0px;
    display: none;
}
.commentviewless {
    display: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #0771EE;
    cursor: pointer;
}
.forumsposts .commentreview .commentrow {
    padding: 9px 16px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-bottom: 24px;
}
.commentreview .userpict,
.commentreview .userpict img {
    width: 30px;
    height: 30px;
    border-radius: 36px;
}
.commentreview .commentcontent {
    flex: 1;
}
.commentreview .commentcontent .username {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #11072F;
    margin-top: 3px;
    margin-bottom: 2px;
}
.commentreview .commentcontent .description {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.commentreview .commentcontent .commentreply {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.commentreview .commentcontent .commentreply .likeandreply {
    flex: 1;
}
.commentreview .commentcontent .commentreply ul,
.commentreview .commentcontent .commentreply ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}

.commentreview .commentcontent .commentreply ul {
    display: flex;
    align-items: center;
    gap: 16px;
    line-height: 12px;
}

.commentreview .commentcontent .commentreply ul li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #0771EE;
}
.commentreview .commentcontent .commentreply .createdby {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #909090;
}
.guestconnectionsview {
    padding: 16px 16px 8px;
    margin-top: 24px;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;    
    color: #454545;
    background: #FBFBFB;
}
.guestconnectionsview .heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.guestconnectionsview h2.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #11072F;
    margin: 0px;
    position: relative;
    flex: 1;
}
.guestconnectionsview h2.blocktitle::before {
    content: "";
    width: 60px;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    background-color: #363636;
}
.guestconnectionsview .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #0771EE;
}

.guestconnectionsview .viewcontent {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5%;
}

.guestconnectionsview .viewcontent .viewrow {
    flex: 1 30%;
    margin: 8px 0px;
}
.guestconnectionsview .viewcontent .viewrow .usericon {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FEFEFE;
    border-radius: 3px;
    margin-bottom: 6px;
}
.guestconnectionsview .viewcontent .viewrow .usericon img {
    width: 20px;
    height: 20px;
}
.guestconnectionsview .viewcontent .viewrow .username a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #2B2B39;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.5s ease;
}
.guestconnectionsview .viewcontent .viewrow:hover .username a {
    color: #0771EE;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {

    .guestprofileinformation .coverimage {
        height: auto;
    }
    .guestprofileinformation .coverimage img {
        width: 100%;
        height: auto;
        height: 180px;
        object-fit: cover;
    }
    .guestprofiledetails .userpict {
        width: 120px;
        height: 120px;
        margin: -100px auto auto;
    }
    .guestprofiledetails .userpict .img {
        width: 100px;
        height: 100px;
    }
    .guestprofiledetails .username h2 {
        font-size: 24px;
        line-height: 30px;
        margin: 10px 0px 18px;
        padding: 0px 20px;
    }
    .guestprofiledetails .description {
        font-size: 15px;
        line-height: 26px;
        padding: 0px;
    }
    .guestprofiletabsview {
        margin: 0px;
    }
    .guestprofiletabs {
        margin-top: 4%;
        margin-bottom: 0px;
    }
    .guestprofiletabs .lefttab,
    .guestprofiletabs .righttab {
        flex: 1 1 auto;
    }
    .guestprofiletabs .righttab ul {
        justify-content: flex-end;
    }
    .guestprofiletabs ul {
        flex-wrap: wrap;
    }
    .guestprofiletabs ul li {
        margin-right: 4%;
        margin-bottom: 4%;
        flex-grow: 0;
    }
    .guestprofiletabs ul li:nth-child(2n) {
        margin-right: 0px;
    }
    .guestprofiletabs .righttab {
        margin-bottom: 4%;
    }
    .guestprofiletabs .righttab li {
        margin-bottom: 0px;
    }
    .guestprofiletabsview .guestprofiletabs ul li a {
        width: 100%;
        padding: 0px 10px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .guestprofiletabs .righttab ul li.report span {
        width: 100%;
    }
    .guestviewcontent {
        margin: auto 20px;
    }
    .guestviewcontent .leftsidebar {
        width: 100%;
    }
    .guestviewcontent .midcontent {
        width: 100%;
        margin-top: 20px;
        margin-left: 0%;
    }
    .guestposts {
        padding: 16px 15px;
    }    
    .posts .userinfomation {
        gap: 0px;
        padding: 20px 15px;
    }
    .posts .userinfomation .userpic,
    .posts .userinfomation .userpict {
        margin-right: 10px;
    }
    .posts .content {
        padding: 20px 15px;
        flex-wrap: wrap;
    }
    .posts .content .contentbox {
        margin-top: 20px;
        flex: 1 100%;
    }
    .posts .content .title {
        font-size: 16px;
        line-height: 22px;
        flex: 1 100%;
    }
    .posts .content .createdby {
        font-size: 12px;
        padding-top: 10px;
    }
    .posts .content .imagebox {
        width: 100%;
        height: 100%;
        margin-right: 0px;
        flex: 1 100%;
    }
    .posts .content .imagebox img {
        width: 100%;
        height: auto;
        border-radius: 6px;
    }
    
    .postsocialshare {
        padding: 0px 15px 20px;
    }
    .commentreview {
        padding: 0px 15px;
    }
    .commentformwrapper {
        padding: 15px;
    }

    .commentreview .commentcontent .commentreply {
        flex-wrap: wrap;
    }
    .commentreview .commentcontent .commentreply .likeandreply {
        flex: 1 100%;
        margin-bottom: 10px;
    }
    .guestconnectionsview .viewcontent {
        gap: 0px;
    }
    .guestconnectionsview .viewcontent .viewrow {
        flex: 1 48%;
        margin: 8px 4% 4% 0px;
        flex-grow: 0;
        width: 50%;
    }
    .guestconnectionsview .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px;
    }
    .guestconnectionsview .viewcontent .viewrow .usericon {
        width: 100%;
        height: 100px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
    .guestprofiletabs .lefttab ul li a {
        width: 128px;
        font-size: 13px;
        margin-right: 10px;
    }
    .guestprofiletabs .righttab ul li a,
    .guestprofiletabs .righttab ul li span.editbutton {
        width: 100px;
        font-size: 13px;
        margin-right: 10px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {

    .guestprofileinformation .coverimage {
        height: auto;
    }
    .guestprofileinformation .coverimage img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    .guestprofiledetails .userpict {
        width: 150px;
        height: 150px;
        margin: -100px auto auto;
    }
    .guestprofiledetails .userpict .img {
        width: 130px;
        height: 130px;
    }
    .guestprofiletabsview {
        margin: 0px auto;
    }
    .guestprofiledetails .description {
        font-size: 16px;
        line-height: 26px;
    }
    .guestprofiledetails .username h2 {
        font-size: 28px;
    }
    .guestviewcontent {
        margin: auto;
    }
    .posts .userinfomation {
        gap: 15px;
    }
    .posts .content .title {
        font-size: 15px;
        padding-right: 10px;
    }
    .maincontent.guestprofileview {
        padding-bottom: 0px;
    }
    .maincontent.guestprofileview .guestprofileview {
        margin: 0px;
    }
    .posts .content .imagebox,
    .posts .content .imagebox img {
        width: 140px;
        height: 140px;
        flex: none;
    }
    .posts .content .contentbox {
        flex: 1;
    }
    .guestconnectionsview .viewcontent {
        gap: 0px;
    }
    .guestconnectionsview .viewcontent .viewrow {
        flex: 1 48%;
        margin: 8px 4% 4% 0px;
        flex-grow: 0;
        width: 50%;
    }
    .guestconnectionsview .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px;
    }
    .guestconnectionsview .viewcontent .viewrow .usericon {
        width: 100%;
        height: 80px;
    }
}
.mandatoryfield  {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
   
}
.spin {
    text-align: center;
}