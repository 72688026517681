.searchbox {
    flex: 1;
}
.searchbox form {
    display: flex;
    margin: 0px 24px 0px 0px;
}
.searchbox .ant-form-item {
    margin-bottom: 0px;
}
.forums .searchbox form {
    margin: 0px 0px 56px 56px;
}
.anonymousforum .searchbox {
    width: 74%;
    position: fixed;
    padding: 14px 0px;
    background: #fff;
}
.anonymousforum .searchbox form {
    max-width: 730px;
    margin: 0px;
}
.searchbox .search-filter {
    flex: 1;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .searchbox {
        flex: 100%;
        padding: 20px 0px;
    }
    .searchbox form {
        margin: 0px;
    }
    .forums .searchbox form {
        margin: 0px;
    }
    .anonymousforum .searchbox {
        width: 100%;
        padding: 20px 0px;
        position: relative;
    }
    .searchbox form .formtext {
        height: 50px;
        font-size: 13px;
    }
    .searchbox form .form-action .form-submit {
        height: 50px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .searchbox {
        flex: auto;
    }
    .searchbox form {
        margin: 0px 10px 0px 0px;
    }
    .searchbox form .formtext {
        height: 40px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .searchbox form .form-action .form-submit {
        width: 50px;
        height: 40px;
    }
    .forums .searchbox form {
        margin: 0px 0px 24px 14px;
    }
    .anonymousforum .searchbox {
        width: 71%;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1279px) {
    
    .forums .searchbox form {
        margin: 0px 0px 24px 14px;
    }
    
}