.commentformwrapper {
    padding: 20px 24px;
}

.commentformwrapper form,
.commentformwrapper .comment-form {
    width: 100%;
    padding: 0px;
    border-radius: 6px;
    /* background: #FBFBFB; */
    margin: 0px;
}
.commentformwrapper .comment-form div {
    border-radius: 6px;
}

.commentformwrapper textarea {
    padding: 7px 7px;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    height: 28px;
    width: 100%;
    overflow: hidden;
    outline: none;
    background: #FEFEFE;
    border-radius: 6px;
    border: 0px;
}
.comment-form .ant-btn{
    display: block;
    float: right;
    margin-top: 24px;
}
.comment-form .ant-btn-primary[disabled],
.comment-form .ant-btn-primary[disabled]:hover,
.comment-form .ant-btn-primary[disabled]:focus,
.comment-form .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5!important;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
}
.post-wrapper .post-message .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #1890ff;
}
.post-messageContent {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.post-wrapper .title button,
.post-wrapper .edit button {
    padding: 0px;
    font-weight: 400;
    font-size: 15px;
    line-height: 12px;
    height: auto;
    color: #0771EE;
    border: 0px;
    cursor: pointer;
    background: none;
    box-shadow: none;
    border-radius: 0px;
    margin: 10px 0px;
}

/* .post-wrapper .title button span,
.post-wrapper .edit button span {
    text-decoration-line: underline;
} */

.error-message {
    color: red;
    font-size: 12px;
}
.post-wrapper .title button[name="cancel"],
.post-wrapper .edit button[name="cancel"] {
    margin-left: 15px;
}

.post-wrapper input[type="text"] {
    width: 100%;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.02em;
    padding: 12px 15px;
    font-size: 14px;
    line-height: 18px;
    color: #2B2B39;
    overflow: hidden;
    outline: none;
    resize: none;
    background: #FEFEFE;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all 0.3s, height 0s;
}
.post-wrapper input[type="text"]:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}