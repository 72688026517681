.pricing {
    max-width: 1440px;
    margin: auto;
    padding: 20px 56px 56px;
}
.pricing .subheading {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #2B2B39;
    margin-bottom: 24px;
}
.pricing h2 {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    color: #11072F;
    margin-bottom: 36px;
}
.pricelist {
    max-width: 1150px;
    margin: auto;
}
.pricelist .membershipbox {
    display: flex;
    gap: 35px;
}
.membershipbox.two-box {
    max-width: 750px;
    margin: auto;
}
.pricelist .membership {
    flex: 1 1 33%;
    flex-grow: 0;
    padding: 34px 16px 24px 16px;
    border-radius: 6px;
    position: relative;
    padding-bottom: 100px;
}
.pricelist .two-box .membership {
    flex: 1 1 50%;
}
.pricelist .formactions {
    position: absolute;
    width: calc(100% - 32px);
    max-width: 325px;
    bottom: 20px;
}
.pricelist .freemember {
    background: #FBFBFB;
}
.pricelist .freemember .ant-radio-group {
    padding-left: 25px;
}
.pricelist .basicmember {
    background: #D5E8F4;
}
.pricelist .membership h3 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin-bottom: 18px;
    padding-left: 25px;

}
.pricelist .membership h3.active{
    background: url(../../assets/images/checked.svg) no-repeat 0% 15%;
}

.pricelist .pricelist h3 {
    text-align: center;
    margin-bottom: 45px;
}
.pricelist .membership h4 {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    margin-bottom: 23px;
    padding-left: 25px;
}
.pricelist .membership .price {
    margin-bottom: 18px;
    display: flex;
    padding-left: 25px;
}
.pricelist .membership .price .ant-radio-group {
    flex: 1;
}
.pricelist .membership .price .ant-radio-group .ant-radio-inner {
    top: 5px;
    border-color: #0072BB;
    background-color: transparent;
}
.pricelist .price .ant-radio-group label span.ant-radio + * {
    padding-left: 8px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #11072F;
    padding-right: 0px;
}
.pricelist .membership .price .ant-form-item {
    margin-bottom: 0px;
    text-align: right;
    line-height: 10px;
}
.pricelist .membership .price .ant-form-item .pricevalue {
    font-size: 28px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.pricelist .membership .price .ant-form-item .labels {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.pricelist .membership ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.pricelist .membership ul li {
    padding: 0px 0px 0px 25px;
    margin: 0px 0px 18px;
    list-style: none;
    list-style-image: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #11072F;
    background: url(../../assets/images/bullet.svg) no-repeat 0% 50%;
    background-size: 10px;
}
.pricelist .membership .formsubmit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #0072BB;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 4px;
    margin-top: 36px;
    cursor: pointer;
}
.pricelist .membership .formsubmit:hover {
    border-color: #000;
    color: #000!important;
}
.pricelist .membership button.formsubmit {
    color: #fff;
    background: #0072BB;
}
.pricelist .card-wrapper {
    max-width: 450px;
    margin: auto;
    min-height: 200px;
    display: flex;
    align-items: center;
    box-shadow: 2px 6px 10px rgb(0 0 0 / 50%);
}
.pricelist .card-wrapper .ant-card-body {
    width: 100%;
    padding: 25px;
}

.pricelist .card-wrapper .form-item {
    position: relative;
}
.pricelist .card-wrapper .form-item.email .ant-form-item-label {
    position: absolute;
    top: -21px;
    left: 15px;
    background: #fff;
    z-index: 1;
    line-height: 15px;
    padding: 0px 5px 0px 10px;
}
.pricelist .card-wrapper .form-item.email .ant-form-item-label label {
    height: auto;
    font-size: 15px;
    line-height: 15px;
}
.ant-form-item-label > label::after {
    display: none;
}
.pricelist .card-wrapper .StripeElement {
    padding: 4px 10px;
    height: 32px;
}
.pricelist .card-wrapper .form-item .form-text {
    width: 100%;
    height: 55px;
    padding: 0px 16px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}
.card-elements-wrapper label {
    color: #000;
    padding-bottom: 8px;
    display: block;
    margin-top: 15px;
}

.pricelist .card-wrapper .actionbutton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pricelist .card-wrapper .actionbutton button {
    margin: auto;
    padding: 8px 25px;
    height: 42px;
    border: 0px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
}
.pricelist .card-wrapper .actionbutton button[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: 0px 4px 4px rgb(217 217 217 / 25%);
}
.paymentconfirmation .ant-modal-content .ant-modal-body {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
}

.currentactive {
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #0072BB;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 4px;
    position: absolute;
    max-width: 325px;
    bottom: 20px;
}
.cancel-subscription,
.manage-payment {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}
.cancel-subscription span,
.manage-payment span {
    border-bottom: 1px solid #000;
    cursor: pointer;
}
.cancel-subscription span:hover,
.manage-payment span:hover{
    color: #fff;
    border-color: #fff;
}
.card-container input {
    color: #000;
}
.card-container .error{
    margin-bottom: 17px;
}
.csc-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top:20px;
}
.csc-wrapper> .ant-row.ant-form-item {
    flex: 1 1 30%;
    margin-right: 10px;
}
.csc-wrapper .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{
    cursor: text;
}
.c-box {
    box-shadow: 0px 0px 2px #02ccfe;
    padding: 12px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 20px;
}
.c-box-label{
    position: absolute;
    top: -8px;
    background: #fff;
    font-size: 16px;
    color: #11072F;
    line-height: 14px;
    padding: 0 10px;
}
.c-box .ant-row {
    margin-bottom: 0;
}
.ant-row.ant-form-item.phonebox {
    margin-top: 10px;
}
.ant-btn.apply-button{
    margin-top: 30px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    border: 0;
}
.couponmsg{
    font-weight: bold;
}

/**Yearly Membership Start***/
.yearlymember {
    background: #0072bb;
}
.yearlymember .cancel-msgsubscription{
    color: #fff;
}
.pricelist .yearlymember.membership h3 {
    color: #fff;
}
.pricelist .yearlymember.membership h3.active{
    background: url(../../assets/images/checkedwhite.svg) no-repeat 0% 15%;
}
.pricelist .yearlymember h4{
    color: #fff;
    position: relative;
}
.pricelist .yearlymember h4 span.discount {
    width: 70px;
    height: 70px;
    display: inline-block;
    top: -35px;
    position: absolute;
    right: 0px;
    background: url(../../assets/images/discount-star.svg) no-repeat 50% 50%;
    background-size: 120%;
}
.pricelist .yearlymember h4 span.discount::after {
    content: "Ahorra $40";
    width: 100%;
    height: auto;
    display: inline-block;
    top: 0px;
    position: absolute;
    right: 0px;
    margin: auto;
    left: 0px;
    bottom: 0px;
    /* background: url(../../assets/images/disount-save-40.svg) no-repeat 50% 50%;
    background-size: 70%; */
    color: #0072BB;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    margin-top: 24px;
    font-weight: bold;
}
.popular-tag {
    display: inline-block;
    padding: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #0072BB;
    background: #fff;
    position: absolute;
    text-transform: uppercase;
    transform: rotate(-45deg);
    height: 18px;
    width: 110px;
    text-align: center;
    left: -24px;
    margin-top: -12px;
}

.pricelist .yearlymember ul {
    list-style: none;
}

.pricelist .yearlymember ul li {
    padding: 0px 0px 0px 25px;
    margin: 0px 0px 18px;
    list-style: none;
    list-style-image: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #fff;
    background: url(../../assets/images/white-dot.svg) no-repeat 0% 50%;
    background-size: 10px;
}

.pricelist .membership .price .ant-radio-group label {
    margin-right: 0px;
    display: flex;
}
.pricelist .membership.yearlymember .price .ant-radio-group .ant-radio-inner {
    border-color: #fff;
}
.pricelist .membership.yearlymember .price .ant-radio-checked .ant-radio-inner::after {
    background-color: #fff;
}
.pricelist .membership.yearlymember .price .ant-radio-group label span.ant-radio + * {
    color: #fff;
}
.pricelist .membership.yearlymember .listpricevalue {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #E8E8E8;
    text-decoration: line-through;
    margin-top: -20px;
    margin-bottom: 5px;
    text-align: center;
}
.pricelist .membership.yearlymember .offerpricevalue {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.pricelist .membership.yearlymember button.formsubmit {
    color: #0072BB;
    background: #fff;
}

@media only screen and (min-width: 768px) {
.fl-box{
    display: flex;
}
.fl-box .ant-row.ant-form-item:first-child {
    margin-right: 10px;
}
.fl-box .ant-row.ant-form-item {
    flex: 1;
}
}
/**Yearly Membership End***/
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .pricing {
        padding: 20px;
    }
    .pricing .subheading {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .maincontent.pricing h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .pricelist .membershipbox {
        flex-wrap: wrap;
    }
    .pricelist .membership {
        flex: 1 1 100%;
    }
    .pricelist .freemember {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .pricelist .membership .price .ant-form-item .pricevalue {
        font-size: 20px;
        line-height: 22px;
    }
    .pricelist .membership h3,
    .pricelist .membership .price .ant-radio-group label span.ant-radio + * {
        font-size: 16px;
    }
    .pricelist .membershipbox .membership.yearlymember .listpricevalue {
        font-size: 18px;
        line-height: 18px;
    }
    .pricelist .membershipbox .membership.yearlymember .offerpricevalue {
        font-size: 20px;
        line-height: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
    .pricelist .membership .price .ant-form-item .pricevalue {
        font-size: 20px;
        line-height: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .pricing {
        padding: 20px;
    }
    .pricelist .membershipbox .membership.yearlymember .listpricevalue {
        font-size: 18px;
        line-height: 18px;
    }
    .pricelist .membershipbox .membership.yearlymember .offerpricevalue {
        font-size: 20px;
        line-height: 20px;
    }
}

/** Single page subscription styling**/
.hidemensualpage,
.hideanualpage,
.hideentrenamientopage{
    display: none;
}
.anualpage,
.mensualpage,
.entrenamientopage{
    margin-left: auto;
    margin-right: auto;
}