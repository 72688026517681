.booksviewblock .viewcontent .viewrow {
    display: flex;
}

.booksviewblock .viewcontent .viewrow .imagebox {
    width: 108px;
    height: 154px;
    margin-right: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
    border-radius: 4px;
}

.booksviewblock .viewcontent .viewrow .imagebox img {
    width: 108px;
    height: 154px;
    object-fit: cover;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
    border-radius: 4px;
}

.booksviewblock .viewcontent .viewrow .bookscontent {
    flex: 1;
}

.booksviewblock .viewcontent .viewrow .bookscontent .title h2 {
    margin-bottom: 4px;
}

.booksviewblock .viewcontent .viewrow .bookscontent .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2B2B39;
}

.booksviewblock .viewcontent .viewrow .bookscontent .description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    margin-top: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .booksviewblock .viewcontent .viewrow {
        flex-wrap: wrap;
    }
    .booksviewblock .viewcontent .viewrow .imagebox,
    .booksviewblock .viewcontent .viewrow .imagebox img {
        width: 100%;
        height: 154px;
        margin-right: 0px;
    }
    .booksviewblock .viewcontent .viewrow .bookscontent .description {
        font-size: 14px;
        line-height: 22px;
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
       text-overflow: ellipsis;
        max-width: 120px;
    }
    .booksviewblock .viewcontent .viewrow .bookscontent {
        margin-top: 10px;
        width: 100%;
    }
}