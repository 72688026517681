.loaninterestedblock .ant-card-head{
    background: #0072BB;
    color: #fff;
}
.loaninterestedblock .ant-table-thead{
    text-transform: capitalize;
}
.loaninterestedblock .ant-card-body{
    padding: 0;
}
.loaninterestedblock .request-btn{
    background: #fff;
    padding: 10px;
    font-weight: bold;
    border-radius: 7px;
}
.loaninterestedblock .ant-card-head {
    background: #0072BB;
    color: #fff;
}
.loaninterestedblock .ant-card-body {
    padding: 0;
}
.button-switcher button {
    height: auto;
    padding: 10px;
    font-size: 16px;
    margin: 15px;
    border-radius: 10px;
}
.button-switcher button.active{
    border-color: #40a9ff;
    background: #40a9ff;
    color: #fff;
}
.lender-profile-view  > div {display: inline-flex;width: 100%;border-bottom: 1px solid #0000001c;padding: 10px 0;}
.lender-profile-view  {padding: 30px 5%;border-radius: 20px;box-shadow: 0 11px 30px 0px #00304d21;max-width: 1100px;margin: 20px 0;}
.lender-profile-view  > div > span:first-child { width: 46%; }
.lender-profile-view  > div > span:last-child { width: 50%; font-weight: 500; letter-spacing: 0.2pt; color: #000; font-size: 16px; }
.lender-profile-view  > div:last-child { border: 0 none; }