.verifysuccessmail {
    max-width: 1440px;
    margin: auto;
    padding-left: 56px;
    padding-right: 56px;
}
.verifysuccessmail .contentbox {
    max-width: 926px;
    height: 178px;
    margin-bottom: 24px;
    background: #FBFBFB;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.verifysuccessmail .contentbox {
    max-width: 926px;
    height: 178px;
    padding: 24px;
    margin-bottom: 24px;
    background: #FBFBFB;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.verifysuccessmail .contentbox .helpus a {
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #0771EE;
    display: inline-block;
    vertical-align: middle;
    margin-top: 24px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    
    .verifysuccessmail {
        padding-left: 20px;
        padding-right: 20px;
    }
    .verifysuccessmail .contentbox {
        height: 114px;
    }
    .verifysuccessmail .contentbox .helpus a {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .verifysuccessmail {
        padding-left: 20px;
        padding-right: 20px;
    }
}