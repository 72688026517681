@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
body {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    line-height: 26px;
    font-family: 'Roboto', sans-serif;
    background: #fff;
}
.container {
    max-width: 1440px;
    margin: auto;
    padding-left: 56px;
    padding-right: 56px;
}
.maindiv {
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
}
.maincontent {
    padding-bottom: 56px;
}
.leftsidebar {
    width: 22%;
    float: left;
}
.rightsidebar {
    width: 22%;
    float: right;
}
.maindiv .midcontent {
    width: 76%;
    margin-right: 2%;
    float: left;
}
.bothsidebar .maindiv .midcontent {
    width: 49%;
    margin-left: 3.5%;
    margin-right: 3.5%;
    float: left;
}

.ant-modal-wrap {
    width: 100%;
    height: 100%;
    display: flex;
}
.ant-modal-wrap .ant-modal {
    top: auto;
    margin: auto;
    padding-bottom: 0px;
}
.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content,
.ant-modal-content .ant-modal-body {
    margin-top: 0px;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.ant-modal-content .ant-modal-body p:last-child {
    margin-bottom: 0px;
}
.ant-modal-body .ant-modal-confirm-btns {
    float:none;
    text-align: center;
}
.ant-modal-footer {
    float:none;
    text-align: center;
    padding:0px 0px 24px 0px;
    border-top: 0px;
    border-radius: 0px;
}
.ant-modal-body button.ant-btn,
.ant-modal-footer .ant-btn,
.comment-form .ant-btn {
    min-width: 130px;
    height: 40px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
}
.ant-message-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.successfully-message {
    font-size: 16px;
    line-height: 25px;
}
.successfully-message p.heading {
    font-weight: bold;
    font-size: 22px;
    color: #0072BB;
}
.successfully-message p.regards {
    font-weight: bold;
    color: #0072BB;
    font-size: 17px;
    margin-bottom: 0px;
    margin-top: 30px;
}
.successfully-message p.regards span {
    font-weight: normal;
    color: #0072BB;
    font-size: 16px;
}
.registration-form .ant-form-item-label > label::after {
    display: none;
}
.requiredsymbol {
    color: #FF0000;
}
.ant-form-item-label > label::after {
    display: none;
}
label.ant-form-item-required {
    display: inline-flex;
    flex-direction: row-reverse;
}
label.ant-form-item-required::before {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    content: '';
    position: relative;
    font-size: 0px !important;
    margin: -10px 0px 0px 5px;
    color: #FF0000;
    font-size: 0px;
    background: url(../src/assets/images/star.svg) no-repeat 50% 50%;
}
span.red{
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    content: '';
    position: relative;
    font-size: 0px !important;
    margin: -10px 0px 0px 5px;
    color: #FF0000;
    font-size: 0px;
    background: url(../src/assets/images/star.svg) no-repeat 50% 50%;
}
span.required {
    color: #FF0000;
}
.leftsidebar.hide {
    display: none;
}
.emptymessage {
    text-align:center;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
ul li .share-icon {
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
}

ul li.share .share-icon:hover {
    color: #0072BB;
}

ul li.share {
    list-style: none;
}
ul li.share .share-icon:before {
    background-image: url(assets/images/share.svg);
    width: 35px;
    height: 35px;
    background-color: #fff;
    display: inline-block;
    content: "";
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 24px;
    border-radius: 50%;
    margin-right: 7px;
}
/*******Notification ******/
div#popover-contained {
    width: 230px;
    transform: translate(-90px, 44px)!important;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .leftsidebar {
        width: 100%;
    }
    .rightsidebar {
        width: 100%;
    }
    .maindiv .midcontent {
        width: 100%;
        margin-right: 0px;
    }
    .bothsidebar .maindiv .midcontent {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
    .ant-modal-body button.ant-btn,
    .ant-modal-footer .ant-btn {
        min-width: 90px;
        font-size: 15px;
        line-height: 16px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}