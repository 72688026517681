.calculator-new {
  max-width: 800px!important;
}
.calculator-new-wrapper {
  padding: 0!important;
}
.calculator-new-wrapper h2{
  padding: 10px;
  margin-bottom: 10px;
  background: #0072BB;
  color: #ffffff!important;
  text-align: center;
}
.calculator-new-wrapper form{
  padding: 0 30px 30px;
}
.calculator-new-wrapper .ant-form-item{
  max-width: 50%;
  display: inline-block;
  margin: 12px!important;
}
.calculator-new-wrapper .ant-input-number {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 0px 2px #02ccfe;
  border-radius: 4px;
  outline: none;
}
.calculator-new-wrapper .ant-input-number .ant-input-number-handler-wrap{
  display: none;
}
.cal-output {
  background: #0072BB;
  padding: 10px;
  color: #fff;
}

.label-name {
  font-size: 16px;
}

.label-value {
  font-size: 24px;
}