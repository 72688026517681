.red-wrapper.hide-marquee{
  display: none;
}div.red-wrapper {
  padding: 10px 5px;
  overflow: hidden;
  font-size: 18px;
  color: #fff;
  background: #0072BB;
  font-family: 'Space Mono', monospace;
  position: relative;
  z-index: 999;
}
div.red-wrapper a{
  color: #fff;
}
.red-line-noti {
    -moz-animation: marquee 25s linear infinite;
    -webkit-animation: marquee 25s linear infinite;
    animation: marquee 25s linear infinite;
    color: red;
    padding: 10px 0;
  }
  @-moz-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @-webkit-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%)
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  .red-line-noti:hover {
    animation-play-state: paused;
  }