#lenderinfo label {
    color: #11072F;
}
#lenderinfo .ant-radio-group-default label.ant-radio-wrapper{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}
.l-onboarding-form-wrap{
    padding: 24px;
    float: none;
    border-radius: 10px;
    box-shadow: 2px 6px 10px rgb(0 0 0 / 50%);
    background: #FFFFFF;
    max-width: 750px;
    margin: 40px auto;
}
.l-onboarding-form-wrap .l-onboarding-form .form-text {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}

.l-onboarding-form-wrap .l-onboarding-form .form-action {
    margin-top: 36px;
}
.l-onboarding-form-wrap .l-onboarding-form .form-action .form-submit {
    min-width: 180px;
    height: 40px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
}
.loading-wrapper{
    max-width: 250px;
    margin: 40px auto;
}
.loading-wrapper img {
    width: 100%;
}
.l-onboarding-form-wrap { box-shadow: 0px 10px 35px rgb(0 0 0 / 12%); padding: 24px 2.5%; }
.l-onboarding-form-wrap .helptext { margin: 10px 0 20px; }
#lenderinfo .ant-col.ant-form-item-label label { font-size: 16px; }
.l-onboarding-form-wrap .l-onboarding-form .form-text { box-shadow: none; border: 1px solid #56B2C9; height: 42px; }
.l-onboarding-form-wrap .ant-form-vertical .ant-form-item { margin: 0 0 18px; }
#lenderinfo .ant-col.ant-form-item-label { margin: 0; padding: 0 0 5px; }
.l-onboarding-form-wrap .ant-form-vertical .ant-row.ant-form-item.form-item { max-width: 48%; width: 100%; }
.l-onboarding-form-wrap form#lenderinfo { display: flex; flex-wrap: wrap; justify-content: space-between; }
.l-onboarding-form-wrap .ant-row.ant-form-item { width: 100%; max-width: 100%; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group { width: 100%; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group > .ant-space { display: flex; flex-direction: row; flex-wrap: wrap; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group > .ant-space .ant-space-item { width: 33.33%; }
#lenderinfo .ant-radio-group-default label.ant-radio-wrapper { white-space: normal; font-size: 12px; font-weight: 400; color: #000; position: relative; padding: 0 0 0 27px; }
#lenderinfo .ant-radio-group-default label.ant-radio-wrapper span.ant-radio {color: #000;position: absolute;left: 0;top: 1px;}
#lenderinfo .ant-radio-group-default label.ant-radio-wrapper .ant-radio + span { padding-left: 0; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group > .ant-space .ant-space-item:first-child { width: 100%; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group > .ant-space .ant-space-item label:empty { display: none; }
.l-onboarding-form-wrap .ant-row.ant-form-item .ant-radio-group > .ant-space .ant-space-item:first-child label { margin: 10px 0 10px; display: block; }

form#lenderinfo .ant-checkbox-group { display: flex; flex-wrap: wrap; }
form#lenderinfo .ant-checkbox-group > label { width: 32.13%; font-size: 13px; padding: 7px 0; }
form#lenderinfo .checkall-wrapper {max-width: 50%; text-align: center; font-weight: bold;}
@media only screen and (min-width: 0px) and (max-width: 768px) {
    .l-onboarding-form-wrap form#lenderinfo{
        max-width: 100%;
        margin: 20px;
        display: block;
    }
    .l-onboarding-form-wrap .ant-form-vertical .ant-row.ant-form-item.form-item{
        max-width: 100%;
    }
    form#lenderinfo .ant-checkbox-group > label{
        width: calc(50% - 8px);
    }
}