.loanrequestedblock .ant-card-head{
    background: #0072BB;
    color: #fff;
}
.loanrequestedblock .ant-table-thead{
    text-transform: capitalize;
}
.loanrequestedblock .ant-card-body{
    padding: 0;
}
.loanrequestedblock .request-btn{
    background: #fff;
    padding: 10px;
    font-weight: bold;
    border-radius: 7px;
}
.address-col{
    width: 40%;
    text-align: center;
    min-width: 200px;
}
.responsive-table .ant-table-content{
    overflow-x:auto;
}