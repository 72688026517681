body.modalopen {
  overflow: hidden;
}

.registrationformwrap .registrationform form {
  margin: 0px;
}

.registrationformwrap .registrationform h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #11072F;
  margin: 0px;
}
.registrationformwrap .registrationform .helptext {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  margin: 8px 0px 10px;
}

.registrationformwrap .registrationform .formitem {
  width: 100%;
  margin-bottom: 21px;
}

.registrationformwrap .registrationform .formtext {
  width: 100%;
  height: 40px;
  margin-top: 4px;
  padding: 0px 16px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 0px 2px #02CCFE;
  border-radius: 4px;
  outline: none;
}

.registrationformwrap .registrationform .help {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #909090;
  margin: 8px 0px 24px;
}

.registrationformwrap .registrationform .termandcondition {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 38px;
  margin-bottom: 26px;
}

.registrationformwrap .registrationform .termandcondition .checkbox {
  display: none;
}

.registrationformwrap .registrationform .termandcondition label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #11072F;
  cursor: pointer;
  padding-left: 30px;
}

.registrationformwrap .registrationform .termandcondition label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  border: 1px solid #0072BB;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 114, 187, 0.25);
  border-radius: 2px;
}

.registrationformwrap .registrationform .termandcondition label::after {
  width: 4px;
  height: 10px;
  content: "";
  position: absolute;
  left: 7px;
  bottom: 0;
  top: -3px;
  margin: auto;
  display: none;
  transition: all .5s ease;
  border-bottom: 2px solid #0072BB;
  border-right: 2px solid #0072BB;
  transform: rotate(45deg);
}

.registrationformwrap .registrationform .termandcondition .checkbox:checked + label::after {
  display: block;
}

.registrationformwrap .registrationform .formaction {
  text-align: center;
}

.registrationformwrap .registrationform .formaction .formsubmit {
  width: 180px;
  height: 40px;
  border: 0px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  color: #FFFFFF;
  background: #0072BB;
  box-shadow: 0px 4px 4px rgba(0, 114, 187, 0.25);
  border-radius: 4px;
  transition: all 0.5s ease;
}
.registrationformwrap .registrationform .formaction .formsubmit:hover {
  opacity: 0.8;
}

.registrationformwrap .registrationform .formdivider {
  border-top: 1px solid #909090;
  text-align: center;
  max-width: 360px;
  margin: 43px auto;
}

.registrationformwrap .registrationform .formdivider span {
  display: inline-block;
  vertical-align: middle;
  background: #FAFAFA;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -26px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #11072F;
  font-family: 'Roboto', sans-serif;
}
.registrationpopup .registrationformwrap {
  padding: 24px;
  overflow: auto;
}
.registrationpopup .registrationformwrap label.ant-checkbox-wrapper {
  display: flex;
}
.registrationpopup .registrationformwrap label.ant-checkbox-wrapper span.ant-checkbox {
  padding-top: 4px;
}
.registrationpopup .registrationformwrap label.ant-checkbox-wrapper .ant-checkbox-checked::after {
  top: 5px;
  width: 100%;
  height: auto;
}
.registrationformwrap .registrationform .socialauthlogin {
  text-align: center;
  max-width: 330px;
  margin: auto;
}
.registrationformwrap .registrationform .socialauthlogin .google {
  width: 100%;
  margin: 0px;
}
.registrationformwrap .registrationform .socialauthlogin a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #11072F;
  border: 1px solid #0072BB;
  border-radius: 4px;
  padding: 0px;
  width: 100% !important;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D5E8F4;
  text-decoration: none;
}
.registrationformwrap .registrationform .socialauthlogin .google a {
  margin-bottom: 24px;
}
.registrationformwrap .registrationform .socialauthlogin a::before {
  content: "";
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 20px;
}

.registrationformwrap .registrationform .socialauthlogin .google a::before {
  background: url(../../assets/images/googleplus.svg) no-repeat 0% 0%;
  background-size: 16px;
}
.registrationformwrap .registrationform .socialauthlogin .facebook a::before {
  background: url(../../assets/images/facebook-auth.svg) no-repeat 0% 0%;
}

.registrationformwrap .registrationform .alreadyaccount {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #11072F;
  text-align: center;
  margin-top: 24px;
}
.registrationformwrap .registrationform .alreadyaccount a {
  text-decoration-line: underline;
  color: #0072BB;
}
.ant-modal-mask {
  background-color: rgba(196, 196, 196, 0.5);
}
.registrationpopup .ant-modal {
  width: 100% !important;
  max-width: 632px;
  padding: 0px 20px;
}

.registrationpopup .ant-modal-content {
  border-radius: 5px;
}
.registrationpopup .ant-modal-body {
  width: 100%;
  padding: 24px;
  overflow: auto;
  margin-top: 60px !important;
  background: #FCFCFC;
  border-radius: 6px;
  position: relative;
}
.registrationpopup .registrationform {
  width: 100%;
}
.registrationpopup .ant-modal-close {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: 24px;
  top: 29px;
  border-radius: 36px;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
  background: url(../../assets/images/popupclose.svg) no-repeat 50% 50%;
}
.registrationpopup .ant-modal-close .ant-modal-close-x {
  display: none;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .registrationformwrap {
      width: 100%;
  }
  .registrationformwrap .registrationform {
      float: none;
      max-width: 100%;
      margin: auto;
  }
  .registrationformwrap .registrationform h2 {
      font-size: 20px;
  }
  .registrationpopup .ant-modal-body {
    margin-top: 70px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .registrationpopup .ant-modal-body {
    margin-top: 80px !important;
  }
}