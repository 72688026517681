.bookdetailwrapper {
    display: flex;
    max-width: 1200px;
    padding: 0px 20px;
    margin: auto;
}
.bookdetailpage {
    flex: 1;
    padding: 0px 0px 56px 0px
}
.bookdetail {
    max-width: 924px;
    margin: auto;
}

.bookdetail .bookdetailcontent {
    display: flex;
}

.bookdetail .bookdetailcontent .imagebox {
    max-width: 400px;
    flex: 1;
    border-radius: 6px;
    margin-right: 24px;
}

.bookdetail .bookdetailcontent .contentbox {
    flex: 1;
}

.bookdetail .bookdetailcontent .imagebox img {
    width: 100%;
    height: auto;
    border-radius: 6px;
}
.bookdetail .bookdetailcontent .contentbox .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #11072F;
}

.bookdetail .bookdetailcontent .contentbox .auther {
    padding: 16px 0px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #11072F;
    border-bottom: 1px solid #C5C5C5;
}

.bookdetail .bookdetailcontent .contentbox .category {
    padding: 16px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #11072F;
    border-bottom: 1px solid #C5C5C5;
}

.bookdetail .bookdetailcontent .contentbox .description {
    padding: 16px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
}

.downloadbutton {
    width: 100%;
    padding: 24px 0px;
    text-align: right;
    border-bottom: 1px solid #C5C5C5;
}

.downloadbutton a {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #0072BB;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
    
    .bookdetailwrapper {
        flex-wrap: wrap;
    }

    .bookdetailpage {
        padding: 20px 20px 56px 20px;
    }
    .bookdetail .bookdetailcontent {
        flex-wrap: wrap;
    }
    .bookdetail {
        max-width: 100%;
        margin: auto;
    }
    .bookdetail .bookdetailcontent .imagebox {
        max-width: 100%;
        flex: 1 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .bookdetail .bookdetailcontent .contentbox {
        flex: 1 100%;
    }
    .bookdetail .bookdetailcontent .contentbox .title {
        font-size: 24px;
        line-height: 30px;
    }
    .bookdetail .bookdetailcontent .contentbox .auther {
        font-size: 18px;
        line-height: 25px;
    }
    .bookdetail .bookdetailcontent .contentbox .category {
        font-size: 15px;
        line-height: 22px;
    }
    .bookdetail .bookdetailcontent .contentbox .description {
        font-size: 15px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .bookdetailpage {
        padding: 0px 20px 56px 20px;
    }
    
    .bookdetail .bookdetailcontent .contentbox .title {
        font-size: 28px;
        line-height: 34px;
    }
    .bookdetail .bookdetailcontent .contentbox .auther {
        font-size: 20px;
        line-height: 26px;
    }
    .bookdetail .bookdetailcontent .contentbox .category {
        font-size: 15px;
        line-height: 22px;
    }
    .bookdetail .bookdetailcontent .contentbox .description {
        font-size: 15px;
    }
}