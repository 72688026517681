header {
  width: 100%;
  background-color:#ffffff;
  position: fixed;
  z-index: 20;
  box-shadow: 0px 1px 2px 1px #8395a0;
}
header.landing {
  display: none;
}
header#comingsoonheader {
  display: block;
}

/* header:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  background: #fff;
  width: 50%;
  height: 100px;
} */
header .headerarea {
  /* max-width: 1440px; */
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0px 56px;
  position: relative;
  z-index: 10;
  background: #ffffff;
}
/* header .headerarea:before {
  width: 56px;
  height: 100%;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  background: #fff;
} */
header .headerarea .logoarea {
  background: #fff;
  min-width: 136px;
  text-align: left;
  position: relative;
  z-index: 10;
}
header .headerarea .logoarea a {
  outline: none;
}
header .headerarea .logoarea img {
  height: 120px;
  width: 120px;
}

header .headerarea .headerright {
  flex: 1;
  display: flex;
  align-items: center;
}
/* .menuarea {
  display: none;
} */
header .headerarea .menuarea {
  flex: 1;
  display: block;
  text-align: center;
}

header .headerarea ul,
header .headerarea ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
  list-style-image: none;
  flex: 1 1 auto;
}
header .headerarea ul li:hover{
  border-bottom: none!important;
}

header .headerarea ul.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  max-width: 1150px;
  margin: auto;
}
header.headerwrap{
  z-index: 1000;
}
header.landing{
 
}
header.landing .useraccountmenu,
header.landing .signuparea,
header.landing .menuarea,
header.landing .mobilemenubutton{
  display: none;
}

header .headerarea .menuarea ul.menu li a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #0072BB;
  text-decoration: none;
  margin: 0px 7px;
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}
header .headerarea .menuarea ul.menu li a:hover,
header .headerarea .menuarea ul.menu li a.active {
  border-color: #FFFFFF;
}

header .headerarea .signuparea ul.menu li a {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #0072BB;
  background: #fff;
  padding: 10px 32px;
  border-radius: 4px;
  margin-left: 24px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.5s ease;
  border: 1px solid #0072BB;
  box-shadow: 0px 1px 2px 1px #8395a0;
}

header .headerarea .signuparea ul.menu li.signup a {
  margin-left: 0px;
}

header .headerarea .signuparea ul.menu li a:hover {
  background: #0072BB;
  color: #fff;
}
.useraccountmenu .useraccount {
  display: flex;
  align-items: center;
}

.useraccountmenu .useraccount {
  display: flex;
  align-items: center;
}

.useraccountmenu .useraccount .userinfo .user {
  display: flex;
  align-items: center;
  padding: 5px 18px 5px 8px;
  background: #FFFFFF;
  border-radius: 30px;
}

.useraccountmenu .useraccount .userinfo .user .userpic {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  border-radius: 360px;
  position: relative;
}

.useraccountmenu .useraccount .userinfo .user .userpic img {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  border-radius: 360px;
  object-fit: cover;
}
img.img.pro {
  border: 2px solid #FFD700;
}
.pro-icon {
  width: 20px;
  height: 20px;
  border: 2px solid #FFD700;
  border-radius: 360px;
  position: absolute;
  bottom: -4px;
  right: 0;
  background: url('../../assets/images/proicon.png') no-repeat center;
  background-size: 20px;
  padding: 10px;
}
.useraccountmenu .useraccount .userinfo .user .userpic .pro-icon{
  width: 14px;
  height: 14px;
  background-size: 14px;
  bottom: -2px;
  right: -2px;
  padding: 5px;
}
.useraccountmenu .useraccount .userinfo .user .username {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0072BB;
  text-transform: capitalize;
}

.useraccountmenu .useraccount .icon {
  width: 40px;
  height: 40px;
  display: block;
  margin-left: 24px;
  border-radius: 360px;
  background: #FFFFFF;
}

.useraccountmenu .useraccount .icon a {
  width: 100%;
  height: 100%;
  font-size: 0px;
  display: block;
  cursor: pointer;
}
.useraccountmenu .useraccount .message.icon a {
  position: relative;
  background: url(../../assets/images/message.svg) no-repeat 50% 50%;
}
.useraccountmenu .useraccount .message.icon a::after {
  width: 14px;
  height: 8px;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: absolute;
  background: url(../../assets/images/message-dot.svg) no-repeat 50% 50%;
}

.useraccountmenu .useraccount .notification.icon a {
  background: url(../../assets/images/alert-notification.svg) no-repeat 50% 45%;
}

.useraccountmenu .useraccount span.icon {
  font-size: 0px;
  display: block;
  cursor: pointer;
  background:#FFFFFF url(../../assets/images/account-menu.svg) no-repeat 50% 45%;
}
.useraccountmenu .useraccount span.icon.active {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.useraccountmenu .useraccount li {
  position: relative;
}
.ant-popover.myaccountoverlay {
  position: fixed !important;
  top: 60px !important;
  right: 20px;
  left: auto !important;
  width: 360px;
}
.myaccountoverlay li{
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-image: none;
}
.useraccountmenu .useraccount .dropdownmenu{
  width: 218px;
  padding: 6px 16px;
  position: absolute;
  right: 0px;
  top: auto;
  margin-top: 14px;
  background: #fdfdfd;
  border-radius: 6px;
  z-index: 1;
  box-shadow: 0 0px 7px 0 #ccc, 0 2px 4px 0 #ccc, inset 0 0 0 1px #ccc;
}
.myaccountoverlay {
  width: 218px!important;
}
.ant-popover.myaccountoverlay .ant-popover-content {
  padding: 20px 10px;
  background: #fff;
  max-height: calc(100vh - 80px);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 10px !important;
  overflow: auto;
}

.ant-popover.mainmenuoverlay .ant-popover-content .ant-popover-inner ,
.ant-popover.myaccountoverlay .ant-popover-content .ant-popover-inner {
  background: none;
  box-shadow: none;
}
.ant-popover.mainmenuoverlay .ant-popover-content .ant-popover-arrow,
.ant-popover.myaccountoverlay .ant-popover-content .ant-popover-arrow {
  display: none;
}
.ant-popover.mainmenuoverlay .ant-popover-content .ant-popover-inner-content,
.ant-popover.myaccountoverlay .ant-popover-content .ant-popover-inner-content {
  padding: 0px;
  color: #1c1e21;
}
.myaccountoverlay .ant-popover-content{
  padding: 0;
}
.myaccountoverlay .dropdownmenu{
  margin: 0;
  border-radius: 6px;
  padding: 6px 16px;
}
.myaccountoverlay .dropdownmenu:before {
  content: "";
  position: absolute;
  top: -17px;
  right: 14px;
  border-width: 7px 5px 10px 5px;
  border-style: solid;
  border-color: transparent transparent #fdfdfd transparent;
}

.myaccount .icon:hover .dropdownmenu{
  display: block;
}

.useraccountmenu .useraccount .dropdownmenu:before {
  content: "";
  position: absolute;
  top: -17px;
  right: 14px;
  border-width: 7px 5px 10px 5px;
  border-style: solid;
  border-color: transparent transparent #fdfdfd transparent;
}

.useraccountmenu .useraccount .dropdownmenu li a,
.myaccountoverlay .dropdownmenu li.a,
.myaccountoverlay .dropdownmenu li a{
  display: block;
  padding: 11px 8px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #2B2B39;
  text-decoration: none;
  transition: all 0.5s ease;
  border-bottom: 1px solid #CECECE;
  cursor: pointer;
}
.useraccountmenu .useraccount .dropdownmenu li:last-child a,
.myaccountoverlay .dropdownmenu li:last-child a{
  border-bottom: 0px;
}
.useraccountmenu .useraccount .dropdownmenu li a:hover,
.myaccountoverlay .dropdownmenu li.a:hover,
.myaccountoverlay .dropdownmenu li a:hover {
  color:#0072BB;
}

/* .Logo {
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 2em;
  line-height: normal;
}

.Logo span{
  display: block;
}

.HeaderMenu {
  background: transparent;
  border: none;
  color: #ffffff;
}
.HeaderMenu li a{
  color: white!important;
}

.HeaderMenuRight{
  background: transparent;
  border: none;
  color: #ffffff;
}

.HeaderMenuRight li a{
  color: white!important;
}
 */


@media only screen and (min-width: 0px) and (max-width: 767px) {
  header .headerarea {
      padding: 0px 20px;
      height: 70px;
  }
  header:before {
      width: 20px;
      height: auto;
  }
  header .headerarea .logoarea {
      min-width: auto;
      flex: 1;
      padding: 0px;
      background: none;
      text-align: left;
  }
  header .headerarea .logoarea img {
      height: 70px;
      width: 70px;
      max-width: 70px;
  }

  header .headerarea .mobilemenubutton {
      width: 40px;
      height: 40px;
      display: block !important;
      cursor: pointer;
      border-radius: 36px;
      border: 0px;
      outline: none;
      background: #FFFFFF url(../../assets/images/account-menu.svg) no-repeat 50% 45%;
  }
  header.landing .headerarea .mobilemenubutton {
    display: none !important;
  }

  header .headerarea .menuarea {
    flex: 1;
    display: none;
    text-align: center;
  }

  .mainmenuoverlay{
    max-width: 100%;
    width: 100%;
    right: 0;
  }
  header .menuarea, .mainmenuoverlay .ant-popover-content {
    display: block;
    z-index: 10;
    width: 100%;
    background: #74acdf;
    text-align: center;
    padding: 20px 0px;
    border-radius: 0!important;
  }
  header ul {
      display: block;
  }
  header .menuarea ul,
  header .menuarea ul li,
  .mainmenuoverlay .ant-popover-content ul,
  .mainmenuoverlay .ant-popover-content ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
  }
  header .menuarea ul li a,
  .mainmenuoverlay .ant-popover-content ul li a {
      font-size: 15px;
      line-height: 18px;
      margin: 0px;
      color: #FFFFFF;
      padding: 10px 0px;
      display: inline-block;
      vertical-align: middle;
  }
  .useraccountmenu .useraccount .userinfo {
      display: none;
  }

  header .headerarea .signuparea {
      text-align: center;
  }
  header .headerarea .signuparea ul.menu {
      display: flex;
  }
  header .headerarea .signuparea ul.menu li {
    margin-right: 10px;
  }
  header .headerarea .signuparea ul.menu li a {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      padding: 7px 8px;
      margin: 0px;
  }
  .useraccountmenu .useraccount .icon {
      margin-left: 0px !important;
  }
  .useraccountmenu .useraccount .myaccount span.icon {
      background: #fff url(../../assets/images/userfriend.svg) no-repeat 50% 50%;
  }
  .headerarea .useraccountmenu .useraccount .dropdownmenu {
      right: 15px;
      top: 50px;
  }
  .headerarea .useraccountmenu .useraccount .dropdownmenu li a {
      padding: 9px 8px;
      font-size: 14px;
      line-height: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  header:before {
      display: none;
  }
  header .headerarea {
      padding: 0px 20px;
      height: 80px;
  }
  header .headerarea .logoarea {
      min-width: 85px;
  }
  header .headerarea .logoarea img {
      height: 80px;
      width: 80px;
  }
  header .headerarea .menuarea ul.menu li a {
      font-size: 15px;
      margin: 0px 15px;
  }
  header .headerarea .signuparea ul.menu li a {
      font-size: 15px;
      padding: 10px 15px;
  }
  .headerarea .useraccountmenu .useraccount .icon {
      margin-left: 15px;
  }
}