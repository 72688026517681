.maincontent.networkpage.fullpage {
    padding-top: 255px;
}
.maincontent.networkpage.fullpage.forpaid {
    padding-top: 300px;
}
.no-result{
    margin: auto;
    text-align: center;
}
.networkpage .network-search-filter {
    width: 100%;
    position: fixed;
    top: 150px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background: #fff;
}
.membersearchbox {
    width: 97%;
    margin: auto;
    padding: 24px 0px 18px 0px;
    border-bottom: 1px solid #CDCDCD;
    background: #fff;
}
.membersearchbox .membersearch,
.membersearchbox .maintitle {
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
    padding: 0px 20px;
}
.membersearchbox .membersearch {
    display: flex;
    align-items: center;
    justify-content: center;
}
.membersearchbox .membersearch .searchbox {
    max-width: 650px;
    max-width: 733px;
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    align-items: center;
    margin: auto;
    background: #fff;
}
.membersearchbox .membersearch .searchbox .userpicbox{
    width: 60px;
    height: 60px;
    border-radius: 360px;
    text-align: right;
    position: relative;
}
.membersearchbox .membersearch .searchbox .userpicbox .pro-icon{
    bottom: 0px;
    right: 2px;
}
.membersearchbox .membersearch .searchbox .userpicbox img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
}
.membersearchbox .membersearch .searchbox .ant-form-item {
    margin: 0px;
}
.membersearchbox .membersearch .searchbox .ant-form-item.form-item {
    flex: 1;
}
.membersearchbox .membersearch .memberlist a {
    width: 180px;
    height: 60px;
    padding: 11px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
    color: #0072BB;
    cursor: pointer;
    text-decoration: none;
    background: #FFFFFF;
    border: 0.6px solid #0072BB;
    border-radius: 36px;
    transition: all 0.5s ease;
}
.membersearchbox .maintitle h2 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    margin-top: 18px;
    text-decoration-line: underline;
    color: #11072F;
    text-align: center;
}
.networkpage .upgrademessage {
    width: 100%;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    background: #0072BB;
    color: #fff;
}
.networkpage .rolefilter {
    max-width: 1440px;
    margin: 16px auto;
    padding: 0px 80px;
}
.networkpage .rolefilter.forpaid{
    opacity: 0.4;
}
.networkpage .rolefilter .ant-carousel .slick-list {
    margin: auto;
}
.networkpage .rolefilter .ant-carousel .slick-slide {
    margin: 0px 8px;
    pointer-events:all;
}
.networkpage .rolefilter .ant-carousel .slick-slide input {
    display: none;
}
.networkpage .rolefilter .ant-carousel .slick-slide label {
    font-size: 18px;
    line-height: 100%;
    padding: 0px 35px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #0072BB;
    cursor: pointer;
    text-align: center;
    border-radius: 360px;
    border: 1px solid #0072BB;
    background: #FBFBFB;
}
.networkpage .rolefilter.forpaid .ant-carousel .slick-slide label{
    cursor: not-allowed;
}
.networkpage .rolefilter .ant-carousel .slick-slide input:checked + label {
    color: #fff;
    background: #0072BB;
}
.networkpage .rolefilter .ant-carousel .slick-arrow {
    width: 50px;
    height: 50px;
    background: #FBFBFB;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.networkpage .rolefilter .ant-carousel .slick-arrow.slick-prev {
    left: -60px;
}
.networkpage .rolefilter .ant-carousel .slick-arrow:before {
    width: 13px;
    height: 13px;
    border-bottom: 2px solid #11072F;
    border-right: 2px solid #11072F;
}
.networkpage .maindiv {
    max-width: 1520px;
    padding: 0px 20px;
}
.networkpage .leftsidebar {
    width: 220px;
    position: relative;
}
.networkpage .networkcategory {
    width: 220px;
    left: auto;
    position: fixed;
}
.networkpage.maincontent .midcontent {
    width: 100%;
    padding-left: 245px;
    margin-right: 0px;
}
.fullpage.networkpage.maincontent .midcontent {
    padding-left: 0px;
}
.networkcategory ul,
.networkcategory ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin-bottom: 0px;
}
.networkcategory ul {
    padding: 32px 24px;
    background: #FBFBFB;
    border-radius: 10px;
    max-height: 475px;
    overflow: auto;
}

/* scrollbar */
.networkcategory ::-webkit-scrollbar {
    width: 6px;
}
.networkcategory  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
.networkcategory ::-webkit-scrollbar-thumb {
    background: #8b8888; 
    border-radius: 10px;
}
.networkcategory ::-webkit-scrollbar-thumb:hover {
    background: #C4C4C4; 
}
/* scrollbar */
.networkcategory ul li a {
    padding: 11px 10px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #0072BB;
    cursor: pointer;
    margin-bottom: 24px;
    text-decoration: none;
    background: #FFFFFF;
    border: 0.6px solid #0072BB;
    border-radius: 36px;
    text-align: center;
    transition: all 0.5s ease;
}
.networkcategory ul li:last-child a {
    margin-bottom: 0px;
}
.networkcategory ul li.active a,
.networkcategory ul li a:hover {
    background: #0072BB;
    color: #FFFFFF;
}
.ourteamlist .viewheader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
}
.ourteamlist .viewheader .viewall {
    flex: 1;
}
.ourteamlist .viewheader .viewall a {
    text-decoration: underline;
}
.ourteamlist .viewheader .resultshow {
    font-size: 16px;
    line-height: 18px;
    color: #909090;
    margin-right: 16px;
}
.ourteamlist .viewheader .pagercontrol {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ourteamlist .viewheader .pagercontrol .prev,
.ourteamlist .viewheader .pagercontrol .next {
    width: 40px;
    height: 40px;
    font-size: 0px;
    display: block;
    text-align: center;
    border-radius: 360px;
    background: #FBFBFB;
    position: relative;
    cursor: pointer;
}

.ourteamlist .viewheader .pagercontrol .prev {
    margin-right: 20px;
}

.ourteamlist .viewheader .pagercontrol .next:before,
.ourteamlist .viewheader .pagercontrol .prev:before {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transform: rotate(-45deg);
    border-bottom: 2px solid #0072BB;
    border-right: 2px solid #0072BB;
}

.ourteamlist .viewheader .pagercontrol .next::after,
.ourteamlist .viewheader .pagercontrol .prev::after {
    width: 10px;
    height: 2px;
    content: "";
    position: absolute;
    left: 0;
    right: 3px;
    bottom: 0;
    top: 0;
    margin: auto;
    background: #0072BB;
}

.ourteamlist .viewheader .pagercontrol .prev:before {
    transform: rotate(135deg);
}
.ourteamlist .viewheader .pagercontrol .prev::after {
    right: 0px;
}
.maincontent.networkpage.fullpage .ourteamlist {
    max-width: 935px;
    margin: auto;
}
.maincontent.networkpage.network-both-sidebar .midcontent .ourteamlist {
    margin-right: 24%;
}
.maincontent.networkpage.network-both-sidebar .chatblock {
    top: 320px;
    position:absolute;
}
.maincontent.networkpage.forpaid.network-both-sidebar .chatblock{
    top: 412px;
}
.maincontent.networkpage.network-both-sidebar .chatblock .friendchatlist {
  height: calc(100vh - 420px);
}

.ourteamlist .viewcontent {
    display: flex;
    flex-wrap: wrap;
}
.ourteamlist .viewrow {
    display: flex;
    flex: 1 31.65%;
    max-width: 295px;
    margin: 0px 0.75% 1.5%;
    flex-grow: 0;
    padding: 16px;
    background: #ECECEC;
    border-radius: 10px;
}
.ourteamlist .viewrow .userinfomation {
    flex: 1;
    display: flex;
    align-items: center;
}
.ourteamlist .viewrow .userinfomation .userpic {
    margin-right: 12px;
}
.ourteamlist .viewrow .userinfomation .username {
    flex: 1;
}
.ourteamlist .viewrow .userinfomation .userpic, 
.ourteamlist .viewrow .userinfomation .userpic img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
    position: relative;
}
.ourteamlist .viewrow .userinfomation .title h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #11072F;
    margin-bottom: 0px;
    text-decoration: none;
    word-break: break-word;
}
.ourteamlist .viewrow .userinfomation .title h2 a {
    color: #11072F;
    text-transform: capitalize;
}
.ourteamlist .viewrow .userinfomation .category {
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #808080;
}
.ourteamlist .viewrow .userinfomation .userconnect {
    position: relative;
    text-align: right;
    display: flex;
}
.ourteamlist .viewrow .userinfomation .userconnect a {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    line-height: 16px;
    color: #0072BB;
    text-align: center;
}
.message-icon-wrapper button, .message-icon-wrapper a {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0px 5px;
    outline: none!important;
    line-height: unset;
    position: relative;
    border-radius: 36px;
    box-shadow: none;
    transition: none;
    font-size: 0!important;
    border: 1px solid transparent;
    background: url(../../assets/images/message.svg) no-repeat 50% 50%;
}
.message-icon-wrapper button:hover, .message-icon-wrapper a:hover{
    background: url(../../assets/images/message.svg) no-repeat 50% 50%;
    border: 1px solid #0072BB;
}
.message-icon-wrapper button::after, .message-icon-wrapper a::after {
    width: 14px;
    height: 8px;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
    background: url(../../assets/images/message-dot.svg) no-repeat 50% 50%;
  }
.userconnect .message-icon{
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0px 10px 0px 0px;
    outline: none!important;
    line-height: unset;
    position: relative;
    border-radius: 36px;
    box-shadow: none;
    transition: none;
    background:#fff url(../../assets/images/message.svg) no-repeat 50% 50%;
    border: 1px solid transparent;
  }
  .userconnect .message-icon::after {
    width: 14px;
    height: 8px;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
    background: url(../../assets/images/message-dot.svg) no-repeat 50% 50%;
  }
.userconnect .connect-icon {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    border-radius: 360px;
    background: #fff url(../../assets/images/send.png) no-repeat 50% 50%;
    font-size: 0;
    box-shadow: none;
    border:none;
    border: 1px solid transparent;
}
.userconnect .connect-icon.sent{
    background: #fff url(../../assets/images/sent.png) no-repeat 50% 50%;
}
.userconnect .connect-icon.received{
    background: #fff url(../../assets/images/accept.png) no-repeat 50% 50%;
}
.userconnect .connect-icon.connected{
    background: #fff url(../../assets/images/connected.png) no-repeat 50% 50%;
}
.userconnect .message-icon:hover,
.userconnect .connect-icon:hover{
    cursor: pointer;
    border: 1px solid #0072BB;
}
.userconnect .connect-icon.connected:hover{
    cursor: default;
    border:none;
}
.ourteamlist .viewrow .userinfomation .category ul,
.ourteamlist .viewrow .userinfomation .category ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.ourteamlist .viewrow .userinfomation .category ul li {
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    line-height: 13px;
    color: #808080;
    margin-bottom: 6px;
}
.ourteamlist .viewrow .location {
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    line-height: 13px;
    color: #808080;
    margin-top: 16px;
}
.showmodalonname{
    cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .maincontent.networkpage.fullpage, .maincontent.networkpage.fullpage.forpaid {
        padding-top: 0px;
        padding-bottom: 20px;
    }
    .networkpage .network-search-filter {
        position: relative;
        top: 0px;
        z-index: auto;
        padding: 0px 0px 18px 0px;
    }
    .membersearchbox .membersearch {
        flex-wrap: wrap;
    }
    .membersearchbox .membersearch .searchbox {
        max-width: 100%;
        padding: 0px;
        gap: 0px;
    }
    .membersearchbox .membersearch .searchbox .userpicbox {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .membersearchbox .membersearch .searchbox .userpicbox span.lazy-load-image-background {
        width: 100%!important;
        height: auto!important;
    }
    .membersearchbox .membersearch .searchbox .userpicbox img {
        width: 40px;
        height: 40px;
    }
    .membersearchbox .membersearch .searchbox .formbox .ant-select-selection-placeholder {
        white-space: normal;
        padding-top: 4px;
    }
    .membersearchbox .membersearch .searchbox .formbox .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
        padding: 0px;
    }
    .membersearchbox .membersearch .searchbox .formbox {
        margin: 0px;
    }
    .membersearchbox .membersearch .searchbox .formtext,
    .membersearchbox .membersearch .searchbox .form-action .form-submit {
        height: 40px;
    }
    .membersearchbox .membersearch .memberlist {
        flex:1;
    }
    .membersearchbox .membersearch .memberlist a {
        width: 100%;
        height: 50px;
        padding: 7px 10px;
        font-size: 15px;
        line-height: 19px;
    }
    .membersearchbox .maintitle h2 {
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 21px;
        line-height: 30px;
        padding-bottom: 0px;
    }
    .networkpage .rolefilter {
        padding: 0px 50px;
    }
    .networkpage .rolefilter .ant-carousel .slick-slide label {
        height: 34px;
        font-size: 15px;
        line-height: 17px;
        padding: 0px 18px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow {
        width: 35px;
        height: 35px;
    }
    .networkpage .rolefilter .ant-carousel .slick-list .slick-slide>div {
        margin: 0px 0px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow.slick-prev {
        left: -40px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow:before {
        width: 8px;
        height: 8px;
    }
    .networkpage .leftsidebar {
        width: 100%;
    }
    .networkpage .networkcategory {
        width: 100%;
        max-width: 100%;
        position: relative;
        padding-left: 0px;
    }
    .networkcategory ul {
        margin: 20px 0px;
        max-height: 300px;
    }
    .networkpage.maincontent .midcontent {
        width: 100%;
        padding-left: 0px;
        margin-right: 0px;
        padding-right: 0px;
    }
    .ourteamlist .viewrow {
        flex: 1 1 100%;
        max-width: 100%;
        margin-right: 0%;
        margin-left: 0%;
    }
    .ourteamlist .viewrow .userinfomation .title h2 {
        font-size: 15px;
    }
    .maincontent.networkpage.network-both-sidebar .midcontent .ourteamlist {
        margin-right: auto;
    }
    .maincontent.networkpage.network-both-sidebar .chatblock,
    .maincontent.networkpage.forpaid.network-both-sidebar .chatblock {
        top: 0px;
    }
    .maincontent.networkpage.network-both-sidebar .chatblock .friendchatlist {
      height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
    .ourteamlist .viewrow {
        flex: 1 1 48%;
        max-width: 48%;
    }
}

@media only screen and (min-width: 980px) and (max-width: 1279px) {
    .ourteamlist .viewrow {
        flex: 1 1 31%;
        max-width: 31%;
        flex-grow: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

    .maincontent.networkpage.fullpage {
        padding-top: 180px;
    }
    .maincontent.networkpage.fullpage.forpaid{
        padding-top: 220px;
    }
    .networkpage .network-search-filter {
        top: 75px;
    }
    
    .membersearchbox .membersearch .searchbox .formtext,
    .membersearchbox .membersearch .searchbox .form-action .form-submit {
        height: 45px;
    }
    .membersearchbox .membersearch .memberlist a {
        height: 40px;
    }
    .membersearchbox .maintitle h2 {
        font-size: 24px;
        line-height: 32px;
    }
    .networkpage .rolefilter {
        padding: 0px 65px;
    }
    .networkpage .rolefilter .ant-carousel .slick-slide label {
        height: 40px;
        font-size: 15px;
        line-height: 17px;
        padding: 0px 22px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow {
        width: 40px;
        height: 40px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow:before {
        width: 10px;
        height: 10px;
    }
    .networkpage .rolefilter .ant-carousel .slick-list .slick-slide>div {
        margin: 0px 0px;
    }
    .networkpage .rolefilter .ant-carousel .slick-arrow.slick-prev {
        left: -50px;
    }
    .networkpage .maindiv {
        margin-top: 20px;
    }
    .networkcategory ul {
        max-height: 250px;
    }
    .networkcategory ul li a {
        margin-bottom: 15px;
    }
    .ourteamlist .viewrow .userinfomation .title h2 {
        font-size: 15px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 60px;
        }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
        padding-top: 15px;
        height: 40px;
        line-height: 8px;
        
        }
}
@media only screen and (min-width: 1280px) and (max-width: 1350px) {
    .networkcategory ul {
        max-height: 360px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 60px;
        }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
        padding-top: 25px;
        height: 40px;
        line-height: 8px;
        
        }
}

@media only screen and (min-width: 1351px) and (max-width: 1600px) {
    .networkcategory ul {
        max-height: 430px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 60px;
        }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
        padding-top: 25px;
        height: 40px;
        line-height: 8px;
        
        }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 60px;
        }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
        padding-top: 25px;
        height: 40px;
        line-height: 8px;
        
        }
    .networkcategory ul {
        max-height: 460px;
    }
}

@media only screen and (min-width: 768px){
    .maincontent.networkpage.network-both-sidebar .midcontent .ourteamlist {
        margin-right: 24%;
        min-height: 500px;
    }
}
