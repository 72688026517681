.contactus{
    padding-bottom: 56px;
}
.contactus .viewcontactuscontent {
    max-width: 1440px;
    margin: auto;
    padding: 46px 56px 5px;
    position: relative;
    z-index: 1;
    background: url(../../../assets/images/curvelinecontact.svg) no-repeat 192px 0%;
    background-size: 100%;
}

.contactus .contactustitle {
    text-align: center;
    color: #fff;
    font-size: 46px;
    line-height: 55px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 0;
}
.ant-form-item-explain {
    text-align: left;
}
.office-hours {
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.office-hours a{
    color: #fff;
    border-bottom: 1px solid #fff;
}
@media only screen and (min-width: 1240px) {
    .contactus .contactustitle {
        font-size: 56px;
        line-height: 83px;
    }
}
.contactus .contactuscard {
    margin: auto;
    max-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    text-align: center;
    display: block;
    padding: 24px 36px;
    background: #fff;
    position: relative;
    z-index: 10;
}

.contactus .viewcontactuscontent::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    bottom: -40%;
    left: 0px;
    background: url(../../../assets/images/dottedline.svg) no-repeat 0% 0%;
    background-size: 100% 100%;
}

.contactus .viewcontactuscontent::after {
    width: 100px;
    height: 100px;
    content: "";
    position: absolute;
    bottom: 30px;
    left: -400px;
    background: url(../../../assets/images/Polygon45.svg) no-repeat 0% 0%;
    background-size: 100%;
    right: 0px;
    margin: auto;
}
.contactus .contactuscard::before {
    width: 45px;
    height: 45px;
    content: "";
    position: absolute;
    bottom: 0px;
    left: -233px;
    top: 57%;
    margin: auto;
    background: url(../../../assets/images/arrowsymbol.svg) no-repeat 0% 0%;
}
.contactus .contactuscard::after {
    width: 50px;
    height: 36px;
    content: "";
    position: absolute;
    bottom: 0px;
    top: 25px;
    margin: auto;
    background: url(../../../assets/images/mailbox.svg) no-repeat 0% 0%;
    right: -76%;
}

.contactus .contactuscard form {
    margin: 0px;
    
}
.contactus .contactuscard label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #11072F;
    display: block;
    text-align: left;
    margin-bottom: 4px;
}

.inputbox {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}
.textareabox {
    width: 100%;
    padding: 16px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 2px #02ccfe;
    border-radius: 4px;
    outline: none;
    height: 150px;
    resize: none;
}
.btn {
    width: 132px;
    height: 40px;
    border: 0;
    cursor: pointer;
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: #fff;
    background: #0072bb;
    box-shadow: 0 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all .5s ease;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .contactus .viewcontactuscontent {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background: url(../../../assets/images/curvelinecontact.svg) no-repeat 0 0%;
    }
    .contactus .contactuscard::after {
        display: none;
    }
    .contactus .contactustitle {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .contactus .viewcontactuscontent {
        background: url(../../../assets/images/curvelinecontact.svg) no-repeat 105px 0%;
    }
    .contactus .contactuscard::after {
        top: 20%;
        background: url(../../../assets/images/mailbox.svg) no-repeat 0% 0%;
        right: -22vw;
        background-size: 70%;
    }
}