.faq .maindiv {
    padding-left: 56px;
    padding-right: 46px;
}
.faq h2.maintitle {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin-top: 24px;
    margin-bottom: 0px;
}
.faq .faqsearchbox .forumssearchbox {
    max-width: 510px;
    padding: 36px 0px 40px 0px;
}
.faq .faqsearchbox .forumssearchbox .userpic,
.faqsearchbox .forumssearchbox .userpic img {
    width: 50px;
    height: 50px;
}
.faq .searchbox form .formtext {
    height: 38px;
}
.faq .searchbox form .form-action .form-submit {
    height: 38px;
    width: 60px;
}
.faq .leftsidebar {
    width: 18.75%;
}
.faq .maindiv .midcontent {
   /*  width: 76.5%; */
   /*  margin: auto auto auto 22.5%; */
   width: 100%;
   margin: auto;
}
.viewfaqlist {
    margin-top: 35px;
}
.viewfaqlist .viewcontent .ant-collapse-borderless {
    background-color: transparent;
}
.viewfaqlist .viewcontent .ant-collapse-item {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 24px;
    border: 0;
}
.viewfaqlist .viewcontent .ant-collapse-item:last-child {
    margin-bottom: 10px;
}
.viewfaqlist .viewcontent .ant-collapse-item .ant-collapse-header {
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #2B2B2B;
    cursor: pointer;
    position: relative;
    padding: 0px 30px 0px 0px;
}
.viewfaqlist .viewcontent .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    right: 0px;
}
.viewfaqlist .viewcontent .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
    fill: #0072BB;
    display: none;
}
.viewfaqlist .viewcontent .ant-collapse-item .ant-collapse-header:after {
    position: absolute;
    width: 10px;
    height: 2px;
    background: #0072BB;
    top: 0px;
    margin: auto;
    content: "";
    bottom: 0px;
    right: 0px;
}
.viewfaqlist .viewcontent .ant-collapse-item .ant-collapse-header:before {
    position: absolute;
    width: 2px;
    height: 10px;
    background: #0072BB;
    top: 0px;
    margin: auto;
    content: "";
    bottom: 0px;
    right: 4px;
}
.viewfaqlist .viewcontent .ant-collapse-item.ant-collapse-item-active .ant-collapse-header:before {
    opacity: 0;
}
#navigation-primary .closepanel span.close-panel {
    display: none;
}
.viewfaqlist .viewcontent .ant-collapse-content > .ant-collapse-content-box {
    margin-top: 16px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #909090;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .faq .maindiv {
        padding-left: 0px;
        padding-right: 0px;
    }
    .faq.maincontent {
        padding-left: 20px;
        padding-right: 20px;
    }
    .faq h2.maintitle {
        font-size: 24px;
        line-height: 35px;
    }
    .faq .searchbox {
        padding: 0px;
    }
    .faq .faqsearchbox .forumssearchbox {
        padding: 26px 0px;
    }
    .faq .leftsidebar {
        width: 100%;
    }
    .faq .leftsidebar .leftsidemenu {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: 0px;
    }
    .faq .maindiv .midcontent {
        width: 100%;
        margin: auto;
    }
    .viewfaqlist {
        margin-top: 24px;
    }
    .viewfaqlist .viewcontent .ant-collapse-borderless {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .faq .maindiv {
        padding-left: 0px;
        padding-right: 0px;
    } 
    .faq .maindiv .midcontent {
        width: 100%;
        margin: auto;
        padding: 0px 20px;
    }
    .faq .leftsidebar {
        width: 23%;
    }
    .faq .leftsidebar .leftsidemenu {
        width: 23%;
        max-width: 100%;
        left: 20px;
    }
    .faq .faqsearchbox .forumssearchbox {
        padding: 20px 0px 20px 0px;
    }
}