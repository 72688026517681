.maincontent.helpuspage {
    position: relative;
    padding-bottom: 0px;
}

.maincontent.helpuspage::after {
    width: 38%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    background: #8EB5FF;
}

.helpuspage .contentarea {
    max-width: 1440px;
    margin: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    min-height: 100vh;
}

.helpuspage .contentarea .leftcontent {
    width: 62%;
    flex: 1;
    position: relative;
    padding: 56px 56px 56px 40px;
    background: #fff;
}
.helpuspage .contentarea .leftcontent .helpuswrapper {
    position: relative;
    z-index: 1;
}

.helpuspage .contentarea .leftcontent::before{
    content: "";
    width: 500px;
    height: 258px;
    position: absolute;
    top: 0px;
    background: url(../../assets/topcurveicon.png) no-repeat 0% 0%;
}
.helpuspage .contentarea .leftcontent::after {
    content: "";
    width: 331px;
    height: 263px;
    position: absolute;
    bottom: 0px;
    background: url(../../assets/bottomcurveicon.png) no-repeat 0% 0%;
}
.helpuspage .step1Form.contentarea .leftcontent::before ,
.helpuspage .step4Form.contentarea .leftcontent::before {
    right: -150px;
}
.helpuspage .step1Form.contentarea .leftcontent::after,
.helpuspage .step4Form.contentarea .leftcontent::after {
    left: 0px;
}
.helpuspage .step2Form.contentarea .leftcontent::before,
.helpuspage .step3Form.contentarea .leftcontent::before {
    left: -150px;
}
.helpuspage .step2Form.contentarea .leftcontent::after,
.helpuspage .step3Form.contentarea .leftcontent::after {
    right: -40px;
    transform: rotate(-90deg);
}

.helpuspage .contentarea .rightcontent {
    width: 38%;
    background: #8EB5FF;
    padding: 56px;
    display: flex;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 1;
}

.helpuspage .contentarea .rightcontent img {
    max-width: 100%;
    height: auto;
}

.helpuswrapper .question {
    max-width: 648px;
    margin-bottom: 61px;
}

.helpuswrapper .question h2 {
    font-size: 36px;
    line-height: 54px;
    color: #11072F;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
}

.helpuswrapper .question p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #4B4B4B;
    margin-bottom: 0px;
    font-family: 'Roboto', sans-serif;
}

.helpuswrapper .question p b {
    font-weight: 500;
}

.helpuswrapper .radioboxitem .ant-radio-group,
.helpuswrapper .checkboxitem .ant-checkbox-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.helpuswrapper .radioboxitem .ant-radio-wrapper, 
.helpuswrapper .checkboxitem .ant-checkbox-wrapper {
    width: 22%;
    height: 100px;
    margin: 0px 2.6% 2.6% 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    color: #0072BB;
    flex-wrap: wrap;
    white-space: normal;
    word-break: break-word;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 8px;
}

.helpuswrapper .radioboxitem .ant-radio-wrapper:after, 
.helpuswrapper .checkboxitem .ant-checkbox-wrapper:after {
    display: none;
}

.helpuswrapper .radioboxitem .ant-radio-wrapper.ant-radio-wrapper-checked, 
.helpuswrapper .checkboxitem .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    font-weight: bold;
    background: #D5E8F4;
    border: 1px solid #0072BB;
}
.helpuswrapper .checkboxitem .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0072BB;
    border-color: #0072BB;
}
.helpuswrapper .checkboxitem .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-color: transparent !important;
    background: transparent;
    transition: none;
}
.helpuswrapper .checkboxitem .ant-checkbox-inner::after {
    top: 45%;
    width: 7px;
    height: 15px;
    transition: none;
}
.helpuswrapper .checkboxitem .ant-checkbox-checked .ant-checkbox-inner::after {
    transition: none;
}
.helpuswrapper .checkboxitem span.ant-checkbox,
.helpuswrapper .radioboxitem span.ant-radio {
    position: absolute;
    top: -8px;
    right: -8px;
}
.helpuswrapper .radioboxitem .ant-radio-checked::after {
    border-radius: 2px;
}
.helpuswrapper .radioboxitem .ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    direction: ltr;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 2px;
    border-collapse: separate;
    transition: none;
}

.helpuswrapper .radioboxitem .ant-radio-inner::after {
    position: absolute;
    top: 45%;
    width: 7px;
    height: 15px;
    left: 22%;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    background: transparent;
    border-radius: 0px;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    transition: none;
    content: ' ';
}

.helpuswrapper .radioboxitem .ant-radio-checked .ant-radio-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: none;
    content: ' ';
}

.helpuswrapper .radioboxitem .ant-radio-checked .ant-radio-inner {
    background-color: #0072BB;
    border-color: #0072BB;
}

.step4Form .helpuswrapper .question {
    margin-bottom: 12px;
    max-width: 100%;
}

.step4Form .helpuswrapper .mylocations {
    margin-bottom: 24px;
}

.step4Form .helpuswrapper .ant-checkbox-wrapper .ant-checkbox + span {
    padding-right: 0px;
    padding-left: 12px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #2B2B39;
}

.step4Form .helpuswrapper .locations {
    background: #FBFBFB;
    border-radius: 6px;
    padding: 24px;
    max-width: 639px;
}

.step4Form .helpuswrapper .locations .formitem:last-child {
    margin-bottom: 0px;
}

.step3Form .helpuswrapper .actionbutton {
    margin-top: 480px;
}

.step4Form .helpuswrapper .actionbutton {
    margin-top: 15%;
    margin-bottom: 0%;
}

.helpuswrapper .actionbutton {
    margin: 40px 2.6% 125px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helpuswrapper .formitem .formtext {
    max-width: 639px;
    /* padding: 0px 16px; */
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    height: 56px;
    color: #868686;
    background: #FBFBFB;
    border: 1px solid #0072BB;
    border-radius: 8px;
}

.helpuswrapper .actionbutton button.ant-btn {
    width: 228px;
    height: 50px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    background: #0072BB;
    border-radius: 4px;
    transition: all 0.5s ease;
}

.helpuswrapper .actionbutton button.prevstep {
    width: 50px;
    height: 50px;
    font-size: 0px;
    margin-right: 24px;
    background: #FBFBFB;
    border-radius: 360px;
    box-shadow: 0px 0px 4px #0072BB;
}

.helpuswrapper .actionbutton button.prevstep::after {
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 0px;
    top: 0px;
    margin: auto;
    left: -6px;
    right: 0px;
    border: 2px solid #0072BB;
    border-top: 0;
    border-left: 0;
    transform: rotate(137deg) scale(1) translate(-50%, -50%);
    content: ' ';
}
.error {
    color: #ff0033;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .helpuspage .contentarea {
        flex-wrap: wrap;
    }
    .helpuspage .contentarea .leftcontent {
        width: 100%;
        flex: 1 100%;
        padding: 30px 20px;
    }
    .helpuspage .contentarea .rightcontent {
        width: 100%;
        flex: 1 100%;
        justify-content: center;
        padding: 30px 20px;
    }
    .helpuswrapper .question h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .helpuswrapper .question {
        max-width: 100%;
        margin-bottom: 20px;
    }
    .helpuswrapper .radioboxitem .ant-radio-group, 
    .helpuswrapper .checkboxitem .ant-checkbox-group {
        margin-right: 0px;
    }
    .helpuswrapper .radioboxitem .ant-radio-wrapper, 
    .helpuswrapper .checkboxitem .ant-checkbox-wrapper {
        width: 100%;
        margin: 0px 0px 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .helpuswrapper .formitem {
        margin-bottom: 15px;
    }
    .helpuswrapper .formitem .formtext {
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        height: 45px;
    }
    .step4Form .helpuswrapper .locations {
        max-width: 100%;
    }
    .step4Form .helpuswrapper .actionbutton {
        margin-top: 20px;
    }
    .helpuswrapper .actionbutton {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .helpuswrapper .actionbutton button.ant-btn {
        width: 160px;
        height: 42px;
        font-size: 15px;
    }
    .helpuswrapper .actionbutton button.prevstep {
        width: 40px;
        height: 40px;
        font-size: 0px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .helpuspage .contentarea .leftcontent {
        padding: 30px 20px;
    }
    .helpuspage .contentarea .rightcontent {
        justify-content: center;
        padding: 30px 20px;
    }
    .helpuswrapper .question h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .helpuswrapper .question {
        max-width: 100%;
        margin-bottom: 40px;
    }
    .helpuswrapper .radioboxitem .ant-radio-group, 
    .helpuswrapper .checkboxitem .ant-checkbox-group {
        margin-right: 0px;
    }
    .helpuswrapper .radioboxitem .ant-radio-wrapper, 
    .helpuswrapper .checkboxitem .ant-checkbox-wrapper {
        font-size: 16px;
        line-height: 20px;
        width: 25%;
        height: 85px;
    }
    .helpuswrapper .formitem {
        margin-bottom: 15px;
    }
    .helpuswrapper .formitem .formtext {
        font-size: 16px;
        line-height: 20px;
        height: 45px;
    }
    .step4Form .helpuswrapper .actionbutton {
        margin-top: 50px;
    }
    .helpuswrapper .actionbutton {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .helpuswrapper .actionbutton button.ant-btn {
        width: 160px;
        height: 42px;
        font-size: 15px;
    }
    .helpuswrapper .actionbutton button.prevstep {
        width: 40px;
        height: 40px;
        font-size: 0px;
    }
    .helpuswrapper .actionbutton button.prevstep::after {
        right: 15px;
    }
}