.testinomialsliderwrap {
  background-color: #F8F8F8;
  padding: 56px 0px;
  overflow: hidden;
  width: 100%;
}
.viewtestinomialslider {
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding: 0px 24px;
}
.viewtestinomialslider h2.blocktitle {
  max-width: 1440px;
  padding:0px 30px;
  overflow: hidden;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 62px;
  color: #11072F;
  margin: 0px 0px 56px;
}

.viewtestinomialslider .viewcontent {
  max-width: 1170px;
  margin: auto;
}
.viewtestinomialslider .viewcontent .viewrow {
  width: 30.33%;
  float: left;
  border-radius: 20px;
  background-color: #fff;
}
.ant-carousel .slick-list .slick-slide .viewrow {
  margin: 0px;
}
.viewtestinomialslider .viewcontent .viewrow .image {
  width: 46%;
  float: left;
}
.viewtestinomialslider .viewcontent .viewrow .image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.viewtestinomialslider .viewcontent .viewrow .imagebox {
  overflow: hidden;
  max-width: 100%;
  position: relative;
  border-radius: 10px;
}
.viewtestinomialslider .viewcontent .viewrow .imagebox span {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.75) url(../../assets/images/play.svg) no-repeat 50% 50%;
  margin: auto;
  left: 0px;
  right: 0px;
  font-size: 0px;
  cursor: pointer;
}
.viewtestinomialslider .viewcontent .viewrow .imagebox span.ant-spin-dot {
  width: 30px;
  height: 30px;
}
@keyframes pulse {
  0% {
      transform: scale(0.5);
  }
  70% {
      transform: scale(0.90);
      box-shadow: 0 0 0 10px rgb(0 116 189 / 30%);
  }
  100% {
      transform: scale(0.80);
      box-shadow: 0 0 0 10px rgb(0 116 189 / 30%);
  }
}

@-webkit-keyframes BGgradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
.intro-video-popup .react-player__play-icon {
  background: rgba(255, 255, 255, 0.75) url(../../assets/images/play.svg) no-repeat 50% 50%;
  border: none!important;
  border-width: unset!important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-size: 25px;
  margin: 0!important;
  box-shadow: 0 0 0 10px rgb(0 116 189 / 30%);
  animation: pulse 2s infinite;
}
.testimonialsvideo {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  border-radius: 10px;
}

.testimonialsvideo >div {
  width: 100% !important;
  height: 100% !important;
}

.testimonialsvideo iframe,
.testimonialsvideo object,
.testimonialsvideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


.ant-modal-mask {
  background-color: rgba(196, 196, 196, 0.5);
}
.testimonialspopup .ant-modal {
  width: 100% !important;
  max-width: 856px;
  padding: 0px 20px;
}
.testimonialspopup .ant-modal-content {
  border-radius: 10px;
}

.testimonialspopup .ant-modal-body {
  width: 100%;
  padding: 65px 20px 20px;
  overflow: auto;
  background: #FBFBFB;
  border-radius: 10px;
  position: relative;
}

.testimonialspopup .ant-modal-close {
  width: 38px;
  height: 38px;
  display: block;
  position: absolute;
  right: 20px;
  top: 15px;
  border-radius: 36px;
  cursor: pointer;
  background:#fff url(../../assets/images/popupclose.svg) no-repeat 50% 50%;
  box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}
.testimonialspopup .ant-modal-close .ant-modal-close-x {
  display: none;
}
.react-player {
  height: 266px;
}
.react-player img {
  width: 100%;
  min-height: 266px;
  object-fit: cover;
}
.spinner-box{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  width: 50px;
  height: 50px;
  z-index: 10;
}
.viewtestinomialslider .viewcontent .viewrow .cardbox {
  width: 49%;
  float: left;
  flex: 1;
  padding: 24px;
}

.viewtestinomialslider .viewcontent .viewrow .title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #747474;
  margin-bottom: 4px;
}

.viewtestinomialslider .viewcontent .viewrow .position {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #747474;
}

.viewtestinomialslider .viewcontent .viewrow .description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #747474;
  margin-top: 36px;
}

.viewtestinomialslider .ant-carousel .slick-arrow {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  border-radius: 36px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}
.viewtestinomialslider .ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.viewtestinomialslider .ant-carousel .slick-prev {
  left: -45px;
}
.viewtestinomialslider .ant-carousel .slick-next {
  right: -45px;
}


@media only screen and (min-width: 1400px) {
  .viewtestinomialslider .viewcontent {
    max-width: 1260px;
  }
  .viewtestinomialslider .ant-carousel .slick-prev {
    left: -66px;
  }
  .viewtestinomialslider .ant-carousel .slick-next {
    right: -66px;
  }
  .viewtestinomialslider .ant-carousel .slick-arrow {
    width: 50px;
    height: 50px;
  }
}
.viewtestinomialslider .ant-carousel .slick-arrow:before {
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transition: all .5s ease;
  border-bottom: 2px solid #0072BB;
  border-right: 2px solid #0072BB;
}

.viewtestinomialslider .ant-carousel .slick-arrow.slick-prev:before {
  transform: rotate(135deg);
  left: 5px;
}

.viewtestinomialslider .ant-carousel .slick-arrow.slick-next:before {
  right: 2px;
  transform: rotate(-45deg);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .viewtestinomialslider .slick-slide {
    margin: auto !important;
    background: none !important;
    justify-content: center;
    border-radius: 0px !important;
  }
  .testinomialsliderwrap {
    padding: 35px 20px;
  }
  .viewtestinomialslider {
    padding: 0px 25px;
  }
  .viewtestinomialslider h2.blocktitle {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 35px;
    padding: 13;
    text-align: center;
  }
  .viewtestinomialslider .viewcontent .viewrow {
    width: 100%;
    margin: 0px 0px 15px;
  }
  .viewtestinomialslider .viewcontent .viewrow .image {
    width: 44%;
  }
  .viewtestinomialslider .viewcontent .viewrow .cardbox {
    flex: 1;
    padding: 10px;
    width: 56%;
  }
  .viewtestinomialslider .viewcontent .viewrow .description {
    margin-top: 5px;
  }
  .viewtestinomialslider .ant-carousel .slick-arrow {
      width: 25px;
      height: 25px;
  }
  .viewtestinomialslider .ant-carousel .slick-prev {
      left: -35px;
  }
  .viewtestinomialslider .ant-carousel .slick-next {
      right: -35px;
  }

  .viewtestinomialslider .viewcontent .imagebox {
    padding-bottom: 56.25%;
    height: 0;
  }
  .viewtestinomialslider .player-wrapper img {
    width: 100%;
    min-height: 266px;
    object-fit: cover;
  }
  .testimonialspopup .ant-modal-body {
    padding: 40px 20px 20px;
}
  .testimonialspopup .ant-modal-close {
    width: 25px;
    height: 25px;
    top: 10px;
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
   
  .viewtestinomialslider {
    padding: 0px 40px;
  }
  .testinomialsliderwrap {
    padding: 35px 20px;
  }
  .viewtestinomialslider .viewcontent .viewrow .player-wrapper img {
    width: 100%;
    height: 266px;
    object-fit: cover;
  }
  .viewtestinomialslider h2.blocktitle {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 35px;
    padding: 0px;
  }
  .viewtestinomialslider .control {
    display: none;
  }
  .viewtestinomialslider .viewcontent .viewrow .description {
    margin-top: 5px;
  }

  .viewtestinomialslider .viewcontent .slick-list .slick-slide>div {
    margin: 0px 0px;
  }
  .viewtestinomialslider .ant-carousel .slick-arrow {
      width: 35px;
      height: 35px;
  }
  .viewtestinomialslider .ant-carousel .slick-prev {
      left: -45px;
  }
  .viewtestinomialslider .ant-carousel .slick-next {
      right: -45px;
  }

}