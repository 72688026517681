.bannerarea {
  height: 910px;
  background-repeat: no-repeat !important;
  background-position: 0% 88% !important;
  background-size: cover !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(213, 145, 43, 0) 100%);
}

.bannerarea .bannercontent {
  max-width: 1440px;
  padding: 36px 56px 36px 0;
  margin: auto;
  display: flex;
}

.bannerarea .shortdescription {
  max-width: 813px;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 62px;
  color: #FFFFFF;
}

.registrationformwrap {
  flex: 1;
}

.registrationformwrap .registrationform form {
  margin: 0px;
}

.registrationformwrap .registrationform {
  width: 260px;
  padding: 24px;
  float: right;
  background: #FAFAFA;
  border-radius: 10px;
}

.registrationformwrap .registrationform h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #11072F;
  margin: 0px;
}
.registrationformwrap .registrationform .helptext {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  margin: 8px 0px 10px;
}

.registrationformwrap .registrationform .formitem {
  width: 100%;
  margin-bottom: 10px;
}

.registrationformwrap .registrationform .formtext {
  width: 100%;
  height: 40px;
  padding: 0px 16px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 0px 2px #02CCFE;
  border-radius: 4px;
  outline: none;
}

.registrationformwrap .registrationform .help {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #909090;
}

.registrationformwrap .registrationform .termandcondition {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.registrationformwrap .registrationform .termandcondition .checkbox {
  display: none;
}

.registrationformwrap .registrationform .termandcondition label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #11072F;
  position: relative;
  cursor: pointer;
  padding-left: 30px;
}

.registrationformwrap .registrationform .termandcondition label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  border: 1px solid #0072BB;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 114, 187, 0.25);
  border-radius: 2px;
}

.registrationformwrap .registrationform .termandcondition label::after {
  width: 4px;
  height: 10px;
  content: "";
  position: absolute;
  left: 7px;
  bottom: 0;
  top: -3px;
  margin: auto;
  display: none;
  transition: all .5s ease;
  border-bottom: 2px solid #0072BB;
  border-right: 2px solid #0072BB;
  transform: rotate(45deg);
}

.registrationformwrap .registrationform .termandcondition .checkbox:checked + label::after {
  display: block;
}

.registrationformwrap .registrationform .form-action {
  text-align: center;
}

.registrationformwrap .registrationform .form-action .form-submit {
  width: 180px;
  height: 40px;
  border: 0px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  color: #FFFFFF;
  background: #0072BB;
  box-shadow: 0px 4px 4px rgba(0, 114, 187, 0.25);
  border-radius: 4px;
  transition: all 0.5s ease;
}
.registrationformwrap .registrationform .form-action .form-submit:hover {
  opacity: 0.8;
}

.registrationformwrap .registrationform .form-divider {
  border-top: 1px solid #909090;
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;
}

.registrationformwrap .registrationform .form-divider span {
  display: inline-block;
  vertical-align: middle;
  background: #FAFAFA;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #11072F;
  font-family: 'Roboto', sans-serif;
}

.registrationformwrap .registrationform .social-auth-login {
  text-align: center;
}

.registrationformwrap .registrationform .social-auth-login a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #11072F;
  background: #FFFFFF;
  border: 1px solid #0072BB;
  border-radius: 4px;
  padding: 13px 12px 13px 33px;
  width: 133px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}



.registrationformwrap .registrationform .already-account {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #11072F;
  text-align: center;
  margin-top: 24px;
}
.registrationformwrap .registrationform .already-account a {
  text-decoration-line: underline;
  color: #0072BB;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
  .bannerarea {
      height: 275px;
      padding: 20px;
  }
  .bannerarea .bannercontent {
      flex-wrap: wrap;
      width: 100%;
      padding: 0px;
  }
  .bannerarea .shortdescription {
      max-width: 100%;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      top: 200px;
      color: #000;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .bannerarea {
      height: auto;
      padding: 36px 30px;
  }
  .bannerarea .bannercontent {
    padding: 0px;
  }
  .bannerarea .shortdescription {
      font-size: 27px;
      line-height: 40px;
      padding-right: 40px;
  }
}