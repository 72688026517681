.allCoursespage.maincontent {
    padding-bottom: 16px;
}
.viewvideocourselist.allcourselist {
    padding: 0px;
    border-bottom: 0px;
}
.viewvideocourselist.allcourselist.level {
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 20px;
}
.allcourselist .viewvideocourse {
    max-width: 1126px;
}
.allcourselist .viewvideocourse .viewcontent {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.allcourselist .viewvideocourse .viewcontent .viewrow {
    width: 23%;
    margin: 0px 1% 2%;
    position: relative;
}
.allcourselist .viewvideocourse .viewcontent .viewrow .titlebox {
    min-height: initial;
}

@media only screen and (min-width: 0px) and (max-width: 539px) {
    .allcourselist .viewvideocourse {
        padding: 0px 20px;
    }
    .allcourselist .viewvideocourse .viewcontent {
        flex-wrap: wrap;
    }
    .allcourselist .viewvideocourse .viewcontent .viewrow {
        width: 100%;
        margin: 0px 0px 15px;
    }
}

@media only screen and (min-width: 540px) and (max-width: 767px) {
    
    .allcourselist .viewvideocourse {
        padding: 0px 20px;
    }
    .allcourselist .viewvideocourse .viewcontent {
        flex-wrap: wrap;
    }
    .allcourselist .viewvideocourse .viewcontent .viewrow {
        width: 49%;
        margin: 0px 2% 2% 0px;
    }
    .allcourselist .viewvideocourse .viewcontent .viewrow:nth-child(2n) {
        margin-right: 0px;
    }
    .viewvideocourse .viewcontent .viewrow .description>div:first-child {
        min-height: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .vieweducationfilter .vieweducationcontent {
        padding: 0px 20px;
    }
    .vieweducationfilter .searchbox form .formtext {
        height: 40px;
    }
    .allcourselist .viewvideocourse {
        padding: 0px 20px;
    }
    .allcourselist .viewvideocourse .viewcontent {
        flex-wrap: wrap;
    }
    .allcourselist .viewvideocourse .viewcontent .viewrow {
        width: 32%;
        margin: 0px 2% 2% 0px;
    }
    .allcourselist .viewvideocourse .viewcontent .viewrow:nth-child(3n) {
        margin-right: 0px;
    }
}