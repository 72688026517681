.forumsviewblock .viewcontent .viewrow .userinformation {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.forumsviewblock .viewcontent .viewrow .userinformation .userinfo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
}

.forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic,
.forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
    position: relative;
}

.forumsviewblock .viewcontent .viewrow .userinformation .createdby {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #909090;
}

.forumsviewblock .viewcontent .viewrow .userinformation .userinfo .title h2 {
    margin: 0px 0px 4px;
}

.forumsviewblock .viewcontent .viewrow .userinformation .userinfo .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2B2B39;
}

.forumsviewblock .viewcontent .viewrow .userinformation .createdby .created {
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
}

.forumsviewblock .viewcontent .viewrow .description {
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    letter-spacing: 0.02em;
    word-break: break-all;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic, 
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic img {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic, 
    .forumsviewblock .viewcontent .viewrow .userinformation .userinfo .userpic img {
        width: 50px;
        height: 50px;
    }
}