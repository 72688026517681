.viewblogslist {
    padding: 24px 0px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #ECECEC;
}
.viewblogs {
    max-width: 1142px;
    margin: auto;
    position: relative;
}
.viewblogs > h2 {
    max-width: 1104px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin: 0px auto 24px auto;
}

.viewblogs .viewcontent {
    max-width: 1128px;
    overflow: visible;
    margin: auto;
}
.viewblogs .viewcontent .viewrow {
    width: 31.33%;
    padding: 12px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 1% 2%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    background: #FBFBFB;
    border-radius: 10px;
}

.ant-carousel .slick-list .slick-slide .viewrow {
    margin: 0px;
}

.viewblogs .viewcontent .viewrow .imagebox {
    width: 100%;
    height: 168px;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
}
.viewblogs .viewcontent .viewrow .imagebox img {
    width: 100%;
    height: 168px;
    object-fit: cover;
    border-radius: 6px;
}
.viewblogs .viewcontent .viewrow h2.title {
    margin: 0px;
    padding: 12px 0px 6px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #11072F;
    font-family: 'Roboto', sans-serif;
}
.viewblogs .viewcontent .viewrow h2.title a {
    color: #11072F;
    text-decoration: none;
}
.viewblogs .viewcontent .viewrow .category {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2B2B39;
    margin-bottom: 5px;
}

.viewblogs .viewcontent .viewrow .readtime {
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    padding: 21px 0px 0px;
}
.viewblogs .viewcontent .viewrow .readtime .readview {
    flex: 1;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #2B2B39;
}
.viewblogs .viewcontent .viewrow .readtime .readview span {
    width: auto;
    padding: 0px 10px;
    height: 42px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
}
.viewblogs .viewcontent .viewrow .sharelink ul,
.viewblogs .viewcontent .viewrow .sharelink ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.viewblogs .viewcontent .viewrow .sharelink ul {
    display: flex;
    align-items: center;
}
.viewblogs .viewcontent .viewrow .sharelink ul li a {
    width: 20px;
    height: 20px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}
.viewblogs .viewcontent .viewrow .sharelink ul li.bookmark a {
    margin-right: 24px;
    background-image: url(../../assets/images/bookmark.svg);
    background-size: 12px;
}
.viewblogs .viewcontent .viewrow .sharelink ul li.share a {
    background-image: url(../../assets/images/share.svg);
    background-size: 14px;
}
.viewblogs .viewall {
    margin: 24px auto auto auto;
    max-width: 1104px;
}
.viewblogs .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-decoration: underline;
    color: #0771EE;
}

.ant-carousel .slick-arrow {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}
.ant-carousel .slick-prev {
    left: -54px;
}
.ant-carousel .slick-next {
    right: -54px;
}
.ant-carousel .slick-arrow:before {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all .5s ease;
    border-bottom: 2px solid #0072BB;
    border-right: 2px solid #0072BB;
}

.ant-carousel .slick-arrow.slick-prev:before {
    transform: rotate(135deg);
    left: 5px;
}

.ant-carousel .slick-arrow.slick-next:before {
    right: 2px;
    transform: rotate(-45deg);
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .viewblogslist {
        padding-left: 55px;
        padding-right: 55px;
    }
    .viewblogs > h2 {
        font-size: 26px;
        line-height: 30px;
        margin: 0px auto 15px auto;
    }
    .ant-carousel .slick-arrow {
        width: 25px;
        height: 25px;
    }
    .ant-carousel .slick-prev {
        left: -35px;
    }
    .ant-carousel .slick-next {
        right: -35px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .viewblogslist {
        padding-left: 55px;
        padding-right: 55px;
    }
    .viewblogs > h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .viewcontent .slick-list .slick-slide>div {
        margin: 0px 8px;
    }
    .ant-carousel .slick-arrow {
        width: 35px;
        height: 35px;
    }
    .ant-carousel .slick-prev {
        left: -45px;
    }
    .ant-carousel .slick-next {
        right: -45px;
    }
}
