.maincontent.detail-page-wrapper {
    margin: auto;
    max-width: 780px;
    padding: 56px 0 54px;
}
.lrtitle h2{
    margin: 0px 0px 30px;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #AAAAAA;
    border-left: 2px solid #0072BB;
    padding-left: 10px;
}
.detail-page > div { display: inline-flex; width: 100%; border-bottom: 1px solid #0000001c; padding: 10px 0; }
.detail-page { padding: 30px 7%; border-radius: 20px; box-shadow: 0 11px 30px 0px #00304d21; }
.detail-page > div > span:first-child { width: 46%; }
.detail-page > div > span:last-child { width: 50%; font-weight: 500; letter-spacing: 0.2pt; color: #000; font-size: 16px; }
.detail-page > div:last-child { border: 0 none; }
.detail-page .lr-action-wrapper{
    display: block;
}
.detail-page>div.lr-action-wrapper { padding: 40px 0; display: inline-block; width: 100%; }
.detail-page>div.lr-action-wrapper h2 { font-size: 22px; margin: 0 0 12px; }
.detail-page>div.lr-action-wrapper .ant-radio-group .ant-space { display: flex; flex-direction: row; padding: 0 0 30px; }
/****lender list*****/
.interested-lenders-wrapper {
    border: 1px solid #0072BB;
    border-radius: 7px;
    margin: 20px auto;
}
.l-items-head, .l-items {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid#0072BB;
}
.l-items-head {
    background: #0072BB;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}
.l-items-left,.l-items-right  {
    flex: 1;
    text-transform: capitalize
}
.l-items:last-child {
    border: none;
}
.maincontent.detail-page-wrapper .breadcrumb ul { padding: 0 0 17px; margin: 0; width: 100%; }
.maincontent.detail-page-wrapper .breadcrumb { padding: 0 10px; }
.loan-status-box { margin: 25px 0; background: #ffff; padding: 12px 20px; border: 1px solid #D7DDF5; border-radius: 5px; border-bottom: 9px solid #0076bb36; }
.loan-status-box .loan-status { font-size: 17px; display: inline-block; width: 100%; padding: 0 0 5px; }
.loan-status-box .loan-status span { font-weight: 600; text-transform: capitalize; color: #0074BB; }
.text-cap{text-transform: capitalize;}