footer{
  position: relative;
  z-index: 1;
}
footer.landing {
  display: none;
}

footer .footertoparea {
  padding: 66px 15px 128px;
  background: #020B12;
}

footer .footertop {
  max-width: 925px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}
footer .footertop .footercolumn {
  margin-right: 24%;
}
footer .footertop .footercolumn:last-child {
  margin-right: 0px;
}

footer .footertop .footercolumn.footerfourth  {
  flex: 1;
  width: auto;
}

footer .footerbottom {
  padding: 23px 15px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-align: center;
  background: #0F0F0F;
}

footer h2.blocktitle {
  margin: 0px 0px 24px 0px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
footer ul,
footer ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
  line-height: 14px;
  list-style-image: none;
}

footer ul li a {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 16px;
  transition: all 0.5s ease;
}
footer ul li:last-child a {
  margin-bottom: 0px;
}
footer ul li a:hover {
  color: #0072BB;
}

footer ul.sociallink li a {
  padding-left: 25px;
}
footer ul.sociallink li.facebook a {
  background: url(../../assets/images/facebook.svg) no-repeat 0% 50%;
}
footer ul.sociallink li.instagram a {
  background: url(../../assets/images/instagram.svg) no-repeat 0% 50%;
}
footer ul.sociallink li.twitter a {
  background: url(../../assets/images/twitter.svg) no-repeat 0% 50%;
}
footer ul.sociallink li.youtube a {
  background: url(../../assets/images/youtube.svg) no-repeat 0% 50%;
}
.footerappdownload{
  margin: auto;
  display: flex;
  padding: 10px 56px;
  position: relative;
  z-index: 10;
 }
.footerappdownload .left img{ max-width: 30%;}
.footerappdownload .right {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5%;
  justify-content: center;
  padding: 5px 0 10px;
  max-width: 360px;
}
.footerappdownload .right > div {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #1874BB;
}
.footerappdownload .right > div > a {
  margin: 10px 5px 0;
}
.footerappdownload .right > div > a img {
  display: block;
  border-radius: 8px;
  max-width: 170px;
}
.footerappdownload .middle {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5% 0;
  align-items: center;
  align-content: center;
  max-width: calc(500px + 10%);
}
.footerappdownload .middle .h {
  font-weight: 900;
  font-size: 50px;
  line-height: normal;
  color: #0076BB;
}
.footerappdownload .middle .h span{
  color: #020B12;
}
.footerappdownload .middle .b {
  font-weight: bold;
  font-size: 18px;
}
.footerappdownload .left{
  display: flex;
  align-items: center;
  width: 280px;
}
.footerappdownload .left img {
  max-width: 100%;
}
.footerappdownload {
  justify-content: space-between;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  footer .footertoparea {
      padding: 25px 5%;
  }
  footer .footertop {
      width: 100%;
      flex-wrap: wrap;
  }
  footer .footertop .footercolumn {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
      margin-right: 0px;
  }
  footer ul.sociallink li a {
    min-width: 90px;
    text-align: left;
  }
  footer .footertop .footercolumn:last-child {
      margin-bottom: 0px;
  }
  .footerappdownload{ flex-wrap: wrap; justify-content: center; }
  .footerappdownload .middle{ max-width: 100%; padding: 25px 0; width: 100%; }
  .footerappdownload .middle .h { width: 100%; font-size: 22px; }
  .footerappdownload .middle .b {font-size: 15px;}
  .footerappdownload .right .text{display: none;}
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  footer .footertoparea {
      padding: 25px 30px;
  }
}