#forumroot.hasmoreitem{
    display: none;
}
.anonymousforum .maindiv {
    max-width: 1440px;
}
.anonymousforum.maincontent .midcontent {
    width: 73%;
    margin-left: 25.5%;
    margin-right: 0px;
}
.anonymousforum.maincontent.noleftside .midcontent {
    width: 76%;
    margin: auto;
    float: none;
}
.anonymousforum.noleftside .midcontent .forumspostscontent {
    padding-top: 56px;
}

.anonymousforum .forumspostswrapper {
    padding-top: 88px;
}
.anonymousforum.forumsfilterresults .forumsposts {
    padding-top: 24px;
}
.forumsfilter {
    padding-bottom: 24px;
}
.forumsfilter .forumfilterbox {
    display: flex;
    align-items: center;
    gap: 24px;
}
.forumsfilter .category {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #11072F;
}
.forums .forumsfilter .category {
    color: #0072BB;
}
.forumsfilter .followfourm a{
    padding: 2px 9px;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: #0072BB;
    border: 1px solid #0072BB;
    border-radius: 6px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .anonymousforum .forumspostswrapper {
        padding-top: 0px;
    }
    
    .anonymousforum.maincontent .midcontent {
        width: 100%;
        margin: 0px;
        padding: 20px 0px;
        overflow: hidden;
    }
    .anonymousforum .maindiv {
        padding: 0px;
    }
    .anonymousforum.noleftside .midcontent .forumspostscontent {
        padding: 30px 0px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .anonymousforum.maincontent .midcontent {
        width: 71%;
        margin-left: 27%;
    }
    .anonymousforum .searchbox {
        width: 71%;
    }
    .anonymousforum .forumspostscontent {
        padding-top: 68px;
    }
    
    
}