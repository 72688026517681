.blogsdetail {
    background: #FBFBFB;
}
.blogsdetailcontent {
    max-width: 1440px;
    margin: auto;
    padding: 0px 56px 24px;
}
.blogsdetail .imagebox {
    width: 100%;
    height: 300px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 24px;
}
.blogsdetail .imagebox span.lazy-load-image-background {
    width: 100%;
}
.blogsdetail .imagebox::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.blogsdetail .imagebox img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}
.blogsdetail .title {
    max-width: 830px;
    margin: auto;
    padding: 15px 20px 30px;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    font-weight: bold;
}
.blogsdetail .description {
    max-width: 830px;
    margin: auto;
    padding: 0px 20px 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.blogsdetail .createdby {
    max-width: 830px;
    margin: auto;
    padding: 24px 20px 0px;
    display: flex;
    align-items: center;
}
.blogsdetail .createdby .sharelink {
    flex: 1;
}
.blogsdetail .sharelink ul li.bookmark a {
    margin-right: 24px;
    background-image: url(../../assets/images/bookmark.svg);
    background-size: 12px;
}
.blogsdetail .sharelink ul li.share a {
    background-image: url(../../assets/images/share.svg);
    background-size: 14px;
}
.blogsdetail .sharelink ul,
.blogsdetail .sharelink ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.blogsdetail .sharelink ul {
    display: flex;
    align-items: center;
}
.blogsdetail .sharelink ul li a {
    width: 20px;
    height: 20px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}
.blogsdetail .createdby .auther {
    flex: 1;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    letter-spacing: 0.02em;
}
.categoriesbox {
    padding-bottom: 56px;
    background: #fff;
}
.blogsdetail .categories {
    max-width: 830px;
    margin: auto;
    padding: 24px 20px 0px;
    display: flex;
    align-items: center;
}
.blogsdetail .categories label {
    padding-right: 5px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #909090;
}
.blogsdetail .categories strong {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #909090;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .blogsdetailcontent {
        padding: 25px 20px;
    }
    .blogsdetail .imagebox {
        width: 100%;
    }
    .blogsdetail .imagebox img {
        width: 100%;
    }
    .blogsdetail .title {
        font-size: 26px;
        line-height: 35px;
        padding: 5px 0px 10px;
    }
    .blogsdetail .description {
        padding: 0px;
        font-size: 15px;
        line-height: 26px;
    }
    .blogsdetail .createdby {
        padding: 24px 0px 0px;
    }
    .blogsdetail .createdby .auther {
        font-size: 16px;
        line-height: 20px;
    }
}