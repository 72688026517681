.vieweducationfilter {
    padding: 14px 0px;
    background: #fff;
}
.vieweducationfilter .vieweducationcontent {
    max-width: 1440px;
    padding: 0px 56px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vieweducationcontent .userpic {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}
.vieweducationcontent .userpic img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 360px;
}
.vieweducationfilter .searchbox {
    flex: 1;
}
.vieweducationfilter .searchbox form {
    display: flex;
    margin: 0px 24px 0px 0px;
}

.vieweducationfilter .searchbox .search_filter {
    flex: 1;
}

.searchtabs ul,
.searchtabs ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.searchtabs ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
.searchtabs ul li {
    min-width: 174px;
}
.searchtabs ul li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #11072F;
    display: flex;
    background: #FFFFFF;
    border: 0.8px solid #0072BB;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    height: 58px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease;
}
.searchtabs ul li.active a,
.searchtabs ul li a:hover {
    color: #fff;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.type-tag {
    background: #0072BB;
    color: #fff;
    z-index: 1;
    position: absolute;
    font-size: 14px;
    top: 2px;
    right: 5px;
    padding: 0 10px;
    border-radius: 7px;
    text-transform: capitalize;
}
.viewvideocourselist {
    padding: 24px 0px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #ECECEC;
}
.viewvideocourse {
    max-width: 1142px;
    margin: auto;
    position: relative;
}
.viewvideocourse > h2 {
    max-width: 1104px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    margin: 0px auto 24px auto;
}

.viewvideocourse .viewcontent {
    max-width: 1128px;
    overflow: visible;
    margin: auto;
}
.viewvideocourse .viewcontent .viewrow {
    width: 31.33%;
    display: inline-block;
    vertical-align: top;
    margin: 0px 1% 2%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    background: #FBFBFB;
    border-radius: 10px;
}
.ant-carousel .slick-list .slick-slide .viewrow {
    margin: 0px;
}
.viewvideocourse .viewrow.education-item{
    width: 100%;
    display: inline-block;
}
.viewvideocourse .viewcontent .viewrow .imagebox  {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    border-radius: 10px 10px 0px 0px;
    background: url('../../assets/images/coursedefault.jpg');
}
.viewvideocourse .viewcontent .viewrow .imagebox.basic  {
    background: url('../../assets/images/basic.png');
    background-size: cover;
}
.viewvideocourse .viewcontent .viewrow .imagebox.inter  {
    background: url('../../assets/images/inter.png');
    background-size: cover;
}
.viewvideocourse .viewcontent .viewrow .imagebox.expert  {
    background: url('../../assets/images/expert.png');
    background-size: cover;
}
.viewvideocourse .viewcontent .viewrow .imagebox.preview-loaded{
    background: transparent;
} 
  
.viewvideocourse .viewcontent .viewrow .imagebox iframe,
.viewvideocourse .viewcontent .viewrow .imagebox object,
.viewvideocourse .viewcontent .viewrow .imagebox embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.viewvideocourse .viewcontent .viewrow .imagebox a {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.viewvideocourse .viewcontent .viewrow .imagebox span {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.75) url(../../assets/images/play.svg) no-repeat 50% 50%;
    margin: auto;
    left: 0px;
    right: 0px;
    font-size: 0px;
    cursor: pointer;
}
.viewvideocourse .viewcontent .viewrow .imagebox img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
}
.viewvideocourse .viewcontent .viewrow .titlebox {
    display: flex;
    align-items: center;
    padding: 10px;
}
/* .titlebox .title {
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
} */
.viewvideocourse .viewcontent .viewrow .titlebox h2 {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #11072F;
    flex: 1;
    font-family: 'Roboto', sans-serif;
}
.viewvideocourse .viewcontent .viewrow .titlebox h2 a {
    color: #11072F;
    text-decoration: none;
    display: block;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul,
.viewvideocourse .viewcontent .viewrow .sharelink ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul {
    display: flex;
    align-items: center;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li a {
    width: 20px;
    height: 20px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li.bookmark a {
    margin-right: 26px;
     background-image: url(../../assets/images/bookmark.svg); 
    background-size: 12px;
}
.viewvideocourse .viewcontent .viewrow .sharelink ul li.share a {
    background-image: url(../../assets/images/share.svg);
    background-size: 14px;
}
.viewvideocourse .viewcontent .viewrow .description {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    padding: 0px 10px;
    min-height: 50px;
}
.viewvideocourse .viewcontent .viewrow .createdby {
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.02em;
    color: #909090;
    text-align: right;
    padding: 10px;
}
.viewvideocourse .viewall {
    margin: 24px auto auto auto;
    max-width: 1104px;
}
.viewvideocourse .level .viewall {
    margin: 0;
}
.viewvideocourse .viewall a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-decoration: underline;
    color: #0771EE;
}

.viewcontent .ant-carousel .slick-track {
    display: flex;
}

.viewcontent .ant-carousel .slick-slide {
    float: left;
    height: auto;
    background: #FBFBFB;
    margin: 0px 12px;
    border-radius: 4px;
    position: relative;
}

.ant-carousel .slick-list .slick-slide>div{
    margin: 0px;
}
.ant-carousel .slick-arrow {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    border-radius: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}
.ant-carousel .slick-prev {
    left: -54px;
}
.ant-carousel .slick-next {
    right: -54px;
}
.ant-carousel .slick-arrow:before {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all .5s ease;
    border-bottom: 2px solid #0072BB;
    border-right: 2px solid #0072BB;
}

.ant-carousel .slick-arrow.slick-prev:before {
    transform: rotate(135deg);
    left: 5px;
}

.ant-carousel .slick-arrow.slick-next:before {
    right: 2px;
    transform: rotate(-45deg);
}
.webinarlist .viewvideocourse .viewcontent .viewrow .titlebox,
.tipofweeklist .viewvideocourse .viewcontent .viewrow .titlebox {
    align-items: flex-start;
}
.webinarlist .sharelink ul li.share .share-icon,
.tipofweeklist .sharelink ul li.share .share-icon,
.alltipofweeklist .sharelink ul li.share .share-icon,
.allwebinarlist .sharelink ul li.share .share-icon {
    font-size: 0px;
    justify-content: center;
    align-items: flex-start;
}
.webinarlist .sharelink ul li.share .share-icon:before,
.tipofweeklist .sharelink ul li.share .share-icon:before,
.alltipofweeklist .sharelink ul li.share .share-icon:before,
.allwebinarlist .sharelink ul li.share .share-icon:before {
    margin-right: 0px;
    background-size: 18px;
}
.webinarlist .viewvideocourse .viewcontent .viewrow .description, 
.tipofweeklist .viewvideocourse .viewcontent .viewrow .description, 
.alltipofweeklist .viewvideocourse .viewcontent .viewrow .description,
.allwebinarlist .viewvideocourse .viewcontent .viewrow .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.webinarlist .viewvideocourse .viewcontent .viewrow .cat, 
.tipofweeklist .viewvideocourse .viewcontent .viewrow .cat, 
.alltipofweeklist .viewvideocourse .viewcontent .viewrow .cat,
.allwebinarlist .viewvideocourse .viewcontent .viewrow .cat {
    margin-top: 5px;
    margin-bottom: 10px;
}

 .webinardetails .breadcrumb ul {
    padding-bottom: 25px;
    justify-content: center;
 }
 .webinardetails .webinartitle {
    max-width: 1440px;
    padding: 0px 56px;
    margin: auto;
    text-align: center;
 }
 .webinardetails .webinartitle .title {
    max-width: 100%;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #11072F;
    padding: 0px 0px 6px 0px;
    font-weight: normal;
 
 }
 .webinardetails .webinartitle .created {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #2B2B39;
    padding-bottom: 14px;
 }
 .webinardetails .blogsdetailcontent {
    max-width: 700px;
    margin: auto;
    padding: 0px 20px 56px;
}
.webinardetails .tipvideo {
    border-radius: 10px;
}
.webinardetails .tipvideo .react-player__preview {
    border-radius: 10px;
}
.webinardetails .tipvideo .react-player__shadow {
    box-shadow: none !important;
    background: rgba(225,225,225,0.8) !important;
}
.webinardetails .tipvideo .react-player__shadow .react-player__play-icon {
    margin-left: 4px !important;
    border-width: 7px 0px 7px 10px !important;
    border-color: transparent transparent transparent #0072BB !important;
}
.webinardetails ul {
    padding: 0px;
    margin: 22px 0px;
    display: flex;
}
.webinardetails ul li.share {
    margin-bottom: 0px;
}
.webinardetails ul li.share .share-icon {
    font-size: 0px;
    align-items: flex-start;
}
.webinardetails ul li.share .share-icon:before {
    margin-right: 0px;
    background-size: 18px;
    background-color: #FBFBFB;
}
.webinardetails .categoriesbox {
    padding-bottom: 36px;
    background: none;
}
.webinardetails .categoriesbox {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #909090;
}
.webinardetails .categoriesbox .categories {
    max-width: 100%;
    padding: 0px;
}
.webinardetails .categoriesbox .categories strong {
    color: #2B2B39;
    font-weight: normal;
}
.webinardetails.blogsdetail .title {
    max-width: 100%;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #11072F;
    font-weight: 400;
    padding: 0px 0px 5px;
}
.webinardetails.blogsdetail .description {
    max-width: 100%;
    padding: 16px 0px 20px 0px;
    font-size: 13px;
    line-height: 24px;
}
.webinardetails .actionbutton .actionbuttoninner {
    display: flex;
    align-items: center;
    justify-content: center;
}
.webinardetails .actionbutton .button button {
    min-width: 184px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    height: 40px;
    background: #0072BB;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: none;
    border: 1px solid #0072BB;
}
.webinardetails .actionbutton .button.previous button {
    color: #0072BB;
    background: #fff;
    margin-right: 17px;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    
    .viewcontent .ant-carousel .slick-track {
        display: inherit;
    }
    .educationlistfilterslider .viewcontent .ant-carousel .slick-track {
        display: flex;
    }
    .viewcontent .ant-carousel .slick-slide {
        margin: 0px 0px;
    }
    .ant-carousel .slick-list .slick-slide>div{
        margin: 0px 12px;
    }
    .vieweducationfilter .vieweducationcontent {
        padding: 0px 20px;
        flex-wrap: wrap;
    }
    .vieweducationcontent .userpic,
    .vieweducationcontent .userpic img {
        width: 50px;
        height: 50px;
    }
    .vieweducationfilter .searchbox {
        padding: 0px;
    }
    .vieweducationfilter .searchbox form {
        margin: 0px;
    }
    .vieweducationfilter .searchbox form .formtext {
        height: 50px;
    }
    .vieweducationfilter .searchtabs {
        flex: 1 100%;
    }
    .viewvideocourselist {
        padding-left: 45px;
        padding-right: 45px;
    }
    .viewvideocourse > h2 {
        font-size: 26px;
        line-height: 30px;
        margin: 0px auto 15px auto;
    }
    .ant-carousel .slick-arrow {
        width: 25px;
        height: 25px;
    }
    .ant-carousel .slick-prev {
        left: -35px;
    }
    .ant-carousel .slick-next {
        right: -35px;
    }
    .searchtabs ul {
        gap: 0px;
        flex-wrap: wrap;
        text-align: center;
    }
    .searchtabs ul li {
        min-width: auto;
        flex: 48%;
        width: 48%;
        display: inline-block;
        vertical-align: middle;
        margin-top: 3%;
        margin-right: 3%;
    }
    .searchtabs ul li:nth-child(2n) {
        margin-right: 0px;
    }
    .searchtabs ul li a {
        font-size: 14px;
        height: 40px;
    }
    .type-tag {
        right: 15px;
    }
    .webinardetails .tipvideo>div {
        height: auto !important;
    }
    .webinardetails .tipvideo .react-player__preview {
        height: auto !important;
        padding-top: 22%;
        padding-bottom: 22%;
    }
    .webinardetails .tipvideo .react-player__shadow {
        width: 40px !important;
        height: 40px !important;
    }
    .webinardetails .thumbnailpager {
        width: 100%;
    }
    .webinardetails ul {
        margin: 12px 0px;
    }
    .webinardetails .categoriesbox {
        padding-bottom: 15px;
    }
    .webinardetails .title {
        font-size: 17px;
    }
    .webinardetails .description {
        padding: 15px 0px 10px 0px;
    }
    .webinardetails .actionbutton .button.previous button {
        color: #0072BB;
        background: #fff;
        margin-left: 12px;
        margin-bottom: 10px;
    }
    .webinardetails .actionbutton .actionbuttoninner {
        width: 100%;
        flex-direction: column;
        display: flex;
    } 
    .webinardetails .actionbutton .button button {
        min-width: 125px;
    } 
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .vieweducationcontent .userpic,
    .vieweducationcontent .userpic img {
        width: 40px;
        height: 40px;
    }
    .viewvideocourselist {
        padding-left: 55px;
        padding-right: 55px;
    }
    .viewvideocourse > h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .viewcontent .slick-list .slick-slide>div {
        margin: 0px 8px;
    }
    .ant-carousel .slick-arrow {
        width: 35px;
        height: 35px;
    }
    .ant-carousel .slick-prev {
        left: -45px;
    }
    .ant-carousel .slick-next {
        right: -45px;
    }
    .searchtabs {
        flex: 1 1 40%;
    }
    .searchtabs ul {
        gap: 0px;
    }
    .searchtabs ul li {
        flex: 1;
        min-width: 120px;
        margin-right: 2%;
    }
    .searchtabs ul li:last-child {
        margin-right: 0px;
    }
    .searchtabs ul li a {
        font-size: 14px;
        height: 40px;
        padding: 0px 10px;
    }
}