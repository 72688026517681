.breadcrumb {
    max-width: 1440px;
    margin: auto;
    padding: 0px 56px;
}
/* .breadcrumb ul,
.breadcrumbwrapper {
    background: #fff;
} */
.breadcrumb ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}

.breadcrumb ul {
    display: flex;
    margin: 0px;
    padding: 24px 0px 50px 0px;
}
.breadcrumb ul li {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
}
.breadcrumb ul li a {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    text-decoration: none;
}
.breadcrumb ul>li+li:before {
    padding: 0 9px;
    color: #111;
    content: "/";
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .breadcrumb {
        padding: 0px 20px;
    }
    .breadcrumb ul {
        padding: 15px 0px 15px 0px;
        flex-wrap: wrap;
    }
    .breadcrumb ul li {
        font-size: 14px;
        line-height: 20px;
    }
    .breadcrumb ul li a {
        font-size: 15px;
        line-height: 20px;
    }
}
