#l-requestform {
    max-width: 750px;
    margin: 40px auto;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    border-radius: 10px;
    display: block;
    padding: 24px 36px;
    background: #fff;
    position: relative;
    z-index: 10;
}
#l-requestform .form-text, #l-requestform .ant-select-selection-search-input {
    width: 100%;
    padding: 0 16px;
    background: #fff;
     border-radius: 4px;
    outline: none;
}
#l-requestform .form-text{
    border: 1px solid #f1f1f1;
    box-shadow: 0 0 2px #02ccfe;
}
#l-requestform .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {height: 40px;align-items: center;}

#l-requestform .ant-form-item{
    margin-bottom: 15px;
}
#l-requestform .ant-form-item-label > label{
    font-size: 17px;
}
#l-requestform .ant-input-number{
    width: 200px;
}
#l-requestform { box-shadow: 0px 10px 35px rgb(0 0 0 / 12%); padding: 64px 2.5%; max-width: 820px; }
#l-requestform .ant-radio-group { width: 100%; }
#l-requestform .ant-radio-group .ant-space { display: flex; width: 100%; flex-wrap: wrap; }
#l-requestform .ant-radio-group .ant-space > .ant-space-item:first-child { width: 100%; }
#l-requestform .ant-radio-group .ant-space .ant-space-item { /* width: 100%; */ padding: 0 10px 0 0; }
#l-requestform .ant-radio-group .ant-space .ant-space-item label.ant-radio-wrapper { border: 1px solid #ccc; padding: 9px 10px; border-radius: 50px; min-width: 172px; margin: 0 0 12px; }
#l-requestform .ant-radio-group .ant-space .ant-space-item label.ant-radio-wrapper.ant-radio-wrapper-checked { background: #0074BB; color: #fff; border-color: #0074BB; }
#l-requestforms .ant-col.ant-form-item-label label { font-size: 16px; }
#l-requestforms .form-text { box-shadow: none; border: 1px solid #56B2C9; height: 42px; }
#l-requestforms .ant-form-item { margin: 0 0 18px; }
#l-requestforms .ant-col.ant-form-item-label { margin: 0; padding: 0 0 5px; }
#l-requestforms .ant-row.ant-form-item { max-width: 48%; width: 100%; }
#l-requestforms .ant-row.ant-form-item.addressfield { max-width: 100%; }
#l-requestforms { display: flex; flex-wrap: wrap; justify-content: space-between; padding: 25px 5px 0; }
#l-requestforms .ant-select-selector, #l-requestforms .ant-input, #l-requestforms .ant-input-number, #l-requestforms .ant-picker-large{box-shadow: none;border: 1px solid #56B2C9;min-height: 42px;border-radius: 5px;width: 100% !important;}
#l-requestforms .ant-input-number input { height: 42px; }
#l-requestforms .ant-row.ant-form-item.form-action { max-width: 100%; text-align: center; padding: 3em 0 0; margin: 0; }
#l-requestforms .ant-row.ant-form-item.form-action  button.ant-btn.form-submit.ant-btn-primary { min-width: 180px; height: 40px; border: 0; cursor: pointer; font-family: "Roboto",sans-serif; font-style: normal; font-weight: 700; font-size: 18px; line-height: 16px; color: #fff; background: #0072BB; box-shadow: 0 4px 4px rgb(0 114 187/25%); border-radius: 4px; transition: all .5s ease; }
.visit-dashboard{text-align: right;}
.visit-dashboard a{padding: 10px 20px; color: #0074BB; border-radius: 7px; border: 1px solid #0074BB;}
.visit-dashboard a:hover{background: #0074BB; color: #fff}
.visit-anony-cta { text-align: center; margin-top: 30px; }
.visit-anony-cta a { padding: 10px 50px; color: #0074BB; border-radius: 7px; border: 1px solid #0074BB; margin: 10px;}
.visit-anony-cta a:hover{background: #0074BB; color: #fff}
button.prevstep, button.nextstep {
    min-width: 180px;
    margin: 10px;
    cursor: pointer;
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    box-shadow: 0 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all .5s ease;
    height: 40px;
}
button.nextstep{
    background:#0072BB
}
.step-wrapper {
    border: 1px solid#ccc;
    border-radius: 20px;
    margin-top: 20px;
}
.step-wrapper h2 {
    background: rgb(0 114 187 / 70%);
    color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    text-align: center;
}
.step-wrapper div#l-requestforms {
    padding: 20px;
}
#l-requestforms .term-accept {
    max-width: 100%!important;
   
}
#l-requestforms .term-accept label{
    font-size: 16px!important;
    color: #11072F!important;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    #l-requestform{
        max-width: 100%;
        margin: 20px;
    }
    .visit-dashboard{
        margin-bottom: 20px;
    }
    #l-requestforms{
        display: block;
    }
    #l-requestforms .ant-row.ant-form-item{
        max-width: 100%;
    }
}