.chatOnlineFriend {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .chatOnlineImgContainer {
    position: relative;
    margin-right: 10px;
  }
  
  .chatOnlineImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;
  }
  
  .chatOnlineBadge {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: limegreen;
    position: absolute;
    right: 20px;
    bottom: 0;
  }
  .chatOnlineImgContainer .lazy-load-image-background {
    width: 50px!important;
    height: 50px!important;
    margin-right: 20px;
}
  
  @media screen and (max-width: 768px) {
    .chatOnlineName {
      display: none;
    }
  }