.educationlistfilterslider {
    padding: 14px 0px;
    position: relative;
    background: #fff;
}
.educationlistfilterslider .viewcontent {
    max-width: 1440px;
    margin: auto;
    position: relative;
    z-index: 10;
    padding: 0px 56px;
}

.educationlistfilterslider .viewcontent .viewrow {
    min-width: 160px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
}

.educationlistfilterslider .viewcontent .ant-carousel .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FBFBFB;
    margin: 0px 5px;
    border-radius: 360px;
}

.educationlistfilterslider .viewcontent .viewrow a,
.educationlistfilterslider .viewcontent .viewrow span.aa {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #0072BB;
    background: #FBFBFB;
    padding: 12px 10px;
    text-decoration: none;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 360px;
    text-align: center;
    cursor: pointer;
}

.educationlistfilterslider .control {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    max-width: 1460px;
    padding: 0px 10px;
    left: 0px;
    right: 0px;
    height: 30px;
}

.educationlistfilterslider .control .prev {
    float: left;
}

.educationlistfilterslider .control .next {
    float: right;
}

.educationlistfilterslider .control .btn {
    width: 30px;
    height: 30px;
    position: relative;
    text-align: center;
    cursor: pointer;
    border-radius: 36px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 114, 187, 0.25);
}

.educationlistfilterslider .control .btn:before {
    width: 6px;
    height: 6px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all .5s ease;
    border-bottom: 2px solid #0072BB;
    border-right: 2px solid #0072BB;
}

.educationlistfilterslider .control .btn.prev:before {
    transform: rotate(135deg);
    left: 5px;
}

.educationlistfilterslider .control .btn.next:before {
    right: 5px;
    transform: rotate(-45deg);
}