.termsandconditions, .basicpage {
    max-width: 1326px;
    padding: 56px;
    margin: auto;
}
h1 {
    color: #17365d;
    margin: 0px 0px 15px;
    font-weight: 400;
    font-size: 40px;
    line-height: 55px;
}

h2 {
    font-size: 25px;
    font-weight: 500;
}

h3 {
    font-size: 20px;
    font-weight: 500;
}
h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: #0072BB;
    margin: 0px 0px 24px;
}
table {
    width: 100%;
}
tbody tr td {
    padding: 0px 5px;
}
tbody tr td:empty {
    width: 50px;
}
.termsandconditions p {
    margin: 0px 0px 15px;
    font-size: 16px;
    line-height: 26px;
}

ul ul {
    margin-top: 20px;
}

ul li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 26px;
}

.termsandconditions>h1 {
    font-weight: bold;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .maincontent h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .maincontent h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .termsandconditions {
        padding: 20px;
    }
    .termsandconditions p {
        font-size: 14px;
        line-height: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .maincontent h1 {
        font-size: 30px;
        line-height: 44px;
    }
    .maincontent h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .termsandconditions {
        padding: 20px;
    }
    .termsandconditions p {
        font-size: 15px;
        line-height: 24px;
    }
}