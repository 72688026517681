.postsocialshare {
    padding: 0px 24px 11px;
}
.postsocialshare ul,
.postsocialshare ul li {
    list-style: none;
    list-style-image: none;
    padding: 0px;
    margin: 0px;
}
.postsocialshare ul {
    display: flex;
    align-items: center;
    gap: 16px;
}
.postsocialshare ul li {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0px;
    height: 32px;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #0072BB;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}
.postsocialshare ul li .count {
    padding-left: 5px;
}
.postsocialshare ul li.likecount button::after {
    display: none !important;
}
.postsocialshare ul li.likecount .like {
    width: 32px;
    height: 32px;
    font-size: 0px;
    padding: 0px;
    display: block;
    border-radius: 20px;
    transition: all 0.5s ease;
    background: url(../../../assets/images/likebutton.svg) no-repeat 50% 50%;
}

.postsocialshare ul li.likecount .wrapperlike,
.postsocialshare ul li.likecount .wrapperdislike {
    display: flex;
    align-items: center;
}

.postsocialshare ul li.likecount .wrapperlike button,
.postsocialshare ul li.likecount .wrapperdislike button {
    width: 32px;
    height: 32px;
    font-size: 0px;
    padding: 0px;
    border-radius: 20px;
    border: 0px;
    margin-right: 5px;
    outline: none !important;
    box-shadow: none !important;
    background: url(../../../assets/images/likebutton.svg) no-repeat 50% 50%;
}

.postsocialshare ul li.likecount .wrapperdislike button {
    background: url(../../../assets/images/dislike.svg) no-repeat 50% 50%;
}

.postsocialshare ul li.commentcount .commentcounts {
    width: 32px;
    height: 32px;
    font-size: 0px;
    border-radius: 20px;
    transition: all 0.5s ease;
    background: url(../../../assets/images/comment.svg) no-repeat 50% 50%;
}
.postsocialshare ul li.commentcount .commentcounts:hover {
    background:#fff url(../../../assets/images/comment.svg) no-repeat 50% 50%;
}
.postsocialshare ul li .share-icon {
    width: 32px;
    height: 32px;
    border-radius: 36px;
    font-size: 0px;
    padding: 0px;
    transition: all 0.5s ease;
    background: url(../../../assets/images/share.svg)no-repeat 50% 50%;
}
.postsocialshare ul li .share-icon:before{
    content: none!important;
}
.postsocialshare ul li .share-icon:hover {
    background:#fff url(../../../assets/images/share.svg)no-repeat 50% 50%;
}

/* Masonry layout */
.photo-wrapper {
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.masonry-image {
  width: calc(50% - 10px);
  margin-bottom: 16px;
  cursor: zoom-in;
  transition: transform 0.3s ease;
  object-fit: cover;
  position: relative;
}

.masonry-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.enlarged-image {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
}
.masonry-image img {
  width: 100%;
}
.masonry-image .delete-icon {
  background-color: #000!important;
  width: 30px!important;
  height: 30px!important;
  display: block;
  top: 20px;
  right: 20px;
  transform: unset;
  left: unset;
  cursor: pointer!important;
}
@media (max-width: 1200px) {
  .photo-wrapper {
    column-count: 2; /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .photo-wrapper {
    column-count: 1; /* 1 column on small screens */
  }
}

.del-photo-preview {
  text-align: center;
}
.del-photo-preview img {
  max-width: 100px;
}
  