.head-title {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    color: #000;
}
.category-list{
    height: 400px;
    overflow: auto;
    padding-right: 20px;
}
/* .category-list .cat-details .cat-title {
    font-size: 20px;
    background: #464242;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 12px;
}
.cat-details ul {
    padding: 0;
    margin: 0;
} */
.category-list label.radio-item {
    margin: 10px 0;
    border: 2px solid rgb(0 114 187 / 50%);
    padding: 10px;
    text-transform: capitalize;
    border-radius: 4px;
    cursor: pointer;
}
button.back-button {
    background: transparent!important;
    outline: none!important;
    box-shadow: none!important;
    color: #0072BB!important;
    min-width: unset!important;
    top: 20px;
    padding: 0!important;
    position: absolute;
}
