.maincontent.signuppage {
    position: relative;
    background: #fff;
    padding-bottom: 0px;
}
.signuppage::before {
    width: 42%;
    height: 100%;
    left: 0px;
    top: 0px;
    content: "";
    position: absolute;
    background: #F9FDFF;
}
.signupwrapper {
    max-width: 1440px;
    margin: auto;
    position: relative;
    z-index: 1;
    display: flex;
    gap: 109px;
}
.signupintroduction {
    width: 42%;
    padding: 56px;
    background: #F9FDFF;
}
.registration-form-wrap {
    flex: auto;
    width: 48%;
    padding: 56px 0 136px;
}
.signupintroduction h3 {
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #11072F;
    width: 362px;
    padding-bottom: 38px;
}
.signupintroduction h1 {
    margin: 0px;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: #11072F;
}
.registration-form-wrap .registration-form {
    width: 604px;
    padding: 24px;
    float: none;
    border-radius: 10px;
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
}
.registration-form-wrap .registration-form h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #11072F;
    margin: 0px;
}
.registration-form-wrap .registration-form .helptext {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666666;
    margin: 8px 0px 10px;
}
.registration-form-wrap .registration-form form{
    padding: 15px 0px;
}
.registration-form-wrap .registration-form .form-item {
    width: 46%;
    float: left;
    margin-right: 7%;
    margin-bottom: 24px;
}
.registration-form-wrap .registration-form .form-text {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 0px 2px #02ccfe;
    border-radius: 4px;
    outline: none;
}
.registration-form-wrap .registration-form .form-item:nth-child(even) {
    margin-right: 0px;
}
.registration-form-wrap .registration-form .help {
    max-width: 200px;
    float: right;
    margin-top: 6px;
}
.registration-form-wrap .registration-form  .form-item.term-and-condition{
    width: 100%;
    float: none;
    justify-content: start;
}
.registration-form-wrap .registration-form .form-action {
    margin-top: 36px;
}
.registration-form-wrap .registration-form .form-action .form-submit {
    min-width: 180px;
    height: 40px;
    border: 0px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
    background: #0072BB;
    box-shadow: 0px 4px 4px rgb(0 114 187 / 25%);
    border-radius: 4px;
    transition: all 0.5s ease;
}
.registration-form-wrap .registration-form .form-divider {
    max-width: 452px;
    margin: 44px auto;
}
.registration-form-wrap .registration-form .form-divider {
    border-top: 1px solid #909090;
    margin-top: 32px;
    margin-bottom: 24px;
    text-align: center;
}
.registration-form-wrap .registration-form .form-divider span {
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: -22px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #11072F;
    font-family: 'Roboto', sans-serif;
}

.registration-form-wrap .registration-form .social-auth-login {
    overflow: hidden;
    padding: 0px 30px;
}

.registration-form-wrap .registration-form .social-auth-login .google,
.registration-form-wrap .registration-form .social-auth-login .facebook {
    width: 46%;
    float: left;
    margin-right: 7%;
}

.registration-form-wrap .registration-form .social-auth-login .facebook {
    margin-right: 0px
}
.registration-form-wrap .registration-form .social-auth-login a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #11072F;
    background: #FFFFFF;
    border: 1px solid #0072BB;
    border-radius: 4px;
    padding: 13px 12px 13px 33px;
    width: 133px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 11px;
}
.registration-form-wrap .registration-form .social-auth-login a {
    width: 100%;
    padding: 0px;
    background: #D5E8F4;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-form-wrap .registration-form .social-auth-login a::before {
    content: "";
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 20px;
}

.registration-form-wrap .registration-form .social-auth-login .google a::before {
    background: url(../../assets/images/googleplus.svg) no-repeat 0% 0%;
    background-size: 18px;
}
.registration-form-wrap .registration-form .social-auth-login .facebook a::before {
    background:#D5E8F4 url(../../assets/images/facebook-auth.svg) no-repeat 0% 0%;
    background-size: 16px;
}

.registration-form-wrap .registration-form .already-account {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #11072F;
    text-align: center;
    margin-top: 46px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .signuppage {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .signuppage::before {
        width: 100%;
    }
    .signupwrapper {
        padding: 0px 20px;
        gap: 0px;
        flex-wrap: wrap;
    }
    .signupintroduction {
        width: 100%;
        padding: 0px;
    }
    .registration-form-wrap {
        width: 100%;
        padding-bottom: 56px;
    }
    .signupintroduction h3 {
        font-size: 16px;
        line-height: 33px;
        width: 100%;
        padding-bottom: 25px;
    }
    .signupintroduction h1 {
        font-size: 24px;
        line-height: 35px;
    }
    .registration-form-wrap .registration-form {
        max-width: 100%;
        width: 100%;
        padding: 30px 20px;
    }
    .registration-form-wrap .registration-form .form-item {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .registration-form-wrap .registration-form .social-auth-login {
        padding: 0px;
        max-width: 230px;
        margin: auto;
    }
    .registration-form-wrap .registration-form .social-auth-login .google, 
    .registration-form-wrap .registration-form .social-auth-login .facebook {
        width: 100%;
        float: left;
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .signupwrapper {
        gap: 50px;
    }
    .signuppage::before {
        width: 41%;
        display: none;
    }
    .signupintroduction {
        padding: 56px 20px;
    }
    .signupintroduction h3 {
        width: 100%;
        padding-bottom: 25px;
        max-width: 360px;
        font-size: 20px;
        line-height: 36px;
    }
    .signupintroduction h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .registration-form-wrap .registration-form {
        width: 90%;
        padding: 24px 5%;
        max-width: 556px;
        margin: auto;
    }
    .registration-form-wrap .registration-form .social-auth-login {
        overflow: hidden;
        padding: 0px;
        max-width: 350px;
        margin: auto;
    }
    .registration-form-wrap .registration-form .social-auth-login a {
        font-size: 11px;
    }
    .registration-form-wrap .registration-form .social-auth-login a::before {
        margin-right: 5px;
    }
    .registration-form-wrap .registration-form .social-auth-login .google, 
    .registration-form-wrap .registration-form .social-auth-login .facebook {
        width: 48%;
        margin-right: 4%;
    }
    .registration-form-wrap .registration-form .social-auth-login .facebook {
        margin-right: 0px;
    }

}