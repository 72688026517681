.podcastdetail {
    max-width: 720px;
    margin: auto;
    padding: 0px 20px 56px 20px;
}
.podcastdetail .imagebox {
    width: 680px;
    height: 250px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 24px;
}
.podcastdetail .imagebox::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.podcastdetail .imagebox img {
    width: 680px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}
.podcastdetail .title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #11072F;
    font-weight: bold;
    padding: 24px 50px 0px;
}
.podcastdetail .description {
    padding: 24px 50px 0px;
    font-size: 13px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.02em;
    color: #2B2B39;
}
.podcastdetail .readtime {
    display: flex;
    align-items: center;
    padding: 24px 50px 0px;
}
.podcastdetail .readtime .readview {
    display: flex;
    align-items: center;
    flex: 1;
}
.podcastdetail .readtime .readview label {
    padding-right: 5px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #909090;
}
.podcastdetail .readtime .readview strong {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #909090;
}
.podcastdetail .sharelink ul li.bookmark a {
    margin-right: 24px;
    background-image: url(../../assets/images/bookmark.svg);
    background-size: 12px;
}
.podcastdetail .sharelink ul li.share a {
    background-image: url(../../assets/images/share.svg);
    background-size: 14px;
}
.podcastdetail .sharelink ul,
.podcastdetail .sharelink ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}
.podcastdetail .sharelink ul {
    display: flex;
    align-items: center;
}
.podcastdetail .sharelink ul li a {
    width: 20px;
    height: 20px;
    font-size: 0px;
    display: block;
    cursor: pointer;
    border-radius: 36px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
}
.podcastdetail .audioplayer {
    padding: 24px 0px;
}
.podcastdetail .audioplayer audio {
    width: 100% !important;
    background: #F2F2F2;
    border-radius: 30px;
}
audio::-webkit-media-controls-play-button {
    color: #fff;
}
audio::-webkit-media-controls-panel {
    background: #F2F2F2;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .podcastdetail .imagebox {
        width: 100%;
    }
    .podcastdetail .imagebox img {
        width: 100%;
        height: 250px;
    }
    .podcastdetail .title {
        font-size: 26px;
        line-height: 35px;
        padding: 20px 20px 0px;
    }
    .podcastdetail .description {
        padding: 20px 20px 0px;
    }
    .podcastdetail .readtime {
        padding: 20px 20px 0px;
    }
    .podcastdetail .readtime .readview label {
        font-size: 14px;
        line-height: 19px;
    }
}