.vieweducationlisting {
  width: 100%;
  overflow: hidden;
}
.vieweducationlisting .viewrow {
  width: 100%;
  padding: 55px 0px 55px 0px;
}

.vieweducationlisting .viewrow .viewcontent {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 0px 45px 0px 56px;
}

.vieweducationlisting .viewrow .body {
  width: 48%;
}

.vieweducationlisting .viewrow .imagebox {
  width: 52%;
  position: relative;
}

.vieweducationlisting .viewrow:nth-child(1) .body {
  width: 52%;
  padding-top: 108px;
}

.vieweducationlisting .viewrow:nth-child(1) .imagebox {
  width: 48%;
  padding: 108px 40px;
  background: url(../../assets/images/education-vector.svg) no-repeat 50% 50%;
  background-size: 100%;
}

.vieweducationlisting .viewrow:nth-child(2n) {
  background-color: #F9F8FF;
}

.vieweducationlisting .viewrow:nth-child(4) {
  background-color: #fff;
}

.vieweducationlisting .viewrow .comming {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
  color: #11072F;
  margin-top: 30px;
}

.vieweducationlisting .viewrow:nth-child(2n) .viewcontent {
  flex-direction: row-reverse;
/*   align-items: center; */
}
.vieweducationlisting .viewrow .body h2 {
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  color: #11072F;
  margin-bottom: 30px;
  margin-top: 0px;
}

.vieweducationlisting .viewrow .description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #11072F;
}
.vieweducationlisting .viewrow .description .quote {
  display: block;
  width: 56px;
  height: 56px;
  background: url(../../assets/images/quote.svg) no-repeat 0% 0%;
}

.vieweducationlisting .viewrow:nth-child(2n) .body {
  padding-left: 88px;
}
.vieweducationlisting .viewrow:nth-child(2n) .description {
  padding-right: 0px;
}

.vieweducationlisting .viewrow .joinbutton {
  margin-top: 34px;
}

.vieweducationlisting .viewrow .joinbutton a {
  padding: 10px 23px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  background: #0072BB;
  box-shadow: 0px 0px 6px rgba(0, 114, 187, 0.25);
  border-radius: 4px;
  transition: all 0.5s ease;
}
.vieweducationlisting .viewrow .joinbutton a:hover {
  opacity: 0.8;
}
.vieweducationlisting .viewrow .imagebox img {
  border-radius: 20px;
  width: 100%;
  height: auto;
}
.vieweducationlisting .viewrow:nth-child(3n) .imagebox {
  padding: 29px 0px 56px 0px;
  position: relative;
}
.vieweducationlisting .viewrow:nth-child(3n) .imagebox::before {
  width: 100%;
  max-width: 560px;
  height: 100%;
  content: "";
  position:absolute;
  right: -56px;
  top:0px;
  filter: drop-shadow(-10px -20px 15px rgba(0, 0, 0, 0.05));
  background: #52B4F2;
}
.vieweducationlisting .viewrow:nth-child(3n) .imagebox img {
  position:relative;
  z-index: 1;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .vieweducationlisting .viewrow {
    width: 100%;
    padding: 20px;
  }
  .vieweducationlisting .viewrow .viewcontent {
      flex-direction: row-reverse;
      padding: 0px;
      flex-wrap: wrap-reverse;
      text-align: center;
  }
  .vieweducationlisting .viewrow:nth-child(1) .imagebox {
      width: 90%;
      padding: 108px 22px;
  }
  .vieweducationlisting .viewrow .imagebox {
      width: 100%;
  }
  .vieweducationlisting .viewrow .body,
  .vieweducationlisting .viewrow:nth-child(1) .body {
      width: 100%;
      padding-top: 0px;
      margin-top: 25px;
  }
  .vieweducationlisting .viewrow:nth-child(2n) .body {
    padding-left: 0px;
  }
  .vieweducationlisting .viewrow .body h2 {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 20px;
      margin-top: 0px;
  }
  .vieweducationlisting .viewrow .description {
      font-size: 16px;
      line-height: 22px;
      padding-right: 0px;
  }
  .vieweducationlisting .viewrow:nth-child(2n) .description {
      padding-left: 0px;
      padding-right: 0px;
  }
  .vieweducationlisting .viewrow .joinbutton {
      margin-top: 25px;
  }
  .vieweducationlisting .viewrow .joinbutton a {
      font-size: 15px;
      display: inline;
  }
  .vieweducationlisting .viewrow .description .quote {
      width: 15px;
      height: 20px;
      background: url(../../assets/images/quote.svg) no-repeat 0% 0%;
      background-size: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .vieweducationlisting .viewrow {
      padding: 45px 0px;
  }
  .vieweducationlisting .viewrow .viewcontent {
      padding: 0px 30px;
  }
  .vieweducationlisting .viewrow .description {
      font-size: 18px;
      line-height: 28px;
      padding-right: 50px;
  }
  .vieweducationlisting .viewrow .join-button {
      margin-top: 35px;
      padding-right: 40px;
  }
  .vieweducationlisting .viewrow .join-button a {
      font-size: 15px;
  }
  .vieweducationlisting .viewrow .description .quote {
      width: 20px;
      height: 30px;
      background-size: 100%;
  }
  .vieweducationlisting .viewrow:nth-child(1) .imagebox {
      padding: 100px 25px;
  }
  .vieweducationlisting .viewrow:nth-child(2n) .body {
    padding-left: 40px;
  }
  .vieweducationlisting .viewrow .body h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
  }
}